import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions, IExhibitorInteractionsTranslations, IExhibitorSettingsTranslations, IExhibitorSidebar, IExhibitorsDashboardTranslations, IExhibitorProducsTranslations, IExhibitorProductInteractions, IExhibitorProfile,
} from '.';

const dashboard: IExhibitorsDashboardTranslations = {
  home: 'Panel de control',
  product_interactions_description: 'Número de aplicaciones a las ofertas de trabajo:',
  information: 'Información',
  name: 'Nombre del expositor:',
  edit: 'Editar',
  interactions: 'Contactos',
  nrOfInteractions: 'Número de interacciones:',
  seeAll: 'Ver todo',
  download: 'Descargar',
  sessions: 'Sesiones',
  upcoming_sessions: 'Siguientes sesiones',
  see_all_sessions: 'Ver todas las sesiones',
  applications: 'Visitantes interesados en un producto',
  see_applications: 'Ver aplicaciones',
  choose_exhibitor: 'Elija el expositor que desea gestionar',
  schedule_meetings: 'Reservar reuniones',
  check_scheduled_meetings: 'Mira las reuniones que ya han sido reservadas',
  exhibitor_pending_title: 'Your exhibitor has not been approved',
  exhibitor_pending_text: 'Please wait until the event manager aproves your exhibitor',
};

const sidebar: IExhibitorSidebar = {
  title: 'Exhibiendo',
  home: 'Panel de control',
  interactions: 'Visitantes',
  exhibitor_interactions: 'Interesados en tu empresa',
  exhibitor_sessions: 'Sesiones de empresa',
  settings: 'Formulario',
  sessions: 'Sesiones',
  products: 'Productos',
  product_interactions: 'Visitantes interesados en un producto',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Configuración del expositor',
  basicInfo: 'Información básica',
  save: 'Guardar',
  additionalInfo: 'Información adicional',
  formError: 'Información no recuperada, formulario no disponible.',

};

const products: IExhibitorProducsTranslations = {
  title: 'Productos',
  subtitle: 'Crear, editar y eliminar productos',
  no_products: 'No hay productos disponibles',
  new_product: 'Nuevo producto',
  create_new_product: 'Crear un producto',
  edit_product: 'Editar producto',
  delete_confirm: 'Está seguro de que desea eliminar este producto?',
  interactions_exist_cannot_delete: 'Este producto ya tiene interacciones, por lo que no es posible eliminar',
  inputs: { name: 'Nombre' },
  search_placeholder: 'Escriba lo que desea buscar en la lista de productos',
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Visitantes interesados en su empresa',
  subtitle: 'Mira la lista de visitantes interesados en tu empresa',
  download: 'Descargar',
  noInteractions: 'Este expositor no tiene interacciones.',
  interaction: {
    editComment: 'Editar comentario',
    saveComment: 'Guardar comentario',
    checkIn: 'Check-in',
  },
  search_placeholder: 'Escriba lo que desea buscar en la lista de solicitudes de contactos',
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Autenticación',
  step_basicInfo: 'Información básica',
  step_additionalInfo: 'Información adicional',
  step_review: 'Revisión',
  step_done: 'Hecho!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Imagen de perfil',
    embedded: 'Video de Youtube',
    meeting_room: 'Activar sala de reuniones virtual',
  },
  login: {
    title: 'Estás a punto de registrarte',
    subtitle: 'Primero haz login o regístrate',
    message: 'Para registrarte, necesitamos que primero hagas login o te registres.',
    exhibitor_manager_name: 'Inserta el nombre del gerente del stand',
    exhibitor_manager_email: 'Inserta el email del gerente del stand',
  },
  basicInformation: {
    title: 'Dinos más sobre tu organización',
    warning: 'La información que has insertado será utilizada para la creación del booklet del evento, así como para crear un perfil que será visto por los demás participantes.',
    createExhibitor: 'Crear',
    exhibitor_name: 'Nombre de la organización',
    exhibitor_name_placeholder: 'Insertar nombre de la organización',
    modal: {
      title: 'Aviso',
      subtitle: 'Si quieres gestionar tu expositor, por favor, haz login primero.',
      skip: 'Saltar',
      createAccount: 'Crear cuenta',
      login: 'Login',
    },
  },
  managedFields: {
    title: 'Información adicional',
    crop_image: 'Cortar imagen',
    crop_again: 'Volver a cortar',
  },
  review: {
    title: 'Revisar información',
    name: 'Nombre',
  },
  completed: {
    title: 'Perfil creado!',
    subtitle: 'Tu perfil está listo. Gracias por completar la información!',
    manage: 'Gestionar',
  },
  back: 'Atrás',
  next: 'Adelante',
  submit: 'Enviar',
};

const sessions: IExhibitorSessions = {
  title: 'Sesiones',
  subtitle: 'Crear, editar y eliminar reuniones',
  start_date: 'Empieza a:',
  end_date: 'Termina en:',
  join_meeting: 'Unirse a la sesión',
  meeting_url: 'URL de la reunión:',
  description: 'Descripción:',
  new_session: 'Nueva sesión',
  edit_session: 'Editar Sesión',
  register: 'Registrarse en esta sesión',
  invited_users: 'Convidados',
  inputs: {
    name: 'Nombre',
    name_placeholder: 'Escriba el nombre de la sesión',
    start_date: 'Día y hora de inicio',
    start_date_placeholder: 'Escriba la día y hora de inicio de la sesión',
    end_date: 'Día y hora de finalización',
    end_date_placeholder: 'Escriba la día y hora de inicio de la sesión',
    color: 'Color de la sesión',
    color_placeholder: 'Elija el color de la sesión',
    description: 'Descripción',
    description_placeholder: 'Escriba la descripción de la sesión',
    entrance_capacity: 'Capacidad de entrada',
    entrance_capacity_placeholder: 'Ingrese la capacidad del espacio',
    registration_capacity: 'Capacidad de registro',
    registration_capacity_placeholder: 'Ingrese la capacidad de registro',
    external_link: 'URL de la reunión',
    external_link_placeholder: 'Escriba la URL de la reunión',
    external_link_warning_message: 'Dejar vacío si quieres que beamian cree una sala de reuniones',
    order: 'Orden',
    order_placeholder: 'Elija el orden de la sesión',
    private_session: 'Sesión privada',
  },
  session_label: 'Sesión',
  select_session: 'Seleccione la sesión',
  no_private_sessions: 'No hay sesiones privadas',
  private_session_warning: 'Si la sesión es privada, sólo la verán los participantes invitados. Si es una sesión pública, será visible para todos los participantes.',
  enable_chat: 'Habilitar chat',
  embed_meeting: 'Sesión de interna',
  embed_meeting_detail: 'Si desactiva esta opción, la URL de la sesión se abrirá en una nueva pestaña',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Visitantes interesados en un producto',
  subtitle: 'Mira la lista de visitantes interesados en tus productos, chatea con ellos o envíales una reunión',
  product_name: 'Producto',
  interaction_name: 'Nombre del visitante',
  application: 'Información del visitante',
  invite_to_session: 'Invitar a una sesión privada',
  send_invite: 'Enviar invitación',
  user_already_registered: 'Este usuario ya está registrado en esta sesión',
  publish_warning: 'Al publicar la oferta de empleo, será visible para los visitantes',
  invitation_sent: 'Invitación enviada con éxito',
  search_placeholder: 'Escriba lo que desea buscar en la lista de solicitudes de información',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Información de la empresa',
  products: 'Ofertas de trabajo',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
