import {
  IExhibitorsDashboardTranslations, IExhibitorSidebar,
  IExhibitorSettingsTranslations, IExhibitorProducsTranslations, IExhibitorInteractionsTranslations, IExhibitorOnboardingTranslations, IExhibitorSessions, IExhibitorProductInteractions, IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  product_interactions_description: 'Number of applications to offers received:',
  name: 'Name of the teacher:',
  // interactions: 'Spontaneous applications',
  // nrOfInteractions: 'Number of spontaneous applications received:',
  // seeAll: 'See applications',
  // applications: 'Applications to job offers',
  // see_applications: 'See applications',
  check_scheduled_meetings: 'Check the meetings that students already scheduled',
};

const sidebar: Partial<IExhibitorSidebar> = {
  title: 'Teaching',
  // exhibitor_interactions: 'Spontaneous applications',
  exhibitor_sessions: 'Calendar',
  product_interactions: 'Applications for offers',
  interactions: 'Students',
  settings: 'Profile',
  products: 'Offers',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  title: 'Teacher settings',
};

const products: Partial<IExhibitorProducsTranslations> = {
  // title: 'Job offers',
  // subtitle: 'Create, edit and delete job offers',
  // no_products: 'No job offers available',
  // new_product: 'New job offer',
  // create_new_product: 'Create a new job offer',
  // edit_product: 'Edit job offer',
  // delete_confirm: 'Are you sure you want to delete this job offer?',
  interactions_exist_cannot_delete: "This offer already has students, therefore it can't be deleted",
  search_placeholder: 'Type what you wish to search in the list of offers',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  // title: 'Spontaneous applications',
  // subtitle: 'Check applications and schedule meetings',
  noInteractions: 'This teacher has no interactions.',
  interaction: {
    editComment: 'Edit comment',
    saveComment: 'Save comment',
    checkIn: 'CheckIn',
  },
  // search_placeholder: 'Type what you wish to search for in the list of spontaneous applications',
};

const onboarding: DeepPartial<IExhibitorOnboardingTranslations> = {
  login: {
    title: 'Register a teacher',
    message: 'To register a teacher we need you to login or register a new beamian account.',
    exhibitor_manager_name: "Insert the teacher's name",
    exhibitor_manager_email: "Insert the teacher's email",
  },
  basicInformation: {
    title: 'Tell us about this teacher',
    createExhibitor: 'Create teacher',
    exhibitor_name: 'Teacher name',
    exhibitor_name_placeholder: 'Insert the name of this teacher',
  },
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning: "Create a private session to invite specific students to a meeting. If it's a public session, any student will be able to see and register for that session.",
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  // title: 'Applications for job offers',
  // subtitle: 'Check job applications and schedule meetings',
  // product_name: 'Job offer',
  interaction_name: 'Student name',
  // application: 'Apply',
  // invite_to_session: 'Schedule meeting',
  // send_invite: 'Send invite',
  user_already_registered: 'This student is already registered on this meeting',
  publish_warning: 'By publishing this form the information will be visible to students',
  // invitation_sent: 'Invitation sent successfully',
  // search_placeholder: 'Type what you want to search for in the list of offers’ applications',
};

const profile: Partial<IExhibitorProfile> = {
  exhibitor_information: 'Teacher information',
  // products: 'Job offers',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
