import { IEventV2 } from '@/models/event';
import { IVisitor } from '@/models/visitor';
import { IExhibitor } from '@/models/exhibitor';
import { ISession } from '../../models/session';

export type UserRole = 'exhibitor' | 'visitor' | 'manager' | 'admin' | 'unset';

export interface IContextState {
  event: IEventV2 | null;
  exhibitor: IExhibitor | null;
  visitor: IVisitor | null;
  role: UserRole;
  session: ISession;
}

const state: IContextState = {
  event: {} as IEventV2,
  exhibitor: {} as IExhibitor,
  visitor: {
    id: 0,
    name: 'Guest',
    email: 'guest@beamian.com',
    event: 0,
  },
  role: 'unset',
  session: {} as ISession,
};

const getters = {
  getContextExhibitor: (state: IContextState) => state.exhibitor,
  getContextEvent: (state: IContextState) => state.event,
  getContextVisitor: (state: IContextState) => state.visitor,
  getContextRole: (state: IContextState) => state.role,
  getContextSession: (state: IContextState) => state.session,
};

const actions = {};

const mutations = {
  setContextEvent: (state: IContextState, event: IEventV2) => {
    state.event = event;
  },
  setContextExhibitor: (state: IContextState, exhibitor: IExhibitor) => {
    state.exhibitor = exhibitor;
    state.role = 'exhibitor';
  },
  setContextRole: (state: IContextState, role: UserRole) => {
    state.role = role;
  },
  setContextVisitor: (state: IContextState, visitor: IVisitor) => {
    state.visitor = {} as IVisitor;
    state.visitor = visitor;
    state.role = 'visitor';
  },
  setContextSession: (state: IContextState, session: ISession) => {
    state.session = session;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
