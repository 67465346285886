import { ActionContext } from 'vuex';
import { IParams } from '@/api';
import { getNetworkingVisitors } from '@/api/networking';
import { IVisitor } from '@/models/visitor';

import { INetworking } from '@/models/networking';
import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateNetworking extends IObjectState, INetworking { }

export type INetworkingState = IStateObject<IStateNetworking>;

const state: INetworkingState = {
  list: [],
  selected: [],
  page: 1,
  page_count: 0,
  item_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (networking: (INetworking)[]) => networking.map((visitor) => ({
  ...visitor,
  loading: false,
  error: false,
  message: '',
}));

const getters = {
  getNetworking: (state: INetworkingState) => state.list,
  getNetworkingState: (state: INetworkingState) => state,
  getSelectedNetworking: (state: INetworkingState) => state.selected,
};

export interface IFetchNetworkingParams extends IParams {
  event: number;
  search?: string;
  visitor?: number;
  reset?: boolean
}

const actions = {
  fetchNetworking(context: ActionContext<INetworkingState, IState>, params: IFetchNetworkingParams) {
    context.commit('setNetworkingLoading');
    if (context.state.page) {
      getNetworkingVisitors(params)
        .then((response) => {
          const networking = {
            list: addStateProps(response.data.results),
            page: response.data.current,
            page_count: response.data.page_count,
            item_count: response.data.item_count,
          };
          if (params.reset) {
            context.commit('resetNetworking');
          }
          context.commit('setNetworking', networking);
        })
        .catch((err) => {
          context.commit('setNetworkingError', err.request);
        });
    }
  },

};

const mutations = {
  setNetworkingError: (state: INetworkingState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setVisitorError: (state: INetworkingState, { failedVisitor, message }: { failedVisitor: IStateNetworking; message: string }) => {
    state.list = state.list.map((visitor) => (visitor.id === failedVisitor.id
      ? {
        ...visitor,
        loading: false,
        error: true,
        message,
      }
      : visitor));
  },

  setNetworkingPage: (state: INetworkingState, page: number) => {
    state.page = page;
  },

  setNetworkingLoading: (state: INetworkingState) => {
    state.loading = true;
    state.error = false;
  },

  setVisitorLoading: (state: INetworkingState, visitor: IStateNetworking) => {
    state.list[state.list.indexOf(visitor)] = {
      ...state.list[state.list.indexOf(visitor)],
      loading: true,
      error: false,
    };
  },

  setNetworking: (state: INetworkingState, networking: INetworkingState) => {
    state.error = false;
    state.loading = false;
    state.list = [...state.list, ...networking.list];
    state.page = networking.page;
    state.page_count = networking.page_count;
    state.item_count = networking.item_count;
  },

  resetNetworking: (state: INetworkingState) => {
    state.error = false;
    state.loading = false;
    state.list = [];
    state.page = 0;
    state.page_count = 0;
    state.item_count = 0;
  },

  editVisitor: (state: INetworkingState, editedVisitor: IStateNetworking) => {
    state.list = state.list.map((visitor) => (visitor.id === editedVisitor.id
      ? {
        ...editedVisitor,
        loading: false,
        error: false,
      }
      : visitor));
  },

  newVisitor: (state: INetworkingState, visitor: IStateNetworking) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(visitor);
  },

  removeVisitor: (state: INetworkingState, deletedVisitor: IVisitor) => {
    state.list = state.list.filter((visitor) => visitor.id !== deletedVisitor.id);
  },

  removeNetworking: (state: INetworkingState, ids: number[]) => {
    state.list = state.list.filter((visitor) => visitor.id && ids.indexOf(visitor.id) === -1);
  },

  setLoadingNetworking: (state: INetworkingState, ids: number[]) => {
    state.list = state.list.map((visitor) => ({
      ...visitor,
      loading: !!(visitor.id && ids.indexOf(visitor.id) > -1),
      error: false,
    }));
  },

  selectVisitor: (state: INetworkingState, visitor: IStateNetworking) => {
    if (visitor.id) {
      const visitorIndex = state.selected.indexOf(visitor.id);
      if (visitorIndex > -1) {
        state.selected.splice(visitorIndex, 1);
      } else {
        state.selected.push(visitor.id);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
