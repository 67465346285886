import { IEventsTranslations } from '.';

const table: IEventsTranslations = {
  title: 'Bem vindo à Beamian',
  subtitle: 'Por favor escolha um evento',
  rolesTitle: 'Bem vindo à beamian',
  rolesSubtitle: 'O que precisa fazer?',
  registerVisitor: 'Registar visitante',
  registerVisitorDescription: 'Registe-se neste evento como visitante.',
  createExhibitor: 'Registar expositor',
  createExhibitorDescription: 'Registe o seu expositor neste evento.',
  manageExhibitor: 'Gerir expositor',
  manageExhibitorDescription: 'Editar informação de expositor, ver interações.',
  manageVisitor: 'Gerir visitante',
  manageVisitorDescription: 'Editar informação de visitante, ver interações.',
  manageEvent: 'Gerir evento',
  manageEventDescription: 'Aceder a configuraçoes e informação do evento.',
};

export default table;
