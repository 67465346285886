import { IAuthLoginTranslations, IAuthRegisterTranslations } from '.';

const login: IAuthLoginTranslations = {
  welcome: 'Welcome',
  email: 'Email',
  email_placeholder: 'Enter your email',
  password: 'Password',
  password_placeholder: 'Enter a password',
  login: 'Login',
  log_back: 'Log back in?',
  logged_out: "You've been logged out",
  or: 'or',
  other_account: 'Other account',
  forgot_password: 'Forgot your password?',
  newAccount: 'New account',
  wrong_password: "Couldn't login with specified credentials",
  link_sent: 'Check your inbox, we sent you a direct link to access the event!',
  send_link: 'Send login link',
  send_me_link: 'Send me an email with the login link',
  use_password: 'Use password to login',
  recover_password: 'Recover password',
  recover_password_link_sent: 'Check your inbox! We sent you a link for resetting your password.'
};

const register: IAuthRegisterTranslations = {
  newAccount: 'New account',
  error: 'There was an error creating your user. Please try again later.',
  cancel: 'Cancel',
  signUp: 'Sign up',
  welcome: 'Welcome to Beamian!',
  confirmationEmail: "We've sent you an email for confirming your account. Please check your inbox.",
  login: 'Login',
  password_not_match: "Passwords don't match.",
};

export default { login, register };
