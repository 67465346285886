import axios from 'axios';
import { IEventV2 } from '@/models/event';
import {
  IAttendingFieldsEntity, IVisitor, IVisitorResponse,
  IAttendingAnswer,
  IFavoriteParams,
  IFavorite,
  IVisitorsParams,
  IVisitorContactsParams,
  IVisitorContact,
  IExportFile,
} from '@/models/visitor';
import { FieldsEntity } from '@/models/brand';
import { Resolve, IData } from '.';
import config from './config';
import { IFieldAnswer } from '@/models/form';

const url = process.env.VUE_APP_SERVER_PATH;

export interface IAttendeeLiveForm {
  sessions?: { field_type: string; value?: string }[]
  email: string;
  name: string;
  ticket_code?: string;
  fields: IAttendingFieldsEntity[];
}

export const addVisitorContactsExport = (exhibitor: number) => new Promise((resolve: Resolve<IExportFile>) => {
  const requestUrl = `${url}/v2/visitor-contacts-exports`;

  const data = {
    exhibitor,
    source_type: 'visitor',
  };

  axios
    .post(requestUrl, data, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const deleteVisitorContactsExport = (exportId: number) => new Promise((resolve: Resolve<IExportFile>) => {
  const requestUrl = `${url}/v2/visitor-contacts-exports/${exportId}`;

  axios
    .delete(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const getVisitorContactsExports = (exhibitor: number) => new Promise((resolve: Resolve<IData<IExportFile>>) => {
  const requestUrl = `${url}/v2/visitor-contacts-exports?exhibitor=${exhibitor}`;
  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const getFavorites = (params: IFavoriteParams) => new Promise((resolve: Resolve<IData<IFavorite>>) => {
  let requestUrl = `${url}/v2/visitor-favourites?page_size=100`;

  if (params.visitor) {
    requestUrl += `&visitor=${params.visitor}`;
  }
  if (params.source_type) {
    requestUrl += `&source_type=${params.source_type}`;
  }
  if (params.source_id) {
    requestUrl += `&source_id=${params.source_id}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const addFavorite = (params: IFavoriteParams) => new Promise((resolve: Resolve<IFavorite>) => {
  axios
    .post(`${url}/v2/visitor-favourites`, params, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const deleteFavorite = (favoriteId: number) => new Promise((resolve: Resolve<IFavorite>) => {
  axios
    .delete(`${url}/v2/visitor-favourites/${favoriteId}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const newAttendee = (event: IEventV2, attendeeForm: IAttendeeLiveForm) => new Promise((resolve: Resolve<IVisitorResponse>) => {
  axios
    .post(`${url}/event-live/${event.slug}/live_form`, attendeeForm, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const editAttendee = (event: IEventV2, attendeeForm: IAttendeeLiveForm) => new Promise((resolve: Resolve<IVisitorResponse>) => {
  console.log(event, attendeeForm, resolve);
});

export const getVisitorContacts = (params: IVisitorContactsParams) => new Promise((resolve: Resolve<IData<IVisitorContact>>) => {
  if (!params.page_size) {
    params.page_size = 100;
  }

  if (!params.page) {
    params.page = 1;
  }

  let requestUrl = `${url}/v2/visitor-contacts?page_size=${params.page_size}&page=${params.page}`;

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }

  if (params.email) {
    requestUrl += `&email=${encodeURIComponent(params.email)}`;
  }

  if (params.phone_nr) {
    requestUrl += `&phone_nr=${params.phone_nr}`;
  }
  if (params.exhibitor) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }
  if (params.search) {
    requestUrl += `&search=${params.search}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getVisitors = (params: IVisitorsParams) => new Promise((resolve: Resolve<IData<IVisitor>>) => {
  if (!params.page_size) {
    params.page_size = 100;
  }

  if (!params.page) {
    params.page = 1;
  }

  let requestUrl = `${url}/v2/visitors?page_size=${params.page_size}&page=${params.page}`;

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }

  if (params.email) {
    requestUrl += `&email=${encodeURIComponent(params.email)}`;
  }

  if (params.phone_nr) {
    requestUrl += `&phone_nr=${params.phone_nr}`;
  }
  if (params.search) {
    requestUrl += `&search=${params.search}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getVisitorById = (visitorId: number) => new Promise((resolve: Resolve<IVisitor>) => {
  axios
    .get(`${url}/v2/visitors/${visitorId}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getVisitorContactById = (visitorContactId: number, exhibitor: number) => new Promise((resolve: Resolve<IVisitorContact>) => {
  axios
    .get(`${url}/v2/visitor-contacts/${visitorContactId}?exhibitor=${exhibitor}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getAttendingAnswers = (visitor: number) => new Promise((resolve: Resolve<IData<IFieldAnswer>>) => {
  axios
    .get(`${url}/v2/attending-answers?visitor=${visitor}&page_size=100`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const updateEventAttendingAnswers = (event: number, visitor: number, fields: FieldsEntity[]) => new Promise((resolve: Resolve<IData<IAttendingAnswer>>) => {
  axios
    .post(`${url}/organizers/events/${event}/visitors/${visitor}/attending_answers`, { fields }, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const updateEventAttendingAnswer = (attendingAnswer: number, answer: string | number | boolean | []) => new Promise((resolve: Resolve<IData<IAttendingAnswer>>) => {
  axios
    .put(`${url}/v2/attending-answers/${attendingAnswer}`, { answer }, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
