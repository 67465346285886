import { IEventsTranslations } from '.';

const events: IEventsTranslations = {
  title: 'Welcome to Beamian!',
  subtitle: 'Please choose an event',
  rolesTitle: 'Welcome to',
  rolesSubtitle: 'What do you want to do?',
  registerVisitor: 'Register visitor',
  registerVisitorDescription: 'Register yourself or someone else as a visitor on this event.',
  createExhibitor: 'Register exhibitor',
  createExhibitorDescription: 'Register your exhibitor on this event.',
  manageVisitor: 'Manage visitor',
  manageVisitorDescription: 'Edit visitor information, see interactions.',
  manageExhibitor: 'Manage exhibitor',
  manageExhibitorDescription: 'Edit exhibitor information, see interactions.',
  manageEvent: 'Manage event',
  manageEventDescription: 'Access event settings, information and content.',
};

export default events;
