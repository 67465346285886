import { IAuthLoginTranslations, IAuthRegisterTranslations } from '.';

const login: IAuthLoginTranslations = {
  welcome: 'Herzlich willkommen',
  email: 'E-Mail',
  email_placeholder: 'Geben Sie Ihre E-Mail-Adresse ein.',
  password: 'Passwort',
  password_placeholder: 'Geben Sie ein Passwort ein.',
  login: 'Einloggen',
  log_back: 'Wieder anmelden?',
  logged_out: 'Sie wurden abgemeldet.',
  or: 'oder',
  other_account: 'Anderes Konto',
  forgot_password: 'Haben Sie Ihr Passwort vergessen?',
  newAccount: 'Neues Konto',
  wrong_password: 'Einloggen mit angegebener Berechtigung nicht möglich',
  link_sent: 'Check your inbox, we sent you a direct link to access the event!',
  send_link: 'Send login link',
  use_password: 'Use password to login',
  send_me_link: 'Send me an email with the login link',
  recover_password: 'Recover password',
  recover_password_link_sent: 'Check your inbox! We sent you a link for resetting your password'
};

const register: IAuthRegisterTranslations = {
  newAccount: 'Neues Konto',
  error: 'Beim Erstellen Ihres Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  cancel: 'Abbrechen',
  signUp: 'Anmelden',
  welcome: 'Willkommen bei beamian!',
  confirmationEmail: 'Wir haben Ihnen eine E-Mail zur Bestätigung Ihres Kontos gesendet. Bitte überprüfen Sie Ihren Posteingang.',
  login: 'Einloggen',
  password_not_match: 'Passwörter stimmen nicht überein.',
};

export default { login, register };
