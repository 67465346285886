import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import PortalVue from 'portal-vue';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import VueGtag from "vue-gtag";

Vue.use(PortalVue);
Vue.use(VueCompositionAPI);

const { VUE_APP_GA_ID } = process.env;

Vue.use(VueGtag, {
  config: {
    id: VUE_APP_GA_ID,
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
