import Vue from 'vue';
import Vuex from 'vuex';

import app, { IAppState } from './modules/app';
import auth, { IAuthState } from './modules/auth';
import context, { IContextState } from './modules/context';
import events, { IEventsState } from './modules/events';
import interactions, { IInteractionsState } from './modules/interactions';
import translations, { ITranslationsState } from './modules/translations';
import users, { IUsersState } from './modules/users';
import notifications, { INotificationsState } from './modules/notifications';
import visitors, { IVisitorsState } from './modules/visitors';
import sessions, { ISessionsState } from './modules/sessions';
import exhibitors, { IExhibitorsState } from './modules/exhibitors';
import rocketAuth, { IRocketAuthState } from './modules/rocketAuth';
import networking, { INetworkingState } from './modules/networking';
import chat, { IChatState } from './modules/chat';
import products, { IProductsState } from './modules/products';
import productInteractions, { IProductInteractionsState } from './modules/productInteractions';
import popups, { IPopupsState } from './modules/popups';
import tickets, { ITicketsState } from './modules/tickets';

Vue.use(Vuex);

/* Typescript coverage very superficial, only to exemplify implementation.
 */

export interface IState {
  app: IAppState;
  events: IEventsState;
  users: IUsersState;
  auth: IAuthState;
  translations: ITranslationsState;
  interactions: IInteractionsState;
  context: IContextState;
  notifications: INotificationsState;
  visitors: IVisitorsState;
  sessions: ISessionsState;
  exhibitors: IExhibitorsState;
  rocketAuth: IRocketAuthState;
  networking: INetworkingState;
  chat: IChatState;
  products: IProductsState;
  productInteractions: IProductInteractionsState;
  popups: IPopupsState;
  tickets: ITicketsState;
}

export interface IObjectState {
  loading: boolean;
  error: boolean;
  message: string;
}

export interface IStateObject<T> extends IObjectState {
  list: T[];
  selected: number[];
  page?: number;
  page_count?: number;
  item_count?: number;
}

// Vuex store typings for vue 3 update
// export type Store = Omit<
//   VuexStore<State>,
//   'getters' | 'commit' | 'dispatch'
// > & {
//   commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
//     key: K,
//     payload: P,
//     options?: CommitOptions
//   ): ReturnType<Mutations[K]>
// } & {
//   dispatch<K extends keyof Actions>(
//     key: K,
//     payload?: Parameters<Actions[K]>[1],
//     options?: DispatchOptions
//   ): ReturnType<Actions[K]>
// } & {
//   getters: {
//     [K in keyof Getters]: ReturnType<Getters[K]>
//   }
// }

export default new Vuex.Store({
  state: {} as IState,
  mutations: {},
  actions: {},
  modules: {
    app,
    events,
    users,
    auth,
    translations,
    interactions,
    context,
    notifications,
    visitors,
    sessions,
    exhibitors,
    rocketAuth,
    networking,
    chat,
    products,
    productInteractions,
    popups,
    tickets,
  },
});
