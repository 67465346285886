import { IEventsTranslations } from '@/translations/default/events';

const events: Partial<IEventsTranslations> = {
  // createExhibitor: 'Registar empresa',
  // createExhibitorDescription: 'Registe a sua empresa neste evento.',
  // manageExhibitor: 'Gerir empresa',
  // manageExhibitorDescription: 'Editar informação da empresa, ver interações.',
};

export default events;
