import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions, IExhibitorInteractionsTranslations,
  IExhibitorSettingsTranslations, IExhibitorSidebar,
  IExhibitorsDashboardTranslations, IExhibitorProducsTranslations,
  IExhibitorProductInteractions, IExhibitorProfile, IExhibitorTickets,
  IExhibitorVisitors, IExhibitorExports,
} from '.';

const dashboard: IExhibitorsDashboardTranslations = {
  home: 'Panneau de commande',
  product_interactions_description: 'Nombre de visiteurs intéressés par les offres :',
  favorites: 'Favorites',
  nr_of_favorites: 'Number of visitors who added this exhibitor as a favorite:',
  information: 'Profil',
  name: "Nom de l'exposant :",
  view_as_visitor: 'Voir comme visiteur',
  edit: 'Éditer',
  interactions: "Visiteurs intéressés par l'exposant",
  nrOfInteractions: "Nombre de visiteurs intéressés par l'exposant :",
  seeAll: 'Voir les contacts',
  download: 'Télécharger',
  sessions: 'Séances',
  upcoming_sessions: 'Prochaines séances',
  see_all_sessions: 'Voir toutes les séances',
  applications: 'Visiteurs intéressés par les offres',
  see_applications: 'Voir les contacts',
  choose_exhibitor: "Choisir l'exposant que vous souhaitez gérer",
  schedule_meetings: 'Réunions planifiées',
  check_scheduled_meetings: 'Vérifier les réunions que les visiteurs ont déjà programmées',
  exhibitor_pending_title: 'Your exhibitor has not been approved',
  exhibitor_pending_text: 'Please wait until the event manager aproves your exhibitor',
};

const exports: IExhibitorExports = {
  title: 'Exports',
  subtitle: 'Manage and download your exports',
  export_contacts: 'Exportar contactos',
};

const visitors: IExhibitorVisitors = {
  title: 'Visitors',
  subtitle: 'See visitor information, invite visitor to private sessions',
  assign_packages: 'Assign packages',
  visitor_information: 'Visitor information',
  assign_step_1: 'Find an existing visitor or register a new one',
  assign_step_2: 'Register new visitor',
  assign_step_3: 'Add packages to visitor',
  assign_step_4: 'All done!',
  assign_step_5: "You can't assign any more packages",
  out_of_packages: 'You are out of packages to assign.',
  package_name: 'Package name',
  package_assigned_successfuly: 'Package assigned successfuly!',
  assign_another: 'Assign another',
  invite_private_session: 'Invitar a una sesión privada',
  user_not_registered: 'Este usuario no está registrado para ninguna sesión de su expositor.',
  enter_note_placeholder: 'Enter your note',
};

const tickets: IExhibitorTickets = {
  title: 'Atribuições',
  subtitle: 'Bilhetes atribuidos a visitantes',
  new_assignment: 'Nova atribuição',
  assignee_name: 'Nome do recebedor/a',
  assignee_email: 'Email do recebedor/a',
  visitor_name: 'Nome do visitante',
  visitor_email: 'Email do visitante',
  ticket: 'Bilhete',
  ticket_type_title: 'Bilhetes',
  ticket_type_subtitle: 'Atribua bilhetes a visitantes',
  assign_title: 'Assign ticket to a visitor',
  assign: 'Assign',
  ticket_success: 'Ticket assigned successfuly!',
};

const sidebar: IExhibitorSidebar = {
  title: 'Exposition',
  home: 'Panneau de commande',
  exhibitor_interactions: "Intéressé par l'exposant",
  exhibitor_sessions: 'Mon agenda',
  interactions: 'Visiteurs',
  sessions: 'Séances',
  settings: 'Profil',
  products: 'Offres',
  product_interactions: 'Intéressé par les offres',
  tickets: 'Tickets',
  exports: 'Exports',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Paramètres de l’exposant',
  basicInfo: 'Informations de base',
  save: 'Sauvegarder',
  additionalInfo: 'Informations complémentaires',
  formError: 'Aucune information trouvée, formulaire non disponible.',
};

const products: IExhibitorProducsTranslations = {
  title: 'Offres',
  subtitle: 'Créer, modifier et supprimer des offres',
  no_products: 'Aucune offre disponible',
  new_product: 'Nouvelle offre',
  create_new_product: 'Créer',
  edit_product: "Modifier l'offre",
  delete_confirm: "Êtes-vous sûr de vouloir supprimer cette offre d'emploi ?",
  interactions_exist_cannot_delete: 'Fait déjà l’objet d’interactions. Ne peut pas être supprimée.',
  search_placeholder: ' Tapez ce que vous souhaitez rechercher dans la liste des offres',
  you_have: 'You have',
  products_left: 'products left.',
  inputs: {
    name: 'Nom',
  },
};

const interactions: IExhibitorInteractionsTranslations = {
  title: "Visiteurs intéressés par l'exposant",
  subtitle: "Consulter la liste des visiteurs intéressés par l'exposant",
  download: 'Télécharger',
  noInteractions: 'Cet exposant ne présente aucune interaction.',
  interaction: {
    editComment: 'Modifier un commentaire',
    saveComment: 'Enregistrer un commentaire',
    checkIn: 'Enregistrement',
  },
  search_placeholder: 'Tapez ce que vous souhaitez rechercher dans la liste de contacts',
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Authentification',
  step_basicInfo: 'Informations de base',
  step_additionalInfo: 'Informations complémentaires',
  step_review: 'Revue',
  step_done: 'Terminé !',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Image de profil',
    embedded: 'vidéo Youtube',
    meeting_room: 'Activer la salle de réunion virtuelle',
  },
  login: {
    title: "Vous êtes sur le point d'inscrire votre exposant",
    subtitle: 'Première connexion ou inscription',
    message: 'Pour enregistrer votre exposant, vous devez vous connecter ou créer un nouveau compte beamian.',
    exhibitor_manager_name: 'Saisir le nom de l’exposant responsable',
    exhibitor_manager_email: 'Saisir l’adresse électronique de l’exposant responsable',
  },
  basicInformation: {
    title: 'Parlez-nous de votre exposant',
    warning: 'Les informations fournies seront utilisées pour créer le livret et un profil que les visiteurs pourront voir.',
    createExhibitor: 'Créer un exposant',
    exhibitor_name: "Nom de l'exposant",
    exhibitor_name_placeholder: 'Saisir le nom de votre exposant (entreprise)',
    modal: {
      title: 'Attention',
      subtitle: "Si vous souhaitez gérer votre exposant, veuillez d'abord vous connecter.",
      skip: 'Sauter',
      createAccount: 'Créer un compte',
      login: 'Connexion',
    },
  },
  managedFields: {
    title: 'Informations complémentaires',
    crop_image: "Recadrer l'image",
    crop_again: 'Retour au recadrage',
  },
  review: {
    title: 'Vérifier les informations',
    name: 'Nom',
  },
  completed: {
    title: 'Exposant créé !',
    subtitle: 'Votre exposant est prêt. Merci pour votre soumission !',
    manage: 'Gérer',
  },
  back: 'Retour',
  next: 'Suivant',
  submit: 'Soumettre',
};

const sessions: IExhibitorSessions = {
  title: 'Séances',
  subtitle: 'Créer, modifier et supprimer des séances',
  start_date: 'Commence à :',
  end_date: 'Se termine à :',
  join_meeting: 'Rejoindre la séance',
  meeting_url: 'URL de la réunion :',
  description: 'Description :',
  new_session: 'Nouvelle séance',
  edit_session: 'Modifier la séance',
  register: "S'inscrire",
  invited_users: 'Invité',
  inputs: {
    name: 'Nom',
    name_placeholder: 'Entrer le nom de la séance',
    start_date: 'Date de début',
    start_date_placeholder: 'Entrer la date de début de la séance',
    end_date: 'Date de fin',
    end_date_placeholder: 'Entrer la date de fin de la séance',
    color: 'Date de couleur',
    color_placeholder: 'Entrer la couleur de la session',
    description: 'Description',
    description_placeholder: 'Entrer la description de la séance',
    entrance_capacity: "Capacité d'entrée",
    entrance_capacity_placeholder: "Entrer la capacité d'entrée",
    registration_capacity: "Capacité d'inscription",
    registration_capacity_placeholder: "Entrer la capacité d'inscription",
    external_link: 'URL de la réunion',
    external_link_placeholder: "Saisir l'URL de la réunion",
    external_link_warning_message: 'Veuillez laisser ce champ vide si vous souhaitez que beamian crée une salle de réunion en votre nom.',
    order: 'Ordre',
    order_placeholder: "Entrer l'ordre des séances",
    private_session: 'Séance privée',
    streaming_link: 'URL de transmisión',
    streaming_link_placeholder: 'Ingresar URL de transmisión',
    streaming_link_warning_message: 'Esta transmisión estará disponible para todos los usuarios, incluidos los que no están registrados para esta sesión.',
    image: 'Imagen',
    image_placeholder: 'Sube la imagen de tu sesión',
    hide_on_calendar: 'Esconderse en el calendario',
    use_default_language_only: 'Utilizar idioma padrão do evento',
  },
  session_label: 'Séance',
  select_session: 'Sélectionner une séance',
  no_private_sessions: "Il n'y a pas de séances d’exposant",
  private_session_warning: "Créer une séance privée pour inviter des candidats spécifiques à une réunion. S'il s'agit d'une séance publique, tout candidat pourra la voir et s'y inscrire.",
  enable_chat: 'Permettre conversation',
  embed_meeting: 'Incorporer vidéo de réunion',
  embed_meeting_detail: "Si vous désélectionnez cette option, le lien de la réunion s'ouvrira dans un nouvel onglet",
  you_have: 'You have',
  sessions_left: 'sessions left',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Visiteurs intéressés par les offres',
  subtitle: 'Consultez la liste des visiteurs intéressés par vos offres et planifiez des rendez-vous',
  product_name: 'Offre',
  interaction_name: 'Nom du visiteur',
  application: 'Informations de contact',
  invite_to_session: 'Planifier une réunion',
  send_invite: 'Envoyer une invitation',
  user_already_registered: 'Cet utilisateur est déjà inscrit à cette séance',
  publish_warning: 'En publiant ce formulaire, les informations seront visibles pour les visiteurs',
  invitation_sent: 'Invitation envoyée avec succès',
  search_placeholder: 'Tapez ce que vous souhaitez rechercher dans la liste de contacts',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Informations sur l’exposant',
  products: 'Offres',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, exports, visitors, profile, tickets,
};
