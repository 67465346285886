import {
  IExhibitorsDashboardTranslations, IExhibitorSidebar,
  IExhibitorSettingsTranslations, IExhibitorProducsTranslations, IExhibitorInteractionsTranslations, IExhibitorOnboardingTranslations, IExhibitorSessions, IExhibitorProductInteractions, IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  home: 'Accueil',
  product_interactions_description: "Nombre de candidatures aux offres d'emploi reçues :",
  information: 'Profil',
  name: "Nom de l'exposant :",
  edit: 'Éditer',
  interactions: 'Candidatures spontanées',
  nrOfInteractions: 'Nombre de candidatures spontanées reçues :',
  seeAll: 'Voir les candidatures',
  download: 'Télécharger',
  sessions: 'Calendrier',
  upcoming_sessions: 'Prochaines séances',
  see_all_sessions: 'Voir toutes les séances',
  applications: "Candidatures aux offres d'emploi",
  see_applications: 'Voir les candidatures',
  choose_exhibitor: "Choisir l'exposant que vous souhaitez gérer",
  schedule_meetings: 'Réunions planifiées',
  check_scheduled_meetings: 'Vérifier les réunions que les candidats ont déjà programmées',
};

const sidebar: Partial<IExhibitorSidebar> = {
  title: 'Exposition',
  home: 'Accueil',
  exhibitor_interactions: 'Candidatures spontanées',
  exhibitor_sessions: 'Calendrier',
  interactions: 'Candidats',
  sessions: 'Calendrier',
  settings: 'Profil',
  products: "Offres d'emplois",
  product_interactions: "Candidatures aux offres d'emploi",
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  title: 'Paramètres de l’exposant',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Candidatures spontanées',
  subtitle: 'Vérifier les candidatures et planifier des réunions',
  download: 'Télécharger',
  noInteractions: 'Cet exposant ne présente aucune interaction.',
  interaction: {
    editComment: 'Modifier un commentaire',
    saveComment: 'Enregistrer un commentaire',
    checkIn: 'Enregistrement',
  },
  search_placeholder: 'Tapez ce que vous souhaitez rechercher dans la liste des candidatures spontanées',
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: "Offres d'emplois",
  subtitle: "Créer, modifier et supprimer des offres d'emploi",
  no_products: "Aucune offre d'emploi disponible",
  new_product: "Nouvelle offre d'emploi",
  create_new_product: "Créer une nouvelle offre d'emploi",
  edit_product: "Modifier une offre d'emploi",
  delete_confirm: "Êtes-vous sûr de vouloir supprimer cette offre d'emploi ?",
  interactions_exist_cannot_delete: "Cette offre d'emploi a déjà fait l’objet de candidatures, elle ne peut donc pas être supprimée",
  search_placeholder: "Tapez ce que vous souhaitez rechercher dans la liste des offres d'emploi",
  inputs: {
    name: 'Nom',
  },
};

const onboarding: DeepPartial<IExhibitorOnboardingTranslations> = {
  login: {
    title: "Vous êtes sur le point d'inscrire votre exposant",
    subtitle: 'Première connexion ou inscription',
    message: 'Pour inscrire votre exposant, vous devez vous connecter ou créer un nouveau compte beamian.',
    exhibitor_manager_name: 'Saisir le nom de l’exposant responsable',
    exhibitor_manager_email: 'Saisir l’adresse électronique de l’exposant responsable',
  },
  basicInformation: {
    title: 'Parlez-nous de votre exposant',
    warning: 'Les informations fournies seront utilisées pour créer le livret et un profil que les visiteurs pourront voir.',
    createExhibitor: 'Créer un exposant',
    exhibitor_name: "Nom de l'exposant",
    exhibitor_name_placeholder: 'Saisir le nom de votre exposant',
    modal: {
      title: 'Attention',
      subtitle: "Si vous souhaitez gérer votre exposant, veuillez d'abord vous connecter.",
      skip: 'Sauter',
      createAccount: 'Créer un compte',
      login: 'Connexion',
    },
  },
  completed: {
    title: 'Exposant créé !',
    subtitle: 'Votre exposant est prêt. Merci pour votre soumission !',
    manage: 'Gérer',
  },
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning: "Créer une séance privée pour inviter des candidats spécifiques à une réunion. S'il s'agit d'une séance publique, tout candidat pourra la voir et s'y inscrire.",
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: "Candidatures aux offres d'emploi",
  subtitle: 'Vérifier les candidatures et planifier des réunions',
  product_name: "Offre d'emploi",
  interaction_name: 'Nom du candidat',
  application: 'Appliquer',
  invite_to_session: 'Planifier une réunion',
  send_invite: 'Envoyer une invitation',
  user_already_registered: 'Cet utilisateur est déjà inscrit à cette réunion',
  publish_warning: 'En publiant ce formulaire, les informations seront visibles pour les candidats',
  invitation_sent: 'Invitation envoyée avec succès',
  search_placeholder: "Tapez ce que vous souhaitez rechercher dans la liste des candidatures d'offres d'emploi",
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Informations sur l’exposant',
  products: "Offres d'emplois",
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
