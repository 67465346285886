import {
  IVisitorOnboardingTranslations,
  IVisitorSessions,
  IVisitorExhibitorTranslations,
  IVisitorSettingsTranslations,
  IVisitorSidebar,
  IVisitorsDashboardTranslations,
  IVisitorNetworkingTranslations,
  IVisitorExhibitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from './index';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Profile',
  networking: 'Networking',
  networking_description: 'Meet fellow visitors',
  sessions: 'Programme',
  sessions_description: 'See all sessions',
  sessions_btn: 'Event programme',
  name: 'Name of the visitor:',
  edit: 'Edit',
  exhibitors: 'Exhibitors',
  nrOfInteractions: 'See all exhibitors',
  seeAll: 'Exhibitors',
  download: 'Download',
  join_networking: 'Networking',
  event_finished: 'Event is finished',
  event_not_start: 'Event did not start yet',
  featured_products: 'Featured products',
  featured_sessions: 'Featured sessions',
  featured_exhibitors: 'Meet our Partners',
  upcoming_exhibitors: 'Next sessions',
};

const sidebar: IVisitorSidebar = {
  title: 'Visiting',
  home: 'Home',
  exhibitors: 'Exhibitors',
  settings: 'Profile',
  sessions: 'Programme',
  exhibitor_sessions: 'My programme',
  event_sessions: 'Event programme',
  myCalendar: 'My programme',
  eventCalendar: 'Event programme',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Visitor settings',
  basicInfo: 'Basic information',
  save: 'Save',
  additionalInfo: 'Additional information',
  formError: 'No information retrieved, form not available.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Exhibitors',
  subtitle: 'See exhibitors and check their products',
  download: 'Download',
  noExhibitors: 'No exhibitors to show.',
  exhibitor_information: 'Exhibitor information',
  contact_success: 'Your information was shared succesfuly!',
  all_exhibitors: 'All exhibitors',
  interacted_exhibitors: 'Exhibitors I interacted with',
  not_interacted_exhibitors: 'Exhibitors I didn’t interact with',
  filter_exhibitors: 'Filter exhibitors',
  contact_request: 'Leave my contact',
  company_info: 'Exhibitor information',
  job_offers: 'Products',
  apply_to_job_offer: "I'm interested",
  job_offer_info: 'Information about the offer',
  cover_letter: 'Why are you interested?',
  cover_letter_text: 'Let the exhibitor know why you’re interested and/or ask him a question about the offer',
  your_info_will_be_shared: 'Your information will be shared with the exhibitor',
  submit_my_application: 'Submit',
  successfully_submited: 'Successfully submitted ',
  already_applied: 'You have already shared your interest in this offer.',
  schedule_interview: 'Reserve your spot',
  select_time_slot: 'Choose a time slot to join',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Tell us about your visitor',
    createVisitor: 'Create visitor',
    modal: {
      title: 'Warning',
      subtitle: 'If you wish to manage your visitor, please login first.',
      skip: 'Skip',
      createAccount: 'Create account',
      login: 'Login',
    },
  },
  managedFields: {
    title: 'Additional information',
  },
  review: {
    title: 'Review information',
    name: 'Name',
  },
  completed: {
    title: 'Visitor created!',
    subtitle: 'Your visitor is ready. Thank you for your submission!',
    manage: 'Manage',
  },
  back: 'Back',
  next: 'Next',
  submit: 'Submit',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Talk to fellow visitors',
  chat: 'Chat',
  profile: 'Profile',
  signup_title: 'Do you wish to meet other visitors?',
  signup_message: 'You’re about to join the networking area. Your information will be available to other visitors.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Register for a session to join',
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
  all_sessions: 'All sessions',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessions in which you are registered',
};

const profile: IVisitorProfile = {
  visitor_information: 'Visitor information',
};

const products: IVisitorProducts = {
  title: 'Products',
  subtitle: 'Browse and interact with exhibitor products',
  products_same_brand: 'Products from the same brand',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile, products,
};
