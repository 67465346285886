import { ActionContext } from 'vuex';
import { IParams } from '@/api';
import {
  getProducts, deleteProduct, createProduct,
  updateProduct,
} from '@/api/products';
import { IProduct } from '@/models/product';

import {
  answerFormQuestion, getFormAnswers, getFormQuestions, updateFormQuestion,
  getFormFields,
  getProductForms,
  deleteFormAnswer,
} from '@/api/forms';
import { IFormQuestion, IFormAnswer } from '@/models/form';
import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateProduct extends IObjectState, IProduct {
}

export type IProductsState = IStateObject<IStateProduct> & {
  questions: IFormQuestion[];
  form: number;
};

const state: IProductsState = {
  list: [],
  selected: [],
  questions: [],
  form: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (products: IProduct[]) => products.map((product) => ({
  ...product,
  loading: false,
  error: false,
  message: '',
}));

export interface IProductParams extends IParams {
  event?: number;
  submitted?: boolean;
  payload?: { product?: Partial<IProduct>, questions?: IFormQuestion[], submitted?: boolean } & { answers?: IFormAnswer[] };
  form?: number;
  exhibitor?: number;
  exhibitor_sponsor_level?: string;
  search?: string;
  category?: number;
  category_name?: string;
  subcategory_name?: string;
  subcategory?: number;
  home_page?: boolean;
  favorite?: boolean;
}

const getters = {
  getProducts: (state: IProductsState) => state.list,
  getProductQuestions: (state: IProductsState) => state.questions,
  getProductsState: (state: IProductsState) => state,
  getSelectedProducts: (state: IProductsState) => state.selected,
};

const actions = {
  async fetchProducts(context: ActionContext<IProductsState, IState>, params: IProductParams) {
    context.commit('setProductsLoading');
    if (context.state.form === 0) {
      const response = await getProductForms(params.event);
      if (response.data && response.data.results[0]) {
        const form = response.data.results[0];
        context.commit('setProductsForm', form.id);
      }
    }
    const paramsR = { ...params, form: context.state.form };
    getProducts(paramsR)
      .then(async (response) => {
        let products = response.data.results;
        if (products && products.length > 0) {
          const responseQuestions = await getFormQuestions(paramsR.form);
          const responseFields = await getFormFields(paramsR.form);
          const questions = responseQuestions.data.results;
          const fields = responseFields.data.results;

          context.commit('setProductQuestions', questions);
          const getAnswers = () => Promise.all(products.map(async (product) => {
            const responseAnswers = await getFormAnswers(product.id);
            const answers = responseAnswers.data.results;
            return {
              ...product,
              answers: answers.map((answer) => {
                const question = questions.find((question) => answer.form_question === question.id);
                if (answer) {
                  return {
                    ...answer,
                    question,
                  };
                }
                return answer;
              }),
              data: fields.map((field) => {
                const question = questions.find((question) => question.form_field === field.id);
                const answer = answers.find((answer) => answer.form_question === question?.id);
                return {
                  display_name: question?.display_name,
                  field_name: field.name,
                  value: answer?.value,
                };
              }),
            } as IProduct;
          }));

          products = await getAnswers();
        }
        const productState: Partial<IProductsState> = {
          list: addStateProps(products),
          page: response.data.current,
          page_count: response.data.page_count,
        };
        context.commit('setProducts', productState);
      })
      .catch((err) => {
        context.commit('setProductsError', err.request);
      });
  },

  async addProduct(context: ActionContext<IProductsState, IState>, params: IProductParams) {
    context.commit('setProductsLoading');
    if (params.payload && params.payload.questions && params.payload.product) {
      if (context.state.form === 0) {
        const response = await getProductForms(params.event);
        if (response.data && response.data.results[0]) {
          const form = response.data.results[0];
          context.commit('setProductsForm', form.id);
        }
      }
      params.payload.product.form = context.state.form;
      createProduct(params.payload.product as IProduct).then(async (response) => {
        const productId = response.data.id;
        const answerQuestions = (questions: IFormQuestion[]) => Promise.all(questions.map(async (question) => {
          if (question.answer && question.answer !== '') {
            await answerFormQuestion({
              form_query: productId,
              form_question: question.id,
              value: question.answer,
            });
          }
        })).catch((error) => {
          context.commit('setProductsError', { message: error });
        });

        if (params && params.payload && params.payload.questions) {
          await answerQuestions(params.payload.questions);
          if (params.payload.submitted) {
            await updateProduct({ ...params.payload.product, submitted: true, id: productId });
          }
        }
        context.commit('newProduct', response.data);
      })
        .catch((err) => {
          context.commit('setProductsError', err.response.data);
        });
    }
  },

  async editProduct(context: ActionContext<IProductsState, IState>, params: IProductParams) {
    let allActionsSuccessful = true;

    if (params.payload && params.payload.product && params.payload.product.id) {
      const productId = params.payload.product.id;
      try {
        context.commit(
          'setLoadingProduct',
          context.state.list.find((product) => productId === product.id),
        );
        const answerQuestions = (questions: IFormQuestion[]) => Promise.all(questions.map(async (question) => {
          const answer = params.payload?.answers?.find((answer) => answer.question?.form_field === question.form_field);

          if (question.answer && typeof question.answer !== 'object') {
            if (answer) {
              await updateFormQuestion({
                id: answer.id,
                form_query: productId,
                form_question: question.id,
                value: question.answer,
              });
            } else {
              await answerFormQuestion({
                form_query: productId,
                form_question: question.id,
                value: question.answer,
              });
            }
          } else if (answer && question.answer === '') {
            await deleteFormAnswer(answer);
          }
        })).catch((error) => {
          context.commit('setProductError', { failedProduct: productId, message: error.message });
          allActionsSuccessful = false;
        });

        if (params && params.payload && params.payload.questions) {
          await answerQuestions(params.payload.questions);
          await updateProduct({ id: productId, submitted: params.payload.product.submitted });
          context.commit('editProduct', { allActionsSuccessful });
        }
      } catch (error) {
        // Catch await errors
        allActionsSuccessful = false;
        context.commit('setProductError', { failedProduct: productId, message: error.response.data });
      }
    }
  },
  deleteProduct(context: ActionContext<IProductsState, IState>, deletedProduct: IStateProduct) {
    context.commit('setLoadingProduct', deletedProduct);
    if (deletedProduct.id) {
      deleteProduct(deletedProduct.id)
        .then((response) => {
          console.log(response);
          context.commit('removeProduct', deletedProduct);
        })
        .catch((err) => {
          context.commit('setProductError', { failedProduct: deletedProduct, message: err.message });
        });
    }
  },

  deleteProducts(context: ActionContext<IProductsState, IState>, ids: number[]) {
    context.commit('setLoadingProducts', ids);

    // axios
    //   .delete(`${url}/product/bulk_destroy?ids=${ids.join('&ids=')}`, config())
    //   .then(response => {
    //     context.commit('removeProducts', ids);
    //   })
    //   .catch(err => {
    //     context.commit('setProductsError', { ids, message: err.message });
    //   });
  },
};

const mutations = {
  setProductsError: (state: IProductsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setProductError: (state: IProductsState, { failedProduct, message }: { failedProduct: IStateProduct; message: string }) => {
    state.list = state.list.map((product) => (product.id === failedProduct.id
      ? {
        ...product,
        loading: false,
        error: true,
        message,
      }
      : product));
  },

  setProductsLoading: (state: IProductsState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingProduct: (state: IProductsState, product: IStateProduct) => {
    state.list[state.list.indexOf(product)] = {
      ...state.list[state.list.indexOf(product)],
      loading: true,
      error: false,
    };
  },
  setProductsForm: (state: IProductsState, form: number) => {
    state.form = form;
  },
  setProducts: (state: IProductsState, products: IProductsState) => {
    state.error = false;
    state.loading = false;
    state.list = products.list;
    state.page = products.page;
    state.page_count = products.page_count;
  },

  setProductQuestions: (state: IProductsState, productQuestions: IFormQuestion[]) => {
    state.error = false;
    state.loading = false;
    state.questions = productQuestions;
  },

  editProduct: (state: IProductsState, editedProduct: IStateProduct) => {
    state.list = state.list.map((product) => (product.id === editedProduct.id
      ? {
        ...editedProduct,
        loading: false,
        error: false,
      }
      : product));
  },

  newProduct: (state: IProductsState, product: IStateProduct) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(product);
  },

  removeProduct: (state: IProductsState, deletedProduct: IProduct) => {
    state.list = state.list.filter((product) => product.id !== deletedProduct.id);
  },

  removeProducts: (state: IProductsState, ids: number[]) => {
    state.list = state.list.filter((product) => product.id && ids.indexOf(product.id) === -1);
  },

  setLoadingProducts: (state: IProductsState, ids: number[]) => {
    state.list = state.list.map((product) => ({
      ...product,
      loading: !!(product.id && ids.indexOf(product.id) > -1),
      error: false,
    }));
  },
  selectProduct: (state: IProductsState, product: IStateProduct) => {
    if (product.id) {
      const productIndex = state.selected.indexOf(product.id);
      if (productIndex > -1) {
        state.selected.splice(productIndex, 1);
      } else {
        state.selected.push(product.id);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
