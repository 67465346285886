import { IAccountSettingsTranslations } from './index';

const settings: IAccountSettingsTranslations = {
  accountSettings: 'Configurações da conta',
  changeAccountInfo: 'Alterar informação da conta',
  save: 'Guardar',
  resetPassword: 'Redefinir palavra passe',
  password_confirm: 'Confirme a palavra passe',
  password_confirm_placeholder: 'Insira a palavra passe novamente',
  no_profile_pic: 'You have no profile picture. Please upload it',
  here: 'here',
};

export default { settings };
