import { IEventsTranslations } from '@/translations/default/events';

const events: Partial<IEventsTranslations> = {
  createExhibitor: 'Inscrire un exposant',
  createExhibitorDescription: 'Inscrivez votre exposant à cet événement.',
  manageExhibitor: 'Gérer un exposant',
  manageExhibitorDescription: 'Modifier les informations relatives à un exposant, voir les interactions.',
};

export default events;
