import { IAccountSettingsTranslations } from './index';

const settings: IAccountSettingsTranslations = {
  accountSettings: 'Configuración de cuenta',
  changeAccountInfo: 'Cambiar información de cuenta',
  save: 'Guardar',
  resetPassword: 'Resetear password',
  password_confirm: 'Confirmar password',
  password_confirm_placeholder: 'Vuelva a escribir la password',
  no_profile_pic: 'No tienes foto de perfil. Por favor, súbela',
  here: 'aquí',
};

export default { settings };
