const activityCenter = {
  title: 'Activity center',
  noRecentActivity: 'No recent activity',
  inProgress: 'In progress',
  recentFiles: 'Recent files',
};
const messages = {
  title: 'Messages',
};

const scanner = {
  title: 'QR code scanner',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'Anmeldung erforderlich',
  text: 'Bitte melden Sie sich als Gast dieser Veranstaltung an, um auf diese Funktion zuzugreifen.',
};

const sessionEnded = {
  title: "You've left the session!",
  subtitle: "Keep browsing?",
  backToCalendar: 'Back to calendar',
  backToEvent: 'Back to event',
}

export default {
  activityCenter, messages, scanner, helpdesk, loginPrompt, sessionEnded,
};
