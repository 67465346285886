const activityCenter = {
  title: 'Centro de actividad',
  noRecentActivity: 'No hay actividad reciente',
  inProgress: 'En progreso',
  recentFiles: 'Archivos recientes',
};
const messages = {
  title: 'Messages',
};

const scanner = {
  title: 'Escáner de código QR',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'Es necesario iniciar sesión',
  text: 'Inicie sesión como invitado de este evento para acceder a esta función',
};

const sessionEnded = {
  title: "Has abandonado la sesión!",
  subtitle: "Seguir navegando?",
  backToCalendar: 'Volver al calendario',
  backToEvent: 'Volver a casa',
}

export default {
  activityCenter, messages, scanner, helpdesk, loginPrompt, sessionEnded,
};
