import {
  IExhibitorsDashboardTranslations, IExhibitorSidebar,
  IExhibitorSettingsTranslations, IExhibitorProducsTranslations, IExhibitorInteractionsTranslations, IExhibitorOnboardingTranslations, IExhibitorSessions, IExhibitorProductInteractions, IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  home: 'Home',
  product_interactions_description: 'Anzahl der erhaltenen Bewerbungen auf Jobangebote:',
  information: 'Profil',
  name: 'Name des Ausstellers:',
  edit: 'Bearbeiten',
  interactions: 'Spontanbewerbungen',
  nrOfInteractions: 'Anzahl der erhaltenen Spontanbewerbungen',
  seeAll: 'Berwerbungen ansehen',
  download: 'Herunterladen',
  sessions: 'Kalender',
  upcoming_sessions: 'Nächste Sitzungen',
  see_all_sessions: 'Alle Sitzungen ansehen',
  applications: 'Bewerbungen für Jobangebote',
  see_applications: 'Bewerbungen ansehen',
  choose_exhibitor: 'Wählen Sie den Aussteller, den Sie verwalten möchten',
  schedule_meetings: 'Geplante Meetings',
  check_scheduled_meetings: 'Überprüfen Sie die Meetings, die Kandidaten bereits geplant haben',
};

const sidebar: Partial<IExhibitorSidebar> = {
  title: 'Ausstellen',
  home: 'Home',
  exhibitor_interactions: 'Spontanbewerbungen',
  exhibitor_sessions: 'Kalender',
  interactions: 'Kandidaten',
  sessions: 'Kalender',
  settings: 'Profil',
  products: 'Job-Angebote',
  product_interactions: 'Bewerbungen für Jobangebote',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  title: 'Ausstellereinstellungen',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Spontanbewerbungen',
  subtitle: 'Bewerbungen überprüfen und Meetings planen',
  download: 'Herunterladen',
  noInteractions: 'Dieser Aussteller hat keine Interaktionen.',
  interaction: {
    editComment: 'Kommentar bearbeiten',
    saveComment: 'Kommentar speichern',
    checkIn: 'Check-In',
  },
  search_placeholder: 'Geben Sie ein, wonach Sie in der Liste der Spontanbewerbungen suchen möchten',
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Job-Angebote',
  subtitle: 'Job-Angebote erstellen, bearbeiten und löschen',
  no_products: 'Keine Job-Angebote verfügbar',
  new_product: 'Neues Job-Angebot',
  create_new_product: 'Neues Job-Angebot erstellen',
  edit_product: 'Job-Angebot bearbeiten',
  delete_confirm: 'Sind Sie sicher, dass Sie dieses Job-Angebot löschen möchten?',
  interactions_exist_cannot_delete: 'Dieses Job-Angebot hat bereits Bewerber, deshalb kann es nicht gelöscht werden',
  search_placeholder: 'Geben Sie ein, wonach Sie in der Liste der Job-Angebote suchen möchten',
  inputs: {
    name: 'Name',
  },
};

const onboarding: DeepPartial<IExhibitorOnboardingTranslations> = {
  login: {
    title: 'Sie sind dabei, Ihren Aussteller anzumelden',
    subtitle: 'Erstlogin oder Anmeldung',
    message: 'Um Ihren Aussteller anzumelden, müssen Sie sich einloggen oder ein neues beamian-Konto anlegen.',
    exhibitor_manager_name: 'Geben Sie den Namen des Verantwortlichen des Ausstellers ein',
    exhibitor_manager_email: 'Geben Sie die E-Mail des Verantwortlichen des Ausstellers ein',
  },
  basicInformation: {
    title: 'Erzählen Sie uns von Ihrem Aussteller.',
    warning: 'Die von Ihnen angegebenen Informationen werden zur Erstellung der Broschüre sowie zur Erstellung eines Profils verwendet, das die Besucher sehen können.',
    createExhibitor: 'Aussteller erstellen',
    exhibitor_name: 'Ausstellername',
    exhibitor_name_placeholder: 'Geben Sie den Namen Ihres Ausstellers ein',
    modal: {
      title: 'Warnung',
      subtitle: 'Wenn Sie Ihren Aussteller verwalten möchten, melden Sie sich bitte zuerst an.',
      skip: 'Überspringen',
      createAccount: 'Benutzerkonto anlegen',
      login: 'Einloggen',
    },
  },
  completed: {
    title: 'Aussteller erstellt!',
    subtitle: 'Ihr Aussteller ist bereit. Vielen Dank für Ihre Einreichung!',
    manage: 'Verwalten',
  },
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning: 'Crie uma sessão privada para convidar candidatos específicos para uma entrevista. Se for uma sessão pública, qualquer candidato poderá visualizar e registar-se na sua sessão.',
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: 'Bewerbungen für Jobangebote',
  subtitle: 'Bewerbungen überprüfen und Meetings planen',
  product_name: 'Job-Angebot',
  interaction_name: 'Name des Kandidaten',
  application: 'Anwenden',
  invite_to_session: 'Meeting planen',
  send_invite: 'Einladung schicken',
  user_already_registered: 'Dieser Nutzer ist bereits für dieses Meeting angemeldet',
  publish_warning: 'Indem Sie dieses Formular veröffentlichen, wird die Information für Kandidaten ersichtlich sein',
  invitation_sent: 'Einladung erfolgreich verschickt',
  search_placeholder: 'Geben Sie ein, wonach Sie in der Liste der Spontanbewerbungen suchen möchten',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Ausstellerinformation',
  products: 'Job-Angebote',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
