import auth from './auth/en';
import common from './common/en';
import dashboard from './dashboard/en';
import table from './table/en';
import account from './account/en';
import events from './events/en';
import exhibitors from './exhibitors/en';
import visitors from './visitors/en';
import root from './root/en';

export default {
  auth, common, dashboard, table, account, events, exhibitors, visitors, root,
};
