import {
  IVisitorsDashboardTranslations, IVisitorSidebar, IVisitorExhibitorTranslations, IVisitorProducts, IVisitorSessions,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  home: 'Home',
  information: 'Profil',
  networking: 'Networking',
  networking_description: 'Andere Kandidaten treffen',
  sessions: 'Kalender',
  sessions_description: 'Anzahl der Sitzungen im Event-Kalender:',
  sessions_btn: 'Event-Kalender ansehen',
  name: 'Name des Kandidaten:',
  edit: 'Bearbeiten',
  exhibitors: 'Aussteller',
  nrOfInteractions: 'Anzahl der Male, die ich meinen Lebenslauf geteilt habe:',
  seeAll: 'Aussteller ansehen',
  download: 'Herunterladen',
  join_networking: 'Dem Networking-Bereich beitreten',
  event_finished: 'Event ist vorbei',
  event_not_start: 'Event hat noch nicht begonnen',
};

const sidebar: Partial<IVisitorSidebar> = {
  exhibitors: 'Aussteller',
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  title: 'Aussteller',
  subtitle: 'Aussteller ansehen und ihre Job-Angebote überprüfen',
  download: 'Herunterladen',
  noExhibitors: 'Keine Aussteller anzeigbar.',
  exhibitor_information: 'Ausstellerinformation',
  contact_success: 'Ihr Lebenslauf wurde erfolgreich geteilt!',
  all_exhibitors: 'Alle Aussteller',
  interacted_exhibitors: 'Aussteller, mit denen ich Informationen ausgetauscht habe',
  not_interacted_exhibitors: 'Aussteller, mit denen ich keine Informationen ausgetauscht habe',
  filter_exhibitors: 'Aussteller filtern',
  contact_request: 'Lebenslauf hinterlassen',
  company_info: 'Ausstellerinformation',
  job_offers: 'Job-Angebote',
  apply_to_job_offer: 'Anwenden',
  job_offer_info: 'Information zu dem Job-Angebot',
  cover_letter: 'Bewerbungsschreiben',
  cover_letter_text: 'Teilen Sie uns mit, warum Sie die/der Richtige für diese Stelle sind',
  your_info_will_be_shared: 'Ihr Profil wird mit der Firma geteilt',
  submit_my_application: 'Einreichen',
  successfully_submited: 'Bewerbung erfolgreich eingereicht',
  already_applied: 'Sie haben sich bereits für diese Stelle beworben',
  schedule_interview: 'Reservieren Sie sich Ihren Platz',
  select_time_slot: 'Wählen Sie einen Time-Slot um beizutreten',
};

const sessions: Partial<IVisitorSessions> = {
  exhibitor_sessions: 'Company sessions',
};

const products: IVisitorProducts = {
  title: 'Jobangebote',
  subtitle: 'Stellenangebote durchsuchen und bewerben',
  products_same_brand: 'Offers from the same teacher',
};

export default {
  dashboard, exhibitors, sidebar, products, sessions,
};
