import { IAccountSettingsTranslations } from './index';

const settings: IAccountSettingsTranslations = {
  accountSettings: 'Definições da conta',
  changeAccountInfo: 'Altere os seus dados pessoais',
  save: 'Guardar',
  resetPassword: 'Redefinir palavra passe',
  password_confirm: 'Confirme palavra passe',
  password_confirm_placeholder: 'Insira a palavra passe novamente',
  no_profile_pic: 'Não tem imagem de perfil. Por favor adicione',
  here: 'aqui',
};

export default { settings };
