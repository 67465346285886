import { IDashboardTranslations } from '.';
import common from '../common/en';

const dashboard: IDashboardTranslations = {
  ...common,
  welcome: 'Bienvenido a beamian admin',
  whatsup: 'Qué estás hacienda hoy?',
  create_event: 'Crear evento',
  create_event_description: 'I want to create a new event',
  create_device: 'Create device',
  create_device_description: 'I want to create a new device',
  events: 'Events',
  manage_events: 'I want to manage events',
  devices: 'Devices',
  manage_devices: 'I want to manage devices',
  categories: 'Categories',
  manage_categories: 'I want to manage device categories',
  models: 'Models',
  manage_models: 'I want to manage device models',
};

export default dashboard;
