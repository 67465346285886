import { IEventsTranslations } from '.';

const events: IEventsTranslations = {
  title: 'Willkommen bei beamian!',
  subtitle: 'Bitte wählen Sie ein Event',
  rolesTitle: 'Willkommen zu',
  rolesSubtitle: 'Was möchten Sie tun?',
  registerVisitor: 'Besucher registrieren',
  registerVisitorDescription: 'Registrieren Sie sich oder eine andere Person als Besucher dieser Veranstaltung.',
  createExhibitor: 'Aussteller registrieren',
  createExhibitorDescription: 'Registrieren Sie Ihren Aussteller für diese Veranstaltung.',
  manageVisitor: 'Besucher verwalten',
  manageVisitorDescription: 'Bearbeiten Sie Besucherinformationen, siehe Interaktionen.',
  manageExhibitor: 'Aussteller verwalten',
  manageExhibitorDescription: 'Ausstellerinformationen bearbeiten, siehe Interaktionen.',
  manageEvent: 'Veranstaltung verwalten',
  manageEventDescription: 'Greifen Sie auf Ereigniseinstellungen, Informationen und Inhalte zu.',
};

export default events;
