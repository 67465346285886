import { ICommonTranslations } from './index';

const common: ICommonTranslations = {
  dashboard: 'Dashboard',
  events: 'Veranstaltungen',
  devices: 'Geräte',
  exhibitors: 'Aussteller',
  exhibitor: 'Aussteller',
  logout: 'Ausloggen',
  edit: 'Bearbeiten',
  create: 'Erstellen',
  delete: 'Löschen',
  name: 'Name',
  name_placeholder: 'Geben Sie Ihren Namen ein',
  email: 'E-Mail',
  description: 'Beschreibung',
  category: 'Kategorie',
  model: 'Modell',
  save: 'Speichern',
  download: 'Herunterladen',
  upload: 'Hochladen',
  change: 'Veränderung',
  apply: 'Anwenden',
  emptyFieldNotAllowed: 'Dieses Feld darf nicht leer sein.',
  next: 'Nächste',
  previous: 'Bisherige',
  close: 'Schließen',
  cancel: 'Abbrechen',
  confirm: 'Bestätigen',
  search: 'Suche',
  mandatory_field: 'Dieses Feld ist verpflichtend.',
  name_in_use: 'Name bereits vergeben.',
  crop: 'Zuschneiden',
  published: 'Veröffentlicht',
  not_published: 'Unveröffentlicht',
  all: 'Alle',
  empty_list: 'Keine Daten verfügbar',
  user_chat_failed: 'Konnte Chat mit diesem User nicht öffnen',
  activity_center: 'Activity-Center',
  no_recent_activity: 'Keine aktuelle Aktivität',
  unread_messages: 'Ungelesene Nachrichten',
  reply: 'Antworten',
  session_invitations: 'Unbeantwortete Einladungen',
  accept: 'Akzeptieren',
  refuse: 'Ablehnen',
  in_progress: 'In Bearbeitung',
  recent_files: 'Letzte Dateien',
  open_conversations: 'Offene Konversationen',
  all_fields_required: 'Alle Felder erforderlich',
  something_went_wrong: 'Etwas ist schiefgelaufen',
  already_exists: 'Existiert bereits',
  profile: 'Mein Konto',
  filter_by_status: 'Nach Status filtern',
  open_meeting: 'Offenes Meeting ',
  visitors: 'Besucher',
  saved: 'Gespeichert',
  back: 'Zurück',
  session_full: 'Sitzung ist voll',
  file_not_image: 'Diese Datei ist kein Bild',
  new_export: 'Neuer Export',
  state: 'Status',
  details: 'Details',
  see_more: 'Mehr sehen',
  manage: 'Verwalten',
  see_as_visitor: 'Als Besucher ansehen',
  see_as_exhibitor: 'Als Aussteller ansehen',
  manage_visitors: 'Besucher verwalten',
  manage_exhibitors: 'Aussteller verwalten',
  manage_event: 'Veranstaltung verwalten',
  registered: 'registriert',
  refused: 'abgelehnt',
  pending: 'ausstehend',
  accepted: 'akzeptiert',
  favorites: 'Favorites',
  add_to_favorites: 'Add to favorites',
  remove_from_favorites: 'Remove from favorites',
  contact_request: 'Contact request',
  invited: 'Invited',
  productInteractions: 'Interações com produtos',
  note: 'Nota',
  discard: 'Descartar',
  no_comment: 'No comment',
  click_here: 'Click here',
};

export default common;
