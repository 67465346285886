import { IAccountSettingsTranslations } from './index';

const settings: IAccountSettingsTranslations = {
  accountSettings: 'Paramètres du compte',
  changeAccountInfo: 'Modifier les informations de votre compte',
  save: 'Sauvegarder',
  resetPassword: 'Réinitialiser le mot de passe',
  password_confirm: 'Confirmer le mot de passe',
  password_confirm_placeholder: 'Insérer à nouveau votre mot de passe',
  no_profile_pic: "Vous n'avez pas de photo de profil. Veuillez en télécharger une",
  here: 'ici',
};

export default { settings };
