import axios from 'axios';
import { IInteraction, IInteractionsExport } from '@/models/interaction';
import { IStateInteractionsExport, IFetchInteractionsParams } from '@/store/modules/interactions';

import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

// export const fetchInteractions = (eventId: number) => new Promise((resolve: Resolve<IInteraction[]>, reject) => {
//   axios
//     .get(`${url}/organizers/event/${eventId}/interactions`, config())
//     .then(response => {
//       resolve(response);
//     })
//     .catch(err => {
//       reject(err);
//     });
// });

export const editInteraction = (interaction: IInteraction) => new Promise((resolve: Resolve<IData<IInteraction>>, reject) => {
  axios
    .patch(`${url}/v2/interactions/${interaction.id}`, interaction, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getExhibitorManagerInviteAccessToken = (token: string) => new Promise((resolve: Resolve<{ email: string; auth_token: string }>, reject) => {
  axios
    .get(`${url}/v2/exhibitor-manager-invites/${token}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getVisitorAccessToken = (token: string) => new Promise((resolve: Resolve<{ token: string, email: string, name: string }>, reject) => {
  axios
    .get(`${url}/v2/visitor-account-invites/${token}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const fetchInteractions = (params: IFetchInteractionsParams) => new Promise((resolve: Resolve<IData<IInteraction>>, reject) => {
  let requestUrl = `${url}/v2/interactions?page_size=${params.page_size}&page=${params.page}`;

  if (params.search !== undefined) {
    requestUrl += `&search=${params.search}`;
  }

  if (params.exhibitor !== undefined) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }
 
  if (params.visitor !== undefined) {
    requestUrl += `&visitor=${params.visitor}`;
  }

  axios
    .get(requestUrl, config(false))
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
export const fetchInteraction = (interactionId: number) => new Promise((resolve: Resolve<IInteraction>, reject) => {
  const requestUrl = `${url}/v2/interactions/${interactionId}`;

  axios
    .get(requestUrl, config(false))
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const fetchInteractionsByVisitor = (visitor: number) => new Promise((resolve: Resolve<IData<IInteraction>>, reject) => {
  axios
    .get(`${url}/v2/interactions?visitor=${visitor}`, config(false))
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addInteractionsExport = (interactionsExport: IInteractionsExport) => new Promise((resolve: Resolve<IInteractionsExport>, reject) => {
  axios
    .post(
      `${url}/v2/interactions-export?exhibitor=${interactionsExport.exhibitor}`,
      {
        filename: interactionsExport.filename,
        exhibitor: interactionsExport.exhibitor,
        page_size: 1,
        building: true,
      },
      config(false),
    )
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const editInteractionsExport = (interactionsExport: IInteractionsExport) => new Promise((resolve: Resolve<IInteractionsExport>, reject) => {
  axios
    .patch(`${url}/v2/interactions-export/${interactionsExport.id}`, interactionsExport, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const fetchInteractionsExports = (exhibitor: number) => new Promise((resolve: Resolve<IData<IInteractionsExport>>, reject) => {
  axios
    .get(`${url}/v2/interactions-export?exhibitor=${exhibitor}&ordering=-id`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const deleteInteractionsExport = (interactionsExport: IStateInteractionsExport) => new Promise((resolve: Resolve<IData<IInteractionsExport>>, reject) => {
  axios
    .delete(`${url}/v2/interactions-export/${interactionsExport.id}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
