import {
  IVisitorOnboardingTranslations,
  IVisitorExhibitorTranslations, IVisitorSettingsTranslations, IVisitorSidebar, IVisitorsDashboardTranslations, IVisitorNetworkingTranslations, IVisitorExhibitorSessions, IVisitorSessions, IVisitorProfile, IVisitorProducts,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Profil',
  networking: 'Networking',
  networking_description: 'Andere Besucher treffen',
  sessions: 'Kalender',
  sessions_description: 'Anzahl der Sitzungen im Event-Kalender:',
  sessions_btn: 'Event-Kalender ansehen',
  name: 'Name des Besuchers:',
  edit: 'Bearbeiten',
  exhibitors: 'Aussteller',
  nrOfInteractions: 'Anzahl der Male, die ich meine Kontakte geteilt habe:',
  seeAll: 'Aussteller ansehen',
  download: 'Herunterladen',
  join_networking: 'Dem Networking-Bereich beitreten',
  event_finished: 'Event ist vorbei',
  event_not_start: 'Event hat noch nicht begonnen',
  featured_products: 'Featured products',
  featured_sessions: 'Featured sessions',
  featured_exhibitors: 'Featured exhibitors',
  upcoming_exhibitors: 'Next sessions',
};

const sidebar: IVisitorSidebar = {
  title: 'Besuch',
  home: 'Home',
  exhibitors: 'Aussteller',
  settings: 'Profil',
  sessions: 'Kalender',
  exhibitor_sessions: 'Mein Kalender',
  event_sessions: 'Ereignis-Sitzungen',
  myCalendar: 'Mein Kalender',
  eventCalendar: 'Veranstaltungskalender',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Besuchereinstellungen',
  basicInfo: 'Basisinformation',
  save: 'Speichern',
  additionalInfo: 'Zusätzliche Information',
  formError: 'Keine Informationen abgerufen, Formular nicht verfügbar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Aussteller',
  subtitle: 'Aussteller ansehen und ihre Angebote überprüfen',
  download: 'Herunterladen',
  noExhibitors: 'Keine Aussteller anzeigbar',
  exhibitor_information: 'Ausstellerinformation',
  contact_success: 'Ihre Information wurde erfolgreich geteilt!',
  all_exhibitors: 'Alle Aussteller',
  interacted_exhibitors: 'Aussteller, mit denen ich interagiert habe',
  not_interacted_exhibitors: 'Aussteller, mit denen ich nicht interagiert habe',
  filter_exhibitors: 'Aussteller filtern',
  contact_request: 'Kontakt hinterlassen',
  company_info: 'Ausstellerinformation',
  job_offers: 'Angebote',
  apply_to_job_offer: 'Ich bin interessiert',
  job_offer_info: 'Information zu dem Angebot',
  cover_letter: 'Warum sind Sie interessiert?',
  cover_letter_text: 'Lassen Sie den Aussteller wissen, warum Sie interessiert sind und/oder fragen Sie ihn etwas über das Angebot',
  your_info_will_be_shared: 'Ihre Information wird mit dem Aussteller geteilt',
  submit_my_application: 'Einreichen',
  successfully_submited: 'Erfolgreich eingereicht ',
  already_applied: 'Sie haben bereits Ihr Interesse an dem Angebot bekundet.',
  schedule_interview: 'Reservieren Sie sich Ihren Platz',
  select_time_slot: 'Wählen Sie einen Time-Slot um beizutreten',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Erzählen Sie uns von Ihrem Besucher.',
    createVisitor: 'Besucher erstellen',
    modal: {
      title: 'Warnung',
      subtitle: 'Wenn Sie Ihren Besucher verwalten möchten, melden Sie sich bitte zuerst an.',
      skip: 'Überspringen',
      createAccount: 'Benutzerkonto anlegen',
      login: 'Einloggen',
    },
    already_registered: 'You are already registered in this event!',
    send_login_link: 'Click here to receive an email with the login link.',
  },
  tickets: {
    title: 'Redeem ticket code',
    subtitle: 'Registration requires a valid ticket',
    message_head: "Don't have a ticket yet?",
    to_buy_tickets: 'to buy tickets;',
    copy_ticket_code: "Copy the ticket code we'll send you to your email and register with it.",
    ticket_code_invalid: 'Ticket code is invalid or has already been used',
    ticket_code_label: 'Ticket code',
    ticket_code_placeholder: 'Insert your ticket code',
  },
  managedFields: {
    title: 'Zusätzliche Information',
  },
  review: {
    title: 'Überprüfen Sie die Informationen',
    name: 'Name',
  },
  completed: {
    title: 'Besucher erstellt!',
    subtitle: 'Ihr Besucher ist bereit. Vielen Dank für Ihre Einreichung!',
    manage: 'Verwalten',
  },
  back: 'Zurück',
  next: 'Nächste',
  submit: 'Einreichen',
  not_allowed_title: 'Your account is pending aproval',
  not_allowed_subtitle: "Please wait for the event manager's approval",
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Mit andere Besuchern sprechen',
  chat: 'Chat',
  profile: 'Profil',
  signup_title: 'Möchten Sie andere Besucher treffen?',
  signup_message: 'Sie sind dabei, dem Networking-Bereich beizutreten Ihre Information wird für andere Besucher verfügbar sein.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Melden Sie sich für die Teilnahme an einer Sitzung an',
  event_sessions: 'Ereignissitzungen',
  exhibitor_sessions: 'Ausstellersitzungen',
  all_sessions: 'All sessions',
  join_in_15: "You'll be able to join 15 minutess before the session starts",
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sitzungen, für die Sie angemeldet sind',
};

const profile: IVisitorProfile = {
  visitor_information: 'Besucherinformation',
};

const products: IVisitorProducts = {
  title: 'Produkte',
  subtitle: 'Durchsuchen und interagieren Sie mit Ausstellerprodukten',
  products_same_brand: 'Offers from the same brand',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile, products,
};
