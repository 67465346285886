import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  dashboard: 'Dashboard',
  events: 'Events',
  devices: 'Devices',
  exhibitors: 'Exhibitors',
  exhibitor: 'Exhibitor',
  logout: 'Logout',
  edit: 'Edit',
  create: 'Create',
  delete: 'Delete',
  name: 'Nombre',
  name_placeholder: 'Introduzca su nombre',
  email: 'Email',
  description: 'Description',
  category: 'Category',
  model: 'Model',
  save: 'Save',
  download: 'Download',
  upload: 'Upload',
  change: 'Change',
  apply: 'Apply',
  emptyFieldNotAllowed: 'This field must not be empty',
  next: 'Next',
  previous: 'Previous',
  close: 'Close',
  cancel: 'Cancel',
  confirm: 'Confirm',
  search: 'Search',
  mandatory_field: 'Este campo es requerido.',
  name_in_use: 'Name already taken.',
  crop: 'Cortar',
  published: 'Published',
  not_published: 'Not published',
  all: 'All',
  empty_list: 'Datos no disponibles',
  user_chat_failed: 'No se pudo abrir el chat con esta usuario',
  activity_center: 'Centro de actividades',
  no_recent_activity: 'No hay actividad reciente',
  unread_messages: 'Mensajes no leídos',
  reply: 'Responder',
  session_invitations: 'Invitaciones sin respuesta',
  accept: 'Aceptar',
  refuse: 'Rechazar',
  in_progress: 'En progreso',
  recent_files: 'Archivos recientes',
  open_conversations: 'Conversaciones abiertas',
  all_fields_required: 'All fields required',
  something_went_wrong: 'Something went wrong',
  already_exists: 'Already exists',
  profile: 'Perfil',
  filter_by_status: 'Filter by status',
  open_meeting: 'Entrar a la reunión',
  visitors: 'Visitantes',
  saved: 'Saved',
  back: 'Back',
  session_full: 'Full',
  file_not_image: 'Este archivo no es una imagen',
  new_export: 'Exportación nueva',
  state: 'Estado',
  details: 'Details',
  see_more: 'Ver mais',
  manage: 'Manage',
  see_as_visitor: 'See as visitor',
  see_as_exhibitor: 'See as exhibitor',
  manage_visitors: 'Manage candidates',
  manage_exhibitors: 'Manage exhibitors',
  manage_event: 'Manage exhibitors',
  registered: 'registered',
  refused: 'refused',
  pending: 'pending',
  accepted: 'accepted',
};

export default common;
