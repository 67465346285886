import { IEventsTranslations } from '.';

const events: IEventsTranslations = {
  title: 'Bienvenue sur la page de beamian',
  subtitle: 'Veuillez choisir un événement',
  rolesTitle: 'Bienvenue',
  rolesSubtitle: 'Que souhaitez-vous faire ?',
  registerVisitor: 'Inscrire un visiteur',
  registerVisitorDescription: 'Inscrivez-vous ou inscrivez une autre personne en tant que visiteur de cet événement.',
  createExhibitor: 'Inscrire un exposant',
  createExhibitorDescription: 'Inscrivez votre exposant à cet événement.',
  manageVisitor: 'Gérer un visiteur',
  manageVisitorDescription: 'Modifier les informations relatives à un visiteur, voir les interactions.',
  manageExhibitor: 'Gérer un exposant',
  manageExhibitorDescription: 'Modifier les informations relatives à un exposant, voir les interactions.',
  manageEvent: 'Gérer un événement',
  manageEventDescription: "Accéder aux paramètres, aux informations et au contenu de l'événement.",

  new: 'New event',
  edit: 'Edit event',
  create: 'Create event',
  send_registration_invite: 'Send registration invite',
  email: 'Email',
  name: 'Name',
  name_placeholder: 'Enter event name',
  assign: 'Assign',
  delete_manager: 'Delete event manager',
  no_managers_assigned: 'This event has no event managers assigned',
  start_date: 'Start date',
  end_date: 'End date',
  capacity: 'Capacity',
  location: 'Location',
  location_placeholder: 'Enter event location',
  is_published: 'Is published',
  base_cost: 'Base cost',
  max_capacity: 'Max capacity',
  delete: 'Delete event',
  assign_manager: 'Assign event manager (select 1 event only)',
  manager: 'Event manager',
  select_manager: 'Select event manager',
  timezone: 'Timezone',
  europe: 'Europe',
  lisbon: 'Lisbon',
  london: 'London',
  madrid: 'Madrid',
  berlin: 'Berlin',
  default_language: 'Default language',
  portuguese: 'Portuguese',
  spanish: 'Spanish',
  english: 'English',
  german: 'German',
  french: 'French',
  description: 'Description',
  description_placeholder: 'Enter an event description',
  image: 'Image',
  image_placeholder: 'Click to upload image',
  background_image: 'Background image',
  background_image_placeholder: 'Click to upload background image',
  created_success: 'Event created successfuly',
  manager_email_placeholder: 'Enter user email',
  managers: 'Event managers',
  manager_added_success: 'Event manager added successfuly',
  manager_deleted_success: 'Event manager deleted successfuly',
  manager_user_not_exists: 'User does not exist. Make sure you typed the email correctly or send a registration invitation to this email.',
  manager_setup: 'Choose name and password',
  manager_user_password: 'Password',
  manager_user_name_placeholder: 'Enter user name',
  manager_user_password_placeholder: 'Enter user password',
  setup_booklet: 'Set up booklet',
  booklet: 'Booklet',
  configure_booklet: 'If you want to send a booklet when the event ends please configure it.',
  no_fields_selected: 'No fields selected.',
  drag_drop_fields: 'Drag and drop fields to set order.',
  title_placeholder: 'Enter booklet title',
  cover: 'Cover',
  cover_placeholder: 'Upload booklet cover image',
  fields: 'Fields',
  create_booklet_success: 'Booklet created',
  create_booklet_error: 'Booklet creation failed',
  edit_booklet_success: 'Booklet edited',
  edit_booklet_error: 'Booklet editing failed',
  no_available_fields: 'No available fields',
  empty_space: 'Empty space',
  live_interactions_email: 'Live interactions email invitation',
};

export default events;
