import store from '@/store';

export default (formData = false, customToken = '', ct = false) => {
  const token = customToken !== '' ? customToken : store.getters.userAuthState.token;
  const contentType = formData ? 'multipart/form-data;' : 'application/vnd.beamian+json; version=1';
  if (ct) {
    return {
      headers: token
        ? {
          // tslint:disable-next-line: object-literal-key-quotes
          'content-type': contentType,
          'Accept-Language': store.getters.getLanguage,
        }
        : {
          // tslint:disable-next-line: object-literal-key-quotes
          'content-type': contentType,
          'Accept-Language': store.getters.getLanguage,
        },
    };
  }
  return {
    headers: token
      ? {
        // tslint:disable-next-line: object-literal-key-quotes
        Authorization: token !== '' ? `Token ${token}` : null,
        'content-type': contentType,
        'Accept-Language': store.getters.getLanguage,
      }
      : {
        // tslint:disable-next-line: object-literal-key-quotes
        'content-type': contentType,
        'Accept-Language': store.getters.getLanguage,
      },
  };
};

export const rocketConfig = () => ({
  headers: store.getters.getRocketAuth.rocket_chat_auth_token
    ? {
      // tslint:disable-next-line: object-literal-key-quotes
      'X-Auth-Token': store.getters.getRocketAuth.rocket_chat_auth_token,
      'X-User-Id': store.getters.getRocketAuth.rocket_chat_user_id,
      'Content-type': 'application/json',
    }
    : {
      'Content-type': 'application/json',
    },
});

export const signatureConfig = () => ({
  'Content-type': 'application/json',
});
