









































// @ is an alias to /src

import { defineComponent, PropType } from '@vue/composition-api';
import { ITextarea } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ITextarea>,
      required: true,
    },
    value: String,
  },

  setup(props, { emit }) {
    const updateValue = (value: string | number) => {
      emit('input', value);
    };
    return { updateValue };
  },
});
