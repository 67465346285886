import { ITableTranslations } from '.';

const table: ITableTranslations = {
  showing: 'Zeigen',
  of: 'von',
  items: 'Artikel',
  see: 'Ansehen',
  see_selected_only: 'Siehe nur Ausgewählte',
  clear_selection: 'Klare Auswahl',
  all_items: 'Alle Elemente',
  items_per_page: 'Objekte pro Seite:',
  previous: 'Bisherige',
  next: 'Nächste',
  table_settings: 'Tabellen-Einstellungen',
  search_settings: 'Sucheinstellungen',
  refresh_table: 'Tabelle aktualisieren',
  show_hide_columns: 'Spalten ein-/ausblenden',
  search_fields: 'Suchfelder',
  search_data: 'Suche',
};

export default table;
