import { ActionContext } from 'vuex';
import {
  addProductInteractionsExport,
  deleteProductInteractionsExport,
  editProductInteraction,
  editProductInteractionsExport,
  fetchProductInteractions,
  fetchProductInteractionsExports,
} from '@/api/productInteractions';
import { IProductInteractionsExport } from '@/models/product';
import { IProductInteraction } from '@/models/productInteraction';

import { IParams } from '@/api/index';
import { IState, IObjectState, IStateObject } from '..';

export interface IStateProductInteractionsExport extends IProductInteractionsExport, IObjectState {
}
export type IProductInteractionsExportState = IStateObject<IStateProductInteractionsExport>
export interface IStateProductInteraction extends IProductInteraction, IObjectState { }

export type IProductInteractionsState = IStateObject<IStateProductInteraction> & {
  exports: IProductInteractionsExportState;
}

export interface IProductInteractionsParams extends IParams {
  exhibitor?: number;
  event: number;
  search?: string;
  visitor?: number;
  product?: number;
}

const state: IProductInteractionsState = {
  list: [],
  exports: {
    list: [],
    selected: [],
    loading: false,
    message: '',
    error: false,
  },
  selected: [],
  page: 1,
  item_count: 0,
  page_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (items: (IProductInteraction | IProductInteractionsExport)[]) => items.map((item) => ({
  ...item,
  loading: false,
  success: false,
  error: false,
  message: '',
}));

const getters = {
  getProductInteractions: (state: IProductInteractionsState) => state.list,
  getProductInteractionsState: (state: IProductInteractionsState) => state,
  getProductInteractionsExports: (state: IProductInteractionsState) => state.exports.list,
  getProductInteractionsExportsState: (state: IProductInteractionsState) => state.exports,
};

const actions = {
  fetchProductInteractions(context: ActionContext<IProductInteractionsState, IState>, params: IProductInteractionsParams) {
    context.commit('setProductInteractionsLoading');
    fetchProductInteractions(params)
      .then((response) => {
        const productProductInteractions = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };
        context.commit('setProductInteractions', productProductInteractions);
      })
      .catch((err) => {
        context.commit('setProductInteractionsError', err.message);
      });
  },
  editProductInteraction(context: ActionContext<IProductInteractionsState, IState>, editedProductInteraction: IStateProductInteraction) {
    context.commit(
      'setLoadingProductInteraction',
      context.state.list.find((productProductInteraction) => editedProductInteraction.id === productProductInteraction.id),
    );
    editProductInteraction(editedProductInteraction)
      .then(() => {
        context.commit('editProductInteraction', editedProductInteraction);
      })
      .catch((err) => {
        context.commit('newProductInteraction', err.message);
      });
  },
  deleteProductInteraction(context: ActionContext<IProductInteractionsState, IState>, productProductInteraction: IStateProductInteraction) {
    context.commit('setLoadingProductInteraction', productProductInteraction);

    setTimeout(() => {
      context.commit('removeProductInteraction', productProductInteraction);
    }, 2000);

    // axios
    //   .delete(`https://b.beamian.com/api/v2/productProductInteractions/${productProductInteraction.slug}/`)
    //   .then(response => {
    //     context.commit('deleteProductInteraction', productProductInteraction);
    //   })
    //   .catch(err => { });
  },
  fetchProductInteractionsExports(context: ActionContext<IProductInteractionsState, IState>, exhibitor: number) {
    context.commit('setProductInteractionsExportsLoading');

    fetchProductInteractionsExports(exhibitor)
      .then((response) => {
        context.commit('setProductInteractionsExports', addStateProps(response.data.results));
      })
      .catch((err) => {
        context.commit('setProductInteractionsExportError', err.message);
      });
  },

  addProductInteractionsExport(context: ActionContext<IProductInteractionsState, IState>, productsExport: IProductInteractionsExport) {
    // context.commit('setProductsExportsLoading');
    addProductInteractionsExport(productsExport)
      .then((response) => {
        context.commit('newProductInteractionsExports', addStateProps([response.data])[0]);
      })
      .catch((err) => {
        context.commit('setProductInteractionsExportError', err.message);
      });
  },
  editProductInteractionsExport(
    context: ActionContext<IProductInteractionsState, IState>,
    interactionsExport: IStateProductInteractionsExport,
  ) {
    context.commit(
      'setLoadingInteractionsExport',
      context.state.exports.list.find((interaction) => interactionsExport.id === interaction.id),
    );

    editProductInteractionsExport(interactionsExport)
      .then((response) => {
        context.commit('editInteractionsExport', addStateProps([response.data])[0]);
      })
      .catch((err) => {
        context.commit('setInteractionsExportError', {
          interactionsExport: context.state.exports.list.find(
            (interaction) => interactionsExport.id === interaction.id,
          ),
          message: err.message,
        });
      });
  },
  deleteProductInteractionsExport(
    context: ActionContext<IProductInteractionsState, IState>,
    interactionsExport: IStateProductInteractionsExport,
  ) {
    context.commit(
      'setLoadingInteractionsExport',
      context.state.exports.list.find((interaction) => interactionsExport.id === interaction.id),
    );
    deleteProductInteractionsExport(interactionsExport)
      .then(() => {
        context.commit('removeInteractionsExport', interactionsExport);
      })
      .catch((err) => {
        context.commit('setInteractionsExportError', {
          interactionsExport: context.state.exports.list.find(
            (interaction) => interactionsExport.id === interaction.id,
          ),
          message: err.message,
        });
      });
  },
};

const mutations = {

  setProductInteractions: (state: IProductInteractionsState, productProductInteractions: IProductInteractionsState) => {
    state.loading = false;
    state.list = productProductInteractions.list;
    state.page = productProductInteractions.page;
    state.page_count = productProductInteractions.page_count;
    state.item_count = productProductInteractions.item_count;
  },

  resetProductInteractions: (state: IProductInteractionsState) => {
    state.loading = false;
    state.error = false;
    state.message = '';
  },

  setProductInteractionsError: (state: IProductInteractionsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setProductInteractionsLoading: (state: IProductInteractionsState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingProductInteraction: (state: IProductInteractionsState, productProductInteraction: IStateProductInteraction) => {
    state.list[state.list.indexOf(productProductInteraction)] = {
      ...state.list[state.list.indexOf(productProductInteraction)],
      loading: true,
      error: false,
    };
    state.error = false;
    state.loading = true;
  },

  editProductInteraction: (state: IProductInteractionsState, editedProductInteraction: IStateProductInteraction) => {
    state.list = state.list.map((productProductInteraction) => (productProductInteraction.id === editedProductInteraction.id
      ? {
        ...editedProductInteraction,
        loading: false,
        error: false,
      }
      : productProductInteraction));
    state.loading = false;
  },

  newProductInteraction: (state: IProductInteractionsState, productProductInteraction: IStateProductInteraction) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(productProductInteraction);
  },
  setProductInteractionsExports: (state: IProductInteractionsState, interactionsExports: IStateProductInteractionsExport[]) => {
    state.exports.loading = false;
    state.exports.list = interactionsExports;
  },

  setProductInteractionsExportsLoading: (state: IProductInteractionsState) => {
    state.exports.loading = true;
    state.exports.error = false;
  },

  setProductInteractionsExportError: (
    state: IProductInteractionsState,
    interactionsExportError: { interactionsExport: IStateProductInteractionsExport; message: string },
  ) => {
    const newList = [...state.exports.list];

    newList[newList.indexOf(interactionsExportError.interactionsExport)] = {
      ...state.exports.list[state.exports.list.indexOf(interactionsExportError.interactionsExport)],
      loading: false,
      error: true,
      message: interactionsExportError.message,
    };

    state.exports.list = newList;
  },
  removeProductInteraction: (state: IProductInteractionsState, deletedProductInteraction: IStateProductInteraction) => {
    state.list = state.list.filter((productProductInteraction) => productProductInteraction.id !== deletedProductInteraction.id);
  },
  editProductInteractionsExport: (state: IProductInteractionsState, productsExport: IStateProductInteractionsExport) => {
    state.exports.list = state.exports.list.map((interaction) => (interaction.id === productsExport.id
      ? {
        ...productsExport,
        loading: false,
        error: false,
      }
      : interaction));
  },

  newProductInteractionsExports: (state: IProductInteractionsState, productsExport: IStateProductInteractionsExport) => {
    state.exports.loading = false;
    state.exports.error = false;
    state.exports.list.unshift(productsExport);
  },

  removeProductInteractionsExport: (state: IProductInteractionsState, deletedInteraction: IStateProductInteractionsExport) => {
    state.exports.list = state.exports.list.filter((interaction) => interaction.id !== deletedInteraction.id);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
