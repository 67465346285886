import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  dashboard: 'Tableau de bord',
  events: 'Événements',
  devices: 'Dispositifs',
  exhibitors: 'Exposants',
  exhibitor: 'Exposant',
  logout: 'Se déconnecter',
  edit: 'Éditer',
  create: 'Créer',
  delete: 'Supprimer',
  name: 'Nom',
  name_placeholder: 'Entrer votre nom',
  email: 'Adresse électronique',
  description: 'Description',
  category: 'Catégorie',
  model: 'Modèle',
  save: 'Sauvegarder',
  download: 'Télécharger',
  upload: 'Télécharger',
  change: 'Modifier',
  apply: 'Appliquer',
  emptyFieldNotAllowed: 'Ce champ doit être rempli',
  next: 'Suivant',
  previous: 'Précédent',
  close: 'Fermer',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  search: 'Rechercher',
  mandatory_field: 'Ce champ est obligatoire.',
  name_in_use: 'Nom déjà utilisé.',
  crop: 'Recadrer',
  published: 'Publié',
  not_published: 'Non publié',
  all: 'Tout',
  empty_list: 'Aucune donnée disponible',
  user_chat_failed: "Impossible d'ouvrir le chat avec cet utilisateur",
  activity_center: "Centre d'activités",
  no_recent_activity: 'Aucune activité récente',
  unread_messages: 'Messages non lus',
  reply: 'Répondre',
  session_invitations: 'Invitations en attente',
  accept: 'Accepter',
  refuse: 'Refuser',
  in_progress: 'En cours',
  recent_files: 'Fichiers récents',
  open_conversations: 'Ouvrir des conversations',
  all_fields_required: 'Tous les champs sont requis',
  something_went_wrong: 'Un problème est survenu',
  already_exists: 'Existe déjà',
  profile: 'Mon compte',
  filter_by_status: 'Filtrer par statut',
  open_meeting: 'Ouvrir une réunion',
  visitors: 'Visiteurs',
  saved: 'Enregistré',
  back: 'Retour',
  session_full: 'La séance est complète',
  file_not_image: "Ce fichier n'est pas une image",
  new_export: 'Nouvelle exportation',
  state: 'État',
  details: 'Détails',
  see_more: 'En savoir plus',
  manage: 'Gérer',
  see_as_visitor: 'Voir en tant que visiteur',
  see_as_exhibitor: "Voir en tant qu'exposant",
  manage_visitors: 'Gérer les visiteurs',
  manage_exhibitors: 'Gérer les exposants',
  manage_event: 'Gérer un événement',
  registered: 'inscrit',
  refused: 'refusé',
  pending: 'en attente',
  accepted: 'accepté',
  favorites: 'Favorites',
  add_to_favorites: 'Add to favorites',
  remove_from_favorites: 'Remove from favorites',
  contact_request: 'Contact request',
  invited: 'Invited',
  productInteractions: 'Interacciones con productos',
  note: 'Nota',
  discard: 'Descarte',
  no_comment: 'Sin comentarios',
  click_here: 'Haga clic aquí',
};

export default common;
