import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions, IExhibitorInteractionsTranslations,
  IExhibitorSettingsTranslations, IExhibitorSidebar,
  IExhibitorsDashboardTranslations, IExhibitorProducsTranslations,
  IExhibitorProductInteractions, IExhibitorProfile,
  IExhibitorTickets, IExhibitorVisitors, IExhibitorExports,
} from '.';

const dashboard: IExhibitorsDashboardTranslations = {
  home: 'Painel de controle',
  product_interactions_description: 'Número de visitantes interessados em ofertas:',
  favorites: 'Favoritos',
  nr_of_favorites: 'Número de visitantes que marcaram este expositor como favorito:',
  information: 'Perfil',
  name: 'Nome do expositor:',
  view_as_visitor: 'Ver como visitante',
  edit: 'Alterar',
  interactions: 'Visitantes interessados no expositor',
  nrOfInteractions: 'Número de visitantes interessados no expositor:',
  seeAll: 'Ver contactos',
  download: 'Descarregar',
  sessions: 'Sessões',
  upcoming_sessions: 'Próximas sessões',
  see_all_sessions: 'Ver agenda',
  applications: 'Visitantes interessados em ofertas',
  see_applications: 'Ver contactos',
  choose_exhibitor: 'Escolha o expositor que deseja administrar',
  schedule_meetings: 'Sessões agendadas',
  check_scheduled_meetings: 'Consulte as sessões nas quais os visitantes já se inscreveram',
  exhibitor_pending_title: 'A aprovação do expositor está pendente',
  exhibitor_pending_text: 'O seu expositor não foi aprovado para entrar na platforma. Por favor espere até que o gestor do evento aprove o seu expositor.',
};

const exports: IExhibitorExports = {
  title: 'Exportações',
  subtitle: 'Gerir e descarregar suas exportações de contactos',
  export_contacts: 'Exportar contactos',
};

const visitors: IExhibitorVisitors = {
  title: 'Visitantes',
  subtitle: 'Ver informação de visitante, convidar para sessões privadas',
  assign_packages: 'Atribuir pacotes',
  visitor_information: 'Informação do visitante',
  assign_step_1: 'Encontra um visitante existente ou regista um novo',
  assign_step_2: 'Registar novo visitante',
  assign_step_3: 'Adicionar pacotes ao visitante',
  assign_step_4: 'Tudo feito!',
  assign_step_5: "Não pode atribuir mais pacotes",
  out_of_packages: 'Sem pacotes para atribuir.',
  package_name: 'Nome do pacote',
  package_assigned_successfuly: 'Pacotes atribuidos com sucesso!',
  assign_another: 'Atribuir novamente',
  invite_private_session: 'Convidar para uma sessão privada',
  user_not_registered: 'Este visitante não está registado para nenhuma sessão com o seu expositor.',
  enter_note_placeholder: 'Adicione uma nota',
}

const tickets: IExhibitorTickets = {
  title: 'Atribuições',
  subtitle: 'Bilhetes atribuidos a visitantes',
  new_assignment: 'Nova atribuição',
  assignee_name: 'Nome do recebedor/a',
  assignee_email: 'Email do recebedor/a',
  visitor_name: 'Nome do visitante',
  visitor_email: 'Email do visitante',
  ticket: 'Bilhete',
  ticket_type_title: 'Bilhetes',
  ticket_type_subtitle: 'Atribua bilhetes a visitantes',
  assign_title: 'Atribuir bilhete a visitante',
  assign: 'Atribuir',
  ticket_success: 'Bilhete atribuido com successo!',
};

const sidebar: IExhibitorSidebar = {
  title: 'Expositor',
  home: 'Painel de controle',
  interactions: 'Visitantes',
  exhibitor_interactions: 'Interessados no expositor',
  product_interactions: 'Interessados em ofertas',
  exhibitor_sessions: 'Sessões do expositor',
  sessions: 'Sessões',
  settings: 'Perfil',
  products: 'Ofertas',
  tickets: 'Bilhetes',
  exports: 'Exportações',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Definições de expositor',
  basicInfo: 'Informação básica',
  save: 'Guardar',
  additionalInfo: 'Informação adicional',
  formError: 'Sem informação disponivel.',
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Visitantes interessados no expositor',
  subtitle: 'Consultar lista de visitantes interessados no expositor',
  download: 'Descarregar',
  noInteractions: 'Este expositor não tem interações.',
  interaction: {
    editComment: 'Editar comentário',
    saveComment: 'Guardar comentário',
    checkIn: 'CheckIn',
  },
  search_placeholder: 'Introduza o que pretende procurar na lista de contactos',
};

const products: IExhibitorProducsTranslations = {
  title: 'Ofertas',
  subtitle: 'Criar, editar e apagar ofertas',
  no_products: 'Nenhum pedido de informação disponível.',
  new_product: 'Nova oferta',
  create_new_product: 'Criar oferta',
  edit_product: 'Editar oferta',
  delete_confirm: 'Tem a certeza que pretende apagar esta oferta?',
  interactions_exist_cannot_delete: 'Esta oferta já tem interações, por isso não é possível eliminar.',
  search_placeholder: 'Digite o que pretende procurar na lista de ofertas',
  you_have: 'Restam',
  products_left: 'produtos.',
  inputs: {
    name: 'Nome',
  },
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Autenticação',
  step_basicInfo: 'Informação básica',
  step_additionalInfo: 'Informação adicional',
  step_review: 'Revisão',
  step_done: 'Terminado!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Imagem de perfil',
    embedded: 'Vídeo de Youtube',
    meeting_room: 'Ativar sala de reuniões virtual',
  },
  login: {
    title: 'Registar expositor',
    subtitle: 'Faça login ou crie uma conta beamian',
    message: 'Para registar o seu expositor precisamos que inicie sessão ou se registe com uma conta Beamian.',
    exhibitor_manager_name: 'Introduza o nome do gestor de expositor',
    exhibitor_manager_email: 'Introduza o email do gestor de expositor',
  },

  basicInformation: {
    title: 'Dados do expositor',
    warning: 'A informação providenciada será usada na criação de um booklet e no perfil acessível por visitantes deste evento.',
    createExhibitor: 'Criar expositor',
    exhibitor_name: 'Nome do expositor',
    exhibitor_name_placeholder: 'Digite o nome a ser atribuído ao seu stand',
    modal: {
      title: 'Aviso',
      subtitle: 'Se quiser gerir o seu expositor terá que iniciar sessão ou criar conta.',
      skip: 'Saltar',
      createAccount: 'Criar conta',
      login: 'Iniciar sessão',
    },
  },
  managedFields: {
    title: 'Informação adicional',
    crop_image: 'Cortar imagem',
    crop_again: 'Voltar a cortar',
  },
  review: {
    title: 'Rever informação',
    name: 'Nome',
  },
  completed: {
    title: 'Expositor criado',
    subtitle: 'O seu expositor está pronto. Obrigado pelo registo!',
    manage: 'Gerir',
  },
  back: 'Voltar',
  next: 'Próxima',
  submit: 'Submeter',
};

const sessions: IExhibitorSessions = {
  title: 'Agenda',
  subtitle: 'Criar, alterar e eliminar sessões',
  start_date: 'Começa a:',
  end_date: 'Acaba a:',
  join_meeting: 'Entrar na sessão',
  meeting_url: 'URL da chamada:',
  description: 'Descrição:',
  new_session: 'Nova sessão',
  edit_session: 'Editar Sessão',
  register: 'Registar na sessão',
  invited_users: 'Convidados',
  inputs: {
    name: 'Nome',
    name_placeholder: 'Digite o nome da sessão',
    start_date: 'Data de início',
    start_date_placeholder: 'Digite a data de início da sessão',
    end_date: 'Data de fim',
    end_date_placeholder: 'Digite a data do fim da sessão',
    color: 'Cor',
    color_placeholder: 'Escolha uma cor para a sessão',
    description: 'Descrição',
    description_placeholder: 'Digite a descrição da sessão',
    entrance_capacity: 'Capacidade de entrada',
    entrance_capacity_placeholder: 'Digite a capacidade da sala',
    registration_capacity: 'Capacidade de registo',
    registration_capacity_placeholder: 'Digite o número máximo de registos',
    external_link: 'URL da chamada',
    external_link_placeholder: 'Digite o url da chamada/reunião',
    external_link_warning_message: 'Mantenha este campo vazio se pretender que o beamian crie a sala de reuniões por si.',
    order: 'Ordem',
    order_placeholder: 'Digite a ordem da sessão',
    private_session: 'Sessão privada',
    streaming_link: 'URL da stream',
    streaming_link_placeholder: 'Digite o URL da stream',
    streaming_link_warning_message: 'Esta stream estará disponivel para todos os utilizadores do evento, mesmo os não registados na sessão.',
    image: 'Imagem',
    image_placeholder: 'Carregue a imagem da sessão',
    hide_on_calendar: 'Esconder no calendário',
    use_default_language_only: 'Utilizar idioma padrão do evento',
  },
  session_label: 'Sessão',
  select_session: 'Seleccione a sessão',
  no_private_sessions: 'Não existem sessões privadas',
  private_session_warning: 'Crie uma sessão privada para convidar visitantes específicos para uma reunião. Se for uma sessão pública, qualquer visitante poderá visualizar e registar-se na sua sessão.',
  enable_chat: 'Ativar chat',
  embed_meeting: 'Sessão interna',
  embed_meeting_detail: 'Se desativar esta opção o url da sessão será aberto numa nova aba',
  you_have: 'Restam',
  sessions_left: 'sessões',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Visitantes interessados em ofertas',
  subtitle: 'Consultar a lista de visitantes interessados nas suas ofertas e agendar reuniões',
  product_name: 'Oferta',
  interaction_name: 'Nome do visitante',
  application: 'Informação do contacto',
  invite_to_session: 'Agendar uma reunião',
  send_invite: 'Enviar convite',
  user_already_registered: 'Este utilizador já está convidado para esta sessão',
  publish_warning: 'Ao publicar a oferta esta ficará visível para todos os visitantes deste evento.',
  invitation_sent: 'Convite enviado com sucesso',
  search_placeholder: 'Introduza o que pretende procurar na lista de contactos',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Informação do expositor',
  products: 'Ofertas',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, exports, visitors, profile, tickets,
};
