import axios from 'axios';
import { Resolve, IData } from '.';
import config from './config';
import { INotificationsParams, INotification } from '@/models/notifications';

const url = process.env.VUE_APP_SERVER_PATH;

export const getNotifications = (params: INotificationsParams = {}) => new Promise((resolve: Resolve<IData<INotification>>) => {
  let requestUrl = `${url}/v2/notifications?page_size=500&ordering=-id`;
  if (params.notification_type) {
    requestUrl += `&notification_type=${params.notification_type}`;
  }
  if (params.status) {
    requestUrl += `&status=${params.status}`;
  }
  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const updateNotification = (params: Partial<INotification>) => new Promise((resolve: Resolve<INotification>) => {
  const requestUrl = `${url}/v2/notifications/${params.id}`;

  axios
    .put(requestUrl, params, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const deleteNotification = (params: Partial<INotification>) => new Promise((resolve: Resolve<INotification>) => {
  const requestUrl = `${url}/v2/notifications/${params.id}`;

  axios
    .delete(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
