import {
  IExhibitorsDashboardTranslations, IExhibitorSidebar,
  IExhibitorSettingsTranslations, IExhibitorProducsTranslations, IExhibitorInteractionsTranslations, IExhibitorOnboardingTranslations, IExhibitorSessions, IExhibitorProductInteractions, IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  product_interactions_description: 'Número de aplicaciones a las ofertas de trabajo:',
  interactions: 'Contactos',
  nrOfInteractions: 'Número de interacciones:',
  seeAll: 'Ver todo',
  download: 'Descargar',
  sessions: 'Sesiones',
  upcoming_sessions: 'Siguientes sesiones',
  see_all_sessions: 'Ver todas las sesiones',
  applications: 'Aplicaciones',
  see_applications: 'Ver aplicaciones',
};

const sidebar: Partial<IExhibitorSidebar> = {
  title: 'Exhibiendo',
  home: 'Home',
  interactions: 'Visitantes',
  exhibitor_interactions: 'Aplicaciones espontáneas',
  exhibitor_sessions: 'Sesiones de empresa',
  settings: 'Formulario',
  sessions: 'Sesiones',
  products: 'Ofertas de trabajo',
  product_interactions: 'Solicitudes de empleo',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Configuración del expositor',
  basicInfo: 'Información básica',
  save: 'Guardar',
  additionalInfo: 'Información adicional',
  formError: 'Información no recuperada, formulario no disponible.',

};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Ofertas de trabajo',
  subtitle: 'Crear, editar y eliminar ofertas de trabajo',
  no_products: 'No hay productos disponibles',
  new_product: 'Nueva oferta de trabajo',
  create_new_product: 'Crear una oferta de trabajo',
  edit_product: 'Editar producto',
  delete_confirm: '¿Está seguro de que desea eliminar esta oferta de trabajo?',
  interactions_exist_cannot_delete: 'Esta oferta ya tiene interacciones, por lo que no es posible eliminar',
  inputs: { name: 'Nombre' },
  search_placeholder: 'Escriba lo que desea buscar en la lista de productos',
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Solicitudes de empleo espontáneas',
  subtitle: 'Responder a solicitudes de empleo',
  download: 'Descargar',
  noInteractions: 'Este expositor no tiene interacciones.',
  interaction: {
    editComment: 'Editar comentario',
    saveComment: 'Guardar comentario',
    checkIn: 'Check-in',
  },
  search_placeholder: 'Escriba lo que desea buscar en la lista de solicitudes de contactos',
};

const onboarding: Partial<IExhibitorOnboardingTranslations> = {
  step_authentication: 'Autenticación',
  step_basicInfo: 'Información básica',
  step_additionalInfo: 'Información adicional',
  step_review: 'Revisión',
  step_done: 'Hecho!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Imagen de perfil',
    embedded: 'Video de Youtube',
    meeting_room: 'Activar sala de reuniones virtual',
  },
  login: {
    title: 'Estás a punto de registrarte',
    subtitle: 'Primero haz login o regístrate',
    message: 'Para registrarte, necesitamos que primero hagas login o te registres.',
    exhibitor_manager_name: 'Inserta el nombre del gerente del stand',
    exhibitor_manager_email: 'Inserta el email del gerente del stand',
  },
  basicInformation: {
    title: 'Dinos más sobre tu organización',
    warning: 'La información que has insertado será utilizada para la creación del booklet del evento, así como para crear un perfil que será visto por los demás participantes.',
    createExhibitor: 'Crear',
    exhibitor_name: 'Nombre de la organización',
    exhibitor_name_placeholder: 'Insertar nombre de la organización',
    modal: {
      title: 'Aviso',
      subtitle: 'Si quieres gestionar tu expositor, por favor, haz login primero.',
      skip: 'Saltar',
      createAccount: 'Crear cuenta',
      login: 'Login',
    },
  },
  managedFields: {
    title: 'Información adicional',
    crop_image: 'Cortar imagen',
    crop_again: 'Volver a cortar',
  },
  review: {
    title: 'Revisar información',
    name: 'Nombre',
  },
  completed: {
    title: 'Perfil creado!',
    subtitle: 'Tu perfil está listo. Gracias por completar la información!',
    manage: 'Gestionar',
  },
  back: 'Atrás',
  next: 'Adelante',
  submit: 'Enviar',
};

const sessions: Partial<IExhibitorSessions> = {
  title: 'Sesiones',
  subtitle: 'Crear, editar y eliminar reuniones',
  start_date: 'Empieza a:',
  end_date: 'Termina en:',
  join_meeting: 'Unirse a la sesión',
  meeting_url: 'URL de la reunión:',
  description: 'Descripción:',
  new_session: 'Nueva sesión',
  edit_session: 'Editar Sesión',
  register: 'Registrarse en esta sesión',
  session_label: 'Sesión',
  select_session: 'Seleccione la sesión',
  no_private_sessions: 'No hay sesiones privadas',
  private_session_warning: 'Si la sesión es privada, sólo la verán los participantes invitados. Si es una sesión pública, será visible para todos los participantes.',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Aplicaciones',
  subtitle: 'Ver solicitantes de empleo, enviar invitaciones a reuniones',
  product_name: 'Oferta de trabajo',
  interaction_name: 'Nombre del candidato',
  application: 'Aplicacion de trabajo',
  invite_to_session: 'Invitar a una sesión privada',
  send_invite: 'Enviar invitación',
  user_already_registered: 'Este usuario ya está registrado en esta sesión',
  publish_warning: 'Al publicar la oferta de empleo, será visible para los visitantes',
  invitation_sent: 'Invitación enviada con éxito',
  search_placeholder: 'Escriba lo que desea buscar en la lista de solicitudes de información',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Información de la empresa',
  products: 'Ofertas de trabajo',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
