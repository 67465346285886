import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  dashboard: 'Panel de control',
  events: 'Eventos',
  devices: 'Dispositivos',
  exhibitors: 'Expositores',
  exhibitor: 'Empresa',
  logout: 'Logout',
  edit: 'Editar',
  create: 'Crear',
  delete: 'Eliminar',
  name: 'Nombre',
  name_placeholder: 'Introduzca su nombre',
  email: 'Email',
  description: 'Descripción',
  category: 'Categoría',
  model: 'Modelo',
  save: 'Guardar',
  download: 'Descargar',
  upload: 'Subir',
  change: 'Cambiar',
  apply: 'Aplicar',
  emptyFieldNotAllowed: 'Este campo no puede estar vacío',
  next: 'Siguiente',
  previous: 'Anterior',
  close: 'Cerrar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  search: 'Buscar',
  mandatory_field: 'Este campo es requerido.',
  name_in_use: 'Este nombre ya esta en uso.',
  crop: 'Cortar',
  published: 'Publicado',
  not_published: 'No publicado  ',
  all: 'Todas',
  empty_list: 'Datos no disponibles',
  user_chat_failed: 'No se pudo abrir el chat con esta usuario',
  activity_center: 'Centro de actividades',
  no_recent_activity: 'No hay actividad reciente',
  unread_messages: 'Mensajes no leídos',
  reply: 'Responder',
  session_invitations: 'Invitaciones sin respuesta',
  accept: 'Aceptar',
  refuse: 'Rechazar',
  in_progress: 'En progreso',
  recent_files: 'Archivos recientes',
  open_conversations: 'Conversaciones abiertas',
  all_fields_required: 'Todos los campos requeridos',
  something_went_wrong: 'Algo fue mal',
  already_exists: 'Ya existe',
  profile: 'Perfil',
  filter_by_status: 'Filtrar por estado',
  open_meeting: 'Entrar a la reunión',
  visitors: 'Visitante',
  saved: 'Guardado',
  back: 'Volver',
  session_full: 'La sesión está llena',
  file_not_image: 'Este archivo no es una imagen',
  new_export: 'Exportación nueva',
  state: 'Estado',
  details: 'Detalles',
  see_more: 'Ver más',
  manage: 'Manage',
  see_as_visitor: 'See as visitor',
  see_as_exhibitor: 'See as exhibitor',
  manage_visitors: 'Manage candidates',
  manage_exhibitors: 'Manage exhibitors',
  manage_event: 'Gerir evento',
  registered: 'registrado',
  refused: 'rechazado',
  pending: 'pendiente',
  accepted: 'acceptado',
};

export default common;
