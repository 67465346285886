import {
  IVisitorOnboardingTranslations,
  IVisitorExhibitorTranslations, IVisitorSettingsTranslations, IVisitorSidebar, IVisitorsDashboardTranslations, IVisitorNetworkingTranslations, IVisitorExhibitorSessions, IVisitorSessions, IVisitorProfile, IVisitorProducts,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Início',
  information: 'Perfil',
  networking: 'Networking',
  networking_description: 'Falar com outros visitantes',
  sessions: 'Agenda',
  sessions_description: 'Número de sessões na agenda do evento:',
  sessions_btn: 'Ver agenda do evento',
  name: 'Nome de visitante:',
  edit: 'Alterar',
  exhibitors: 'Marcas',
  nrOfInteractions: 'Número de contactos partilhados:',
  seeAll: 'Ver marcas',
  download: 'Descarregar',
  join_networking: 'Entrar no espaço de networking',
  event_finished: 'Este evento já terminou',
  event_not_start: 'Este evento ainda não começou',
  featured_products: 'Produtos em destaque',
  featured_sessions: 'Sessões em destaque',
  featured_exhibitors: 'Marcas em destaque',
  upcoming_exhibitors: 'Próximas sessões',
};

const sidebar: IVisitorSidebar = {
  title: 'Visitante',
  home: 'Início',
  exhibitors: 'Marcas',
  settings: 'Perfil',
  sessions: 'Agenda',
  exhibitor_sessions: 'Agenda pessoal',
  event_sessions: 'Agenda do evento',
  myCalendar: 'Agenda pessoal',
  eventCalendar: 'Agenda do evento',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Definições de visitante',
  basicInfo: 'Informação básica',
  save: 'Guardar',
  additionalInfo: 'Informação adicional',
  formError: 'Sem informação para mostrar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Marcas',
  subtitle: 'Ver marcas e consultar produtos',
  download: 'Descarregar',
  noExhibitors: 'Sem marcas para mostrar.',
  exhibitor_information: 'Informação de marca',
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  all_exhibitors: 'Todas as marcas',
  interacted_exhibitors: 'Marcas com que interagi',
  not_interacted_exhibitors: 'Marcas com que não interagi',
  filter_exhibitors: 'Filtrar marcas',
  contact_request: 'Partilhar contactos',
  company_info: 'Informação da marca',
  job_offers: 'Produtos',
  apply_to_job_offer: 'Registar interesse',
  job_offer_info: 'Informação do produto',
  cover_letter: 'Pedido de informação',
  cover_letter_text: 'Deixe uma mensagem à marca',
  your_info_will_be_shared: 'A sua informação de registo será partilhada com a marca',
  submit_my_application: 'Submeter',
  successfully_submited: 'Efetuado com sucesso',
  already_applied: 'Já efetuou este registo de interesse',
  schedule_interview: 'Reserve o seu lugar',
  select_time_slot: 'Selecione um horário para participar',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Dados de visitante',
    createVisitor: 'Registar como visitante',
    modal: {
      title: 'Aviso',
      subtitle: 'Se quiser aceder ao evento após o registo terá que iniciar sessão primeiro.',
      skip: 'Saltar',
      createAccount: 'Criar conta',
      login: 'Iniciar sessão',
    },
  },
  managedFields: {
    title: 'Informação adicional',
  },
  review: {
    title: 'Rever informação',
    name: 'Nome',
  },
  completed: {
    title: 'Visitante registado',
    subtitle: 'Utilizador registado como visitante. Obrigado!',
    manage: 'Gerir',
  },
  back: 'Voltar',
  next: 'Próximo',
  submit: 'Submeter',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Fale com outros visitantes',
  chat: 'Chat',
  profile: 'Perfil',
  signup_title: 'Pretende falar com outros visitantes?',
  signup_message: 'Está prestes a entrar na área de networking. A sua informação ficará disponível para outros visitantes.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Registe-se para participar nas sessões do evento',
  event_sessions: 'Sessões do evento',
  exhibitor_sessions: 'Sessões de marcas',
  all_sessions: 'Todas as sessões',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessões em que está inscrito/a',
};

const profile: IVisitorProfile = {
  visitor_information: 'Informação do visitante',
};

const products: IVisitorProducts = {
  title: 'Produtos',
  subtitle: 'Procurar e interagir com produtos da marca',
  products_same_brand: 'Produtos da mesma marca',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile, products,
};
