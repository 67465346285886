import auth from './auth/de';
import common from './common/de';
import dashboard from './dashboard/de';
import table from './table/de';
import account from './account/de';
import events from './events/de';
import exhibitors from './exhibitors/de';
import visitors from './visitors/de';
import root from './root/de';

export default {
  auth, common, dashboard, table, account, events, exhibitors, visitors, root,
};
