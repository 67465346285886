import {
  IVisitorOnboardingTranslations,
  IVisitorSessions,
  IVisitorExhibitorTranslations,
  IVisitorSettingsTranslations,
  IVisitorSidebar,
  IVisitorsDashboardTranslations,
  IVisitorNetworkingTranslations,
  IVisitorExhibitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from './index';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Profile',
  networking: 'Networking',
  networking_description: 'Meet fellow visitors',
  sessions: 'Calendar',
  sessions_description: 'See all sessions',
  sessions_btn: 'Event calendar',
  name: 'Name of the visitor:',
  edit: 'Edit',
  exhibitors: 'Exhibitors',
  nrOfInteractions: 'See all exhibitors',
  seeAll: 'Exhibitors',
  download: 'Download',
  join_networking: 'Networking',
  event_finished: 'Event is finished',
  event_not_start: 'Event did not start yet',
  featured_products: 'Featured products',
  featured_sessions: 'Featured sessions',
  featured_exhibitors: 'Featured exhibitors',
  upcoming_exhibitors: 'Next sessions',
};

const sidebar: IVisitorSidebar = {
  title: 'Visiting',
  home: 'Home',
  exhibitors: 'Exhibitors',
  settings: 'Profile',
  sessions: 'Calendar',
  exhibitor_sessions: 'My calendar',
  event_sessions: 'Event calendar',
  myCalendar: 'My calendar',
  eventCalendar: 'Event calendar',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Visitor form',
  basicInfo: 'Basic information',
  save: 'Save',
  additionalInfo: 'Additional information',
  formError: 'No information retrieved, form not available.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Exhibitors',
  subtitle: 'See exhibitors and check their offers',
  download: 'Download',
  noExhibitors: 'No exhibitors to show.',
  exhibitor_information: 'Exhibitor information',
  contact_success: 'Your information was shared succesfuly!',
  all_exhibitors: 'All exhibitors',
  interacted_exhibitors: 'Exhibitors I interacted with',
  not_interacted_exhibitors: 'Exhibitors I didn’t interact with',
  filter_exhibitors: 'Filter exhibitors',
  contact_request: 'Leave my contact',
  company_info: 'Exhibitor information',
  job_offers: 'Offers',
  apply_to_job_offer: "I'm interested",
  job_offer_info: 'Information about the offer',
  cover_letter: 'Why are you interested?',
  cover_letter_text: 'Let the exhibitor know why you’re interested and/or ask him a question about the offer',
  your_info_will_be_shared: 'Your information will be shared with the exhibitor',
  submit_my_application: 'Submit',
  successfully_submited: 'Successfully submitted ',
  already_applied: 'You have already shared your interest in this offer.',
  schedule_interview: 'Reserve your spot',
  select_time_slot: 'Choose a time slot to join',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Visitor details',
    createVisitor: 'Register as a visitor',
    modal: {
      title: 'Warning',
      subtitle: 'If you wish to manage your visitor, please login first.',
      skip: 'Skip',
      createAccount: 'Create account',
      login: 'Login',
    },
    already_registered: 'You are already registered in this event!',
    send_login_link: 'Click here to receive an email with the login link.',
  },
  tickets: {
    title: 'Redeem ticket code',
    subtitle: 'Registration requires a valid ticket',
    message_head: "Don't have a ticket yet?",
    to_buy_tickets: 'to buy tickets;',
    copy_ticket_code: "Copy the ticket code we'll send you to your email and register with it.",
    ticket_code_invalid: 'Ticket code is invalid or has already been used',
    ticket_code_label: 'Ticket code',
    ticket_code_placeholder: 'Insert your ticket code',
  },
  managedFields: {
    title: 'Additional information',
  },
  review: {
    title: 'Review information',
    name: 'Name',
  },
  completed: {
    title: 'Visitor created!',
    subtitle: 'Your visitor is ready. Thank you for your submission!',
    manage: 'Manage',
  },
  back: 'Back',
  next: 'Next',
  submit: 'Submit',
  not_allowed_title: 'Your account is pending aproval',
  not_allowed_subtitle: "Please wait for the event manager's approval",
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Talk to fellow visitors',
  chat: 'Chat',
  profile: 'Profile',
  signup_title: 'Do you wish to meet other visitors?',
  signup_message: 'You’re about to join the networking area. Your information will be available to other visitors.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Register for a session to join',
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
  all_sessions: 'All sessions',
  join_in_15: "This session is not currently ongoing",
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessions in which you are registered',
};

const profile: IVisitorProfile = {
  visitor_information: 'Visitor information',
};

const products: IVisitorProducts = {
  title: 'Offers',
  subtitle: 'Browse and interact with exhibitor offers',
  products_same_brand: 'Offers from the same brand',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile, products,
};
