import {
  IExhibitorsDashboardTranslations, IExhibitorSidebar,
  IExhibitorSettingsTranslations, IExhibitorProducsTranslations, IExhibitorInteractionsTranslations, IExhibitorOnboardingTranslations, IExhibitorSessions, IExhibitorProductInteractions, IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  product_interactions_description: 'Number of applications to job offers received:',
  name: 'Name of the exhibitor:',
  interactions: 'Spontaneous applications',
  nrOfInteractions: 'Number of spontaneous applications received:',
  seeAll: 'See applications',
  applications: 'Applications to job offers',
  see_applications: 'See applications',
  check_scheduled_meetings: 'Check the meetings candidates already scheduled',
};

const sidebar: Partial<IExhibitorSidebar> = {
  title: 'Exhibiting',
  exhibitor_interactions: 'Spontaneous applications',
  exhibitor_sessions: 'Calendar',
  product_interactions: 'Applications for job offers',
  interactions: 'Candidates',
  settings: 'Profile',
  products: 'Job offers',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  title: 'Exhibitor settings',
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Job offers',
  subtitle: 'Create, edit and delete job offers',
  no_products: 'No job offers available',
  new_product: 'New job offer',
  create_new_product: 'Create a new job offer',
  edit_product: 'Edit job offer',
  delete_confirm: 'Are you sure you want to delete this job offer?',
  interactions_exist_cannot_delete: "This job offer already has applicants, therefore it can't be deleted",
  search_placeholder: 'Type what you wish to search in the list of job offers',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Spontaneous applications',
  subtitle: 'Check applications and schedule meetings',
  noInteractions: 'This exhibitor has no interactions.',
  interaction: {
    editComment: 'Edit comment',
    saveComment: 'Save comment',
    checkIn: 'CheckIn',
  },
  search_placeholder: 'Type what you wish to search for in the list of spontaneous applications',
};

const onboarding: DeepPartial<IExhibitorOnboardingTranslations> = {
  login: {
    title: "You're about to register your exhibitor",
    message: 'To register your exhibitor we need you to login or register a new beamian account.',
    exhibitor_manager_name: 'Insert the exhibitor manager name',
    exhibitor_manager_email: "Insert the exhibitor manager's email",
  },
  basicInformation: {
    title: 'Tell us about your exhibitor',
    createExhibitor: 'Create exhibitor',
    exhibitor_name: 'Exhibitor name',
    exhibitor_name_placeholder: 'Insert the name of your exhibitor',
  },
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning: "Create a private session to invite specific candidates to a meeting. If it's a public session, any candidate will be able to see and register for that session.",
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Applications for job offers',
  subtitle: 'Check job applications and schedule meetings',
  product_name: 'Job offer',
  interaction_name: 'Candidate name',
  application: 'Apply',
  invite_to_session: 'Schedule meeting',
  send_invite: 'Send invite',
  user_already_registered: 'This user is already registered on this meeting',
  publish_warning: 'By publishing this form the information will be visible to candidates',
  invitation_sent: 'Invitation sent successfully',
  search_placeholder: 'Type what you want to search for in the list of job offers’ applications',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Exhibitor information',
  products: 'Job offers',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
