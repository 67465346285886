import { IEventsTranslations } from '@/translations/default/events';

const events: Partial<IEventsTranslations> = {
  createExhibitor: 'Register exhibitor',
  createExhibitorDescription: 'Register your exhibitor on this event.',
  manageExhibitor: 'Manage exhibitor',
  manageExhibitorDescription: 'Edit exhibitor information, see interactions.',
};

export default events;
