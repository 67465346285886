import { ActionContext } from 'vuex';
import { deleteNotification, getNotifications, updateNotification } from '@/api/notifications';
import { IRocketRoom } from '@/models/chat';
import { IInteractionsExport } from '@/models/interaction';
import { INotification } from '@/models/notifications';
import { IProductInteractionsExport } from '@/models/product';
import { ISession } from '@/models/session';
import { IState } from '..';

export interface INotificationsState {
  interactionsExports: IInteractionsExport[];
  productInteractionsExports: IProductInteractionsExport[];
  messages: IRocketRoom[];
  sessions: ISession[];
  notifications: INotification[];
}

const state: INotificationsState = {
  interactionsExports: [],
  productInteractionsExports: [],
  messages: [],
  sessions: [],
  notifications: [],
};

const getters = {
  getNotificationsNr: (state: INotificationsState) => state.interactionsExports.length + state.notifications.filter((notification) => notification.status === 'sent').length,
  getNotifications: (state: INotificationsState) => state.notifications.filter((notification) => notification.status === 'sent'),
  getMessages: (state: INotificationsState) => state.messages,
  getMessagesNotifications: (state: INotificationsState) => state.messages,
  getSessionsNotifications: (state: INotificationsState) => state.sessions,
  getInteractionsExportsNotifications: (state: INotificationsState) => state.interactionsExports,
  getProductInteractionsExportsNotifications: (state: INotificationsState) => state.productInteractionsExports,
};

const mutations = {
  setMessagesNotifications: (state: INotificationsState, messages: IRocketRoom[]) => {
    state.messages = messages;
  },
  setSessionsNotifications: (state: INotificationsState, sessions: ISession[]) => {
    state.sessions = sessions;
  },
  setInteractionsExportsNotifications: (state: INotificationsState, interactionExports: IInteractionsExport[]) => {
    state.interactionsExports = interactionExports;
  },
  setProductInteractionsExportsNotifications: (state: INotificationsState, productInteractionsExports: IProductInteractionsExport[]) => {
    state.productInteractionsExports = productInteractionsExports;
  },
  setNotifications: (state: INotificationsState, notifications: INotification[]) => {
    state.notifications = notifications;
  },

  updateNotification: (state: INotificationsState, editedNotification: INotification) => {
    state.notifications = state.notifications.map((notification) => (editedNotification.id === notification.id
      ? {
        ...editedNotification,
        loading: false,
        error: false,
      }
      : notification));
  },

  deleteNotification: (state: INotificationsState, deletedNotification: INotification) => {
    state.notifications = state.notifications.filter((notification) => deletedNotification.id !== notification.id);
  },
};

const actions = {
  fetchNotifications(context: ActionContext<INotificationsState, IState>) {
    // context.commit('setNnotificationsLoading');
    getNotifications()
      .then((response) => {
        context.commit('setNotifications', response.data.results);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
  updateNotification(context: ActionContext<INotificationsState, IState>, notification: INotification) {
    // context.commit('setNnotificationsLoading');
    updateNotification(notification)
      .then((response) => {
        context.commit('updateNotification', response.data);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
  deleteNotification(context: ActionContext<INotificationsState, IState>, notification: INotification) {
    // context.commit('setNnotificationsLoading');
    deleteNotification(notification)
      .then(() => {
        context.commit('deleteNotification', notification);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
