const activityCenter = {
  title: 'Activity center',
  noRecentActivity: 'No recent activity',
  inProgress: 'In progress',
  recentFiles: 'Recent files',
};

const messages = {
  title: 'Messages',
};

const scanner = {
  title: 'QR code scanner',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'Login required',
  text: 'Please log in as a visitor of this event to access this feature',
};

const sessionEnded = {
  title: "You've left the session!",
  subtitle: "Keep browsing?",
  backToCalendar: 'Back to calendar',
  backToEvent: 'Back home',
}

export default {
  activityCenter, messages, scanner, helpdesk, loginPrompt, sessionEnded,
};
