import axios from 'axios';

import { Resolve, IData, IParams } from '.';
import config from './config';
import { IExhibitor, IExhibitorComment, IExhibitorInvitation, IExhibitorPackageAllowance, IExhibitorPackagesParams } from '@/models/exhibitor';
import { IExhibitorsParams } from '../store/modules/exhibitors';

const url = process.env.VUE_APP_SERVER_PATH;

export const getExhibitorById = (exhibitorId: number) => new Promise((resolve: Resolve<IExhibitor>, reject) => {
  axios
    .get(`${url}/v2/exhibitors/${exhibitorId}?page_size=1000`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const createExhibitorComment = (comment: IExhibitorComment) => new Promise((resolve: Resolve<IData<IExhibitorComment>>) => {

  const requestUrl = `${url}/v2/exhibitor-comments`;

  axios
    .post(requestUrl, comment, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const updateExhibitorComment = (comment: IExhibitorComment) => new Promise((resolve: Resolve<IData<IExhibitorComment>>) => {

  const requestUrl = `${url}/v2/exhibitor-comments/${comment.id}`;

  axios
    .patch(requestUrl, comment, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getExhibitorsById = (exhibitorsId: number[]) => new Promise((resolve: Resolve<IData<IExhibitor>>, reject) => {
  axios
    .get(`${url}/v2/exhibitors?id=${exhibitorsId.join(',')}&ordering=priority`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getExhibitorPackageAllowances = (params: IExhibitorPackagesParams) => new Promise((resolve: Resolve<IData<IExhibitorPackageAllowance>>, reject) => {
  let requestUrl = `${url}/v2/exhibitor-package-allowances?page_size=100&page=1`;
  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }
  if (params.exhibitor) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }
  if (params.package) {
    requestUrl += `&exhibitor=${params.package}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getExhibitorSessionLimits = (params: IParams & { exhibitor: number }) => new Promise((resolve: Resolve<IData<{
  exhibitor: number,
  limit: number,
}>>, reject) => {
  let requestUrl = `${url}/v2/exhibitor-session-limits&page_size=100`;
  if (params.exhibitor) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getExhibitors = (params: IExhibitorsParams) => new Promise((resolve: Resolve<IData<IExhibitor>>, reject) => {
  let requestUrl = `${url}/v2/exhibitors?page_size=${params.page_size}&page=${params.page}`;
  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }
  if (params.favorite !== undefined) {
    requestUrl += `&favourite=${params.favorite}`;
  }
  if (params.name) {
    requestUrl += `&name=${params.name}`;
  }
  if (params.category) {
    requestUrl += `&category=${params.category}`;
  }
  if (params.name) {
    requestUrl += `&name=${params.name}`;
  }
  if (params.search) {
    requestUrl += `&search=${params.search}`;
  }
  if (params.visitor) {
    requestUrl += `&visitor=${params.visitor}`;
  }
  if (params.notvisitor) {
    requestUrl += `&notvisitor=${params.notvisitor}`;
  }
  if (params.sponsor_level) {
    requestUrl += `&sponsor_level=${params.sponsor_level}`;
  }
  if (params.hidden !== undefined) {
    requestUrl += `&hidden=${params.hidden}`;
  }
  if (params.status) {
    requestUrl += `&status=${params.status}`;
  }

  if (params.home_page !== undefined) {
    requestUrl += `&home_page=${params.home_page}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getExhibitorsByVisitorId = (visitor: number) => new Promise((resolve: Resolve<IData<IExhibitor>>, reject) => {
  axios
    .get(`${url}/v2/exhibitors?page_size=100&visitor=${visitor}&ordering=priority`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getExhibitorsByName = (name: string, event: number) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .get(`${url}/v2/exhibitors?name=${encodeURI(name)}&event=${event}&page_size=200&ordering=priority`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getExhibitorsByEvent = (event: number) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .get(`${url}/v2/exhibitors?event=${event}&page_size=1000&ordering=priority`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const editExhibitor = (exhibitor: Partial<IExhibitor>) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .patch(`${url}/v2/exhibitors/${exhibitor.id}`, exhibitor, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addExhibitor = (exhibitor: IExhibitor) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .post(`${url}/v2/exhibitors`, exhibitor, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
export const createExhibitorInvitation = (invitation: IExhibitorInvitation) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .post(`${url}/v2/exhibitor-invitations`, invitation, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addExhibitorManager = (exhibitor: number, manager: number) => new Promise((resolve: Resolve<any>, reject) => {
  axios
    .post(`${url}/v2/exhibitors-managers`, { user: manager, exhibitor }, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addExhibitorContactRequest = (exhibitor: number, event: number, visitor: number) => new Promise((resolve: Resolve<any>, reject) => {
  const formData = new FormData();

  formData.append('event', event.toString());
  formData.append('exhibitor', exhibitor.toString()); // number 123456 is immediately converted to a string "123456"
  formData.append('visitor', visitor.toString()); // number 123456 is immediately converted to a string "123456"

  axios
    .post(`${url}/v2/contact-requests`, formData, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
