import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions, IExhibitorInteractionsTranslations, IExhibitorSettingsTranslations, IExhibitorSidebar, IExhibitorsDashboardTranslations, IExhibitorProducsTranslations, IExhibitorProductInteractions, IExhibitorProfile,
} from '.';

const dashboard: IExhibitorsDashboardTranslations = {
  home: 'Schalttafel',
  product_interactions_description: 'Anzahl der Besucher, die an Angeboten interessiert sind:',
  information: 'Profil',
  name: 'Ausstellername:',
  edit: 'Bearbeiten',
  interactions: 'Besucher, die sich für den Aussteller interessieren',
  nrOfInteractions: 'Anzahl der Besucher, die sich für den Aussteller interessieren:',
  seeAll: 'Kontakte ansehen',
  download: 'Herunterladen',
  sessions: 'Sitzungen',
  upcoming_sessions: 'Nächste Sitzungen',
  see_all_sessions: 'Alle Sitzungen ansehen',
  applications: 'Besucher, die an Angeboten interessiert sind',
  see_applications: 'Kontakte ansehen',
  choose_exhibitor: 'Wählen Sie den Aussteller, den Sie verwalten möchten',
  schedule_meetings: 'Geplante Meetings',
  check_scheduled_meetings: 'Überprüfen Sie die Meetings, die Besucher bereits geplant haben',
  exhibitor_pending_title: 'Aussteller muss noch genehmigt werden',
  exhibitor_pending_text: 'Ihr Aussteller wurde nicht zum Betreten der Plattform zugelassen. Warten Sie, bis der Eventmanager Ihren Aussteller genehmigt hat.',
};

const sidebar: IExhibitorSidebar = {
  title: 'Ausstellen',
  home: 'Home',
  exhibitor_interactions: 'An dem Aussteller interessiert',
  exhibitor_sessions: 'Mein Kalender',
  interactions: 'Besucher',
  sessions: 'Kalender',
  settings: 'Profil',
  products: 'Angebote',
  product_interactions: 'An Angeboten interessiert',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Ausstellereinstellungen',
  basicInfo: 'Basisinformation',
  save: 'Speichern',
  additionalInfo: 'Zusätzliche Information',
  formError: 'Keine Informationen abgerufen, Formular nicht verfügbar.',
};

const products: IExhibitorProducsTranslations = {
  title: 'Angebote',
  subtitle: 'Angebote erstellen, bearbeiten und löschen',
  no_products: 'Keine Angebote verfügbar',
  new_product: 'Neues Angebot',
  create_new_product: 'Erstellen',
  edit_product: 'Angebot bearbeiten',
  delete_confirm: 'Sind Sie sicher, dass Sie dieses Angebot löschen möchten?',
  interactions_exist_cannot_delete: 'Es gibt bereits Interaktionen. Kann nicht gelöscht werden.',
  search_placeholder: ' Geben Sie ein, wonach Sie in der Angebotsliste suchen möchten',
  inputs: {
    name: 'Name',
  },
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Besucher, die sich für den Aussteller interessieren',
  subtitle: 'Liste der Besucher, die sich für den Aussteller interessieren, überprüfen',
  download: 'Herunterladen',
  noInteractions: 'Dieser Aussteller hat keine Interaktionen.',
  interaction: {
    editComment: 'Kommentar bearbeiten',
    saveComment: 'Kommentar speichern',
    checkIn: 'Check-In',
  },
  search_placeholder: 'Geben Sie ein, wonach Sie in der Kontaktliste suchen möchten',
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Authentifizierung',
  step_basicInfo: 'Basisinformation',
  step_additionalInfo: 'Zusätzliche Information',
  step_review: 'Überprüfen',
  step_done: 'Fertig!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Profilbild',
    embedded: 'Youtube-Video',
    meeting_room: 'Virtuellen Meeting-Room freigeben',
  },
  login: {
    title: 'Sie sind dabei, Ihren Aussteller anzumelden',
    subtitle: 'Erstlogin oder Anmeldung',
    message: 'Um Ihren Aussteller anzumelden, müssen Sie sich einloggen oder ein neues beamian-Konto anlegen.',
    exhibitor_manager_name: 'Geben Sie den Namen des Verantwortlichen des Ausstellers ein',
    exhibitor_manager_email: 'Geben Sie die E-Mail des Verantwortlichen des Ausstellers ein',
  },
  basicInformation: {
    title: 'Erzählen Sie uns von Ihrem Aussteller.',
    warning: 'Die von Ihnen angegebenen Informationen werden zur Erstellung der Broschüre sowie zur Erstellung eines Profils verwendet, das die Besucher sehen können.',
    createExhibitor: 'Aussteller erstellen',
    exhibitor_name: 'Ausstellername',
    exhibitor_name_placeholder: 'Geben Sie den Namen des Ausstellers ein (Firma)',
    modal: {
      title: 'Warnung',
      subtitle: 'Wenn Sie Ihren Aussteller verwalten möchten, melden Sie sich bitte zuerst an.',
      skip: 'Überspringen',
      createAccount: 'Benutzerkonto anlegen',
      login: 'Einloggen',
    },
  },
  managedFields: {
    title: 'Zusätzliche Information',
    crop_image: 'Bild zuschneiden',
    crop_again: 'Zurück zum Zuschneiden',
  },
  review: {
    title: 'Überprüfen Sie die Informationen',
    name: 'Name',
  },
  completed: {
    title: 'Aussteller erstellt!',
    subtitle: 'Ihr Aussteller ist bereit. Vielen Dank für Ihre Einreichung!',
    manage: 'Verwalten',
  },
  back: 'Zurück',
  next: 'Nächste',
  submit: 'Einreichen',
};

const sessions: IExhibitorSessions = {
  title: 'Sitzungen',
  subtitle: 'Sitzungen erstellen, bearbeiten und löschen',
  start_date: 'Beginnt um:',
  end_date: 'Endet um:',
  join_meeting: 'Sitzung beitreten',
  meeting_url: 'Meeting-URL:',
  description: 'Beschreibung:',
  new_session: 'Neue Sitzung',
  edit_session: 'Sitzung bearbeiten',
  register: 'Registrieren',
  invited_users: 'Eingeladen',
  inputs: {
    name: 'Name',
    name_placeholder: 'Sitzungsnamen eingeben',
    start_date: 'Startdatum',
    start_date_placeholder: 'Startdatum für Sitzung eingeben',
    end_date: 'Enddatum',
    end_date_placeholder: 'Enddatum der Sitzung eingeben',
    color: 'Farbe Datum',
    color_placeholder: 'Farbe für Sitzung eingeben',
    description: 'Beschreibung',
    description_placeholder: 'Beschreibung der Sitzung eingeben',
    entrance_capacity: 'Eintrittskapazität',
    entrance_capacity_placeholder: 'Eintrittskapazität eingeben',
    registration_capacity: 'Anmeldekapazität',
    registration_capacity_placeholder: 'Anmeldekapazität eingeben',
    external_link: 'Meeting-URL:',
    external_link_placeholder: 'Meeting-URL eingeben',
    external_link_warning_message: 'Lassen Sie dieses Feld leer, wenn Sie möchten, dass beamian für Sie einen Meeting-Room erstellt.',
    order: 'Reihenfolge',
    order_placeholder: 'Reihenfolge für Sitzung eingeben',
    private_session: 'Private Sitzung',
  },
  session_label: 'Sitzung',
  select_session: 'Sitzung auswählen',
  no_private_sessions: 'Es gibt keine Aussteller-Sitzungen',
  private_session_warning: 'Erstellen Sie eine private Sitzung um bestimmte Besucher zu einem Meeting einzuladen. Wenn es eine öffentliche Sitzung ist, können alle Besucher die Sitzung einsehen und sich für sie anmelden.',
  enable_chat: 'Chat aktivieren',
  embed_meeting: 'Besprechungsvideo mit einbeziehen',
  embed_meeting_detail: 'Wenn Sie diese Option deaktivieren, wird der Meeting-Link in einem neuen Fenster geöffnet',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Besucher, die an Angeboten interessiert sind',
  subtitle: 'Die Liste der interessierten Besucher überprüfen und Meetings planen',
  product_name: 'Angebot',
  interaction_name: 'Name des Besuchers',
  application: 'Kontaktinformation',
  invite_to_session: 'Meeting planen',
  send_invite: 'Einladung schicken',
  user_already_registered: 'Dieser Nutzer ist bereits für diese Sitzung angemeldet',
  publish_warning: 'Indem Sie dieses Formular veröffentlichen, wird die Information für Besucher ersichtlich sein',
  invitation_sent: 'Einladung erfolgreich verschickt',
  search_placeholder: 'Geben Sie ein, wonach Sie in der Kontaktliste suchen möchten',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Ausstellerinformation',
  products: 'Angebote',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
