import {
  IVisitorsDashboardTranslations, IVisitorSidebar, IVisitorExhibitorTranslations, IVisitorSessions, IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  networking_description: 'Falar com outros candidatos',
  nrOfInteractions: 'Número de candidaturas espontâneas efetuadas:',
  name: 'Nome de candidato:',
  featured_exhibitors: 'Professores',
  featured_products: 'Cursos',
};

const sidebar: Partial<IVisitorSidebar> = {
  // exhibitors: 'Empresas',
  title: 'Candidatos',
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  // title: 'Empresas',
  subtitle: 'Ver expositores e consultar ofertas de emprego',
  noExhibitors: 'Sem expositores para mostrar.',
  exhibitor_information: 'Informação do expositor',
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  all_exhibitors: 'Todas os expositores',
  interacted_exhibitors: 'Expositores com que interagi',
  not_interacted_exhibitors: 'Expositores com que não interagi',
  filter_exhibitors: 'Filtrar expositores',
  contact_request: 'Candidatura espontânea',
  company_info: 'Informação do expositor',
  job_offers: 'Ofertas de emprego',
  apply_to_job_offer: 'Candidatura',
  job_offer_info: 'Informação sobre a oferta de emprego',
  cover_letter: 'Carta de apresentação',
  cover_letter_text: 'Diga-nos porque é que é a pessoa certa para este lugar',
  your_info_will_be_shared: 'O seu perfil será partilhado com o expositor',
  submit_my_application: 'Submeter',
  successfully_submited: 'Candidatura efetuada com sucesso',
  already_applied: 'Já se candidatou a esta oferta',
};

const sessions: Partial<IVisitorSessions> = {
  exhibitor_sessions: 'Sessões de empresas',
};

const products: IVisitorProducts = {
  title: 'Ofertas de emprego',
  subtitle: 'Procurar e responder a ofertas de emprego',
  products_same_brand: 'Ofertas do mesmo professor',
};

export default {
  dashboard, exhibitors, sidebar, sessions, products,
};
