



































































































































































































































































































































































































import { MutationPayload } from 'vuex';
import Dropdown from '@/components/dropdown.vue';
import { AvailableLanguages } from '@/translations';
import { IExhibitor } from '@/models/exhibitor';
import store from '@/store';
import { IVisitor } from '@/models/visitor';
import { IDropdown } from '.';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { Dropdown },
  data() {
    return {
      dropdown: {} as IDropdown,
      navbarActive: false,
      exhibitor: 0,
      event: 0,
      fixNavTimeout: 0,
    };
  },
  setup() {
    const translations = computed(() => store.getters.getTranslations);
    const language = computed(() => store.getters.getLanguage);
    const eventLanguages = computed(() => store.getters.getEventLanguages);
    const currentUser = computed(() => store.getters.currentUser);
    const contextEvent = computed(() => store.getters.getContextEvent);
    const userLoggedIn = computed(() => store.getters.userLoggedIn);
    const userAdmin = computed(() => store.getters.userAdmin);
    const user = computed(() => store.getters.currentUser);
    const userManages = computed(() => store.getters.userManages as number[]);
    const relatedVisitors = computed(() => store.getters.getRelatedVisitors as IVisitor[]);
    const userExhibits = computed(() => store.getters.userExhibits);
    const relatedExhibitors = computed(() => store.getters.getRelatedExhibitors as IExhibitor[]);
    const userRolesSet = computed(() => store.getters.getUserRolesSet);
    const contextVisitor = computed(() => store.getters.getContextVisitor);
    const relatedEvents = computed(() => store.getters.getRelatedEvents);
    const notifications = computed(() => store.getters.getNotificationsNr);
    const messages = computed(() => store.getters.getMessages);

    const userIsExhibitor = computed(
      () =>
        relatedExhibitors.value &&
        relatedExhibitors.value.some((exhibitor) => exhibitor.event === contextEvent.value.id),
    );
    const userIsVisitor = computed(
      () =>
        relatedVisitors.value &&
        relatedVisitors.value.some((visitor) => visitor.event === contextEvent.value.id),
    );
    const userExhibitors = computed(
      () =>
        relatedExhibitors.value &&
        relatedExhibitors.value.filter((visitor) => visitor.event === contextEvent.value.id),
    );
    const userExhibitor = computed(
      () =>
        relatedExhibitors.value &&
        relatedExhibitors.value.find((exhibitor) => exhibitor.event === contextEvent.value.id),
    );
    const userVisitor = computed(
      () =>
        relatedVisitors.value &&
        relatedVisitors.value.find((visitor) => visitor.event === contextEvent.value.id),
    );
    const userIsManager = computed(
      () => userManages.value && userManages.value.some((event) => event === contextEvent.value.id),
    );
    const visitorInitial = computed(() =>
      userAdmin.value && contextVisitor.value.id === 0
        ? 'A'
        : (contextVisitor.value.name && contextVisitor.value.name.charAt(0)) ||
          (currentUser.value.name && currentUser.value.name.charAt(0)),
    );

    return {
      userExhibitors,
      visitorInitial,
      user,
      messages,
      userIsExhibitor,
      userIsVisitor,
      userExhibitor,
      userVisitor,
      userIsManager,
      currentUser,
      notifications,
      translations,
      language,
      eventLanguages,
      contextEvent,
      userAdmin,
      userManages,
      relatedExhibitors,
      relatedVisitors,
      userExhibits,
      userRolesSet,
      relatedEvents,
      contextVisitor,
      userLoggedIn,
    };
  },

  created() {
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.event = parseInt(this.$route.params.event, 10);

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setContextExhibitor' && mutation.payload.id) {
        this.event = parseInt(this.$route.params.event, 10);
        this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
      }
      if (mutation.type === 'setContextVisitor' && mutation.payload.id) {
        this.event = parseInt(this.$route.params.event, 10);
      }
    });

    this.$router.afterEach(() => {
      this.navbarActive = false;
    });
  },
  methods: {
    openNotifications() {
      store.commit('openNotifications');
    },

    openScanner() {
      store.commit('openScanner');
    },

    openMessages() {
      store.commit('setChatOpen', true);
    },

    openHelpCenter() {
      store.commit('openHelpCenter');
    },

    changeLanguage(lang: AvailableLanguages) {
      const localStorageLangProp = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
      localStorage.setItem(localStorageLangProp, lang);
      store.commit('setLanguage', lang);
    },
  },
});
