const activityCenter = {
  title: 'Centro de atividade',
  noRecentActivity: 'Sem atividade recente',
  inProgress: 'A gerar ficheiro',
};

const messages = {
  title: 'Mensagens',
};

const scanner = {
  title: 'Leitor de código QR',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'É necessário iniciar sessão',
  text: 'Por favor inicie sessão como visitante deste evento para aceder a esta funcionalidade',
};

const sessionEnded = {
  title: "Sais-te da sessão!",
  subtitle: "Continuar navegando?",
  backToCalendar: 'Voltar ao calendário',
  backToEvent: 'Voltar para a home',
}

export default {
  activityCenter, messages, scanner, helpdesk, loginPrompt, sessionEnded,
};
