import { IAccountSettingsTranslations } from './index';

const settings: IAccountSettingsTranslations = {
  accountSettings: 'Account settings',
  changeAccountInfo: 'Change your account information',
  save: 'Save',
  resetPassword: 'Reset password',
  password_confirm: 'Confirm password',
  password_confirm_placeholder: 'Insert your password again',
  no_profile_pic: 'You have no profile picture. Please upload it',
  here: 'here',
};

export default { settings };
