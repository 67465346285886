const activityCenter = {
  title: "Centre d'activités",
  noRecentActivity: 'Aucune activité récente',
  inProgress: 'En cours',
  recentFiles: 'Fichiers récents',
};
const messages = {
  title: 'Messages',
};

const scanner = {
  title: 'QR code scanner',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'Connexion requise',
  text: "Veuillez vous connecter en tant qu'invité de cet événement pour accéder à cette fonctionnalité",
};
const sessionEnded = {
  title: "You've left the session!",
  subtitle: "Keep browsing?",
  backToCalendar: 'Back to calendar',
  backToEvent: 'Back to event',
}

export default {
  activityCenter, messages, scanner, helpdesk, loginPrompt, sessionEnded,
};
