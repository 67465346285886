import { IEventsTranslations } from '@/translations/default/events';

const events: Partial<IEventsTranslations> = {
  createExhibitor: 'Aussteller registrieren',
  createExhibitorDescription: 'Registrieren Sie Ihren Aussteller für diese Veranstaltung.',
  manageExhibitor: 'Aussteller verwalten',
  manageExhibitorDescription: 'Ausstellerinformationen bearbeiten, siehe Interaktionen.',
};

export default events;
