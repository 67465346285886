<template>
  <div class="scanner">
    <Modal :config="modal">
      <template v-slot:header>
        <h3 class="title is-3">{{ translations.root.scanner.title }}</h3>
      </template>
      <qrcode-stream @decode="onDecode" v-if="modal.isActive"></qrcode-stream>
      <template v-slot:footer>
        <div class="buttons" style="justify-content: space-between; width: 100%">
          <button class="button is-light" @click="closeScanner()">
            {{ translations.common.close }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<style lang="scss">
.scanner {
  .modal-card-body {
    padding: 0;
  }
  .qrcode-stream-wrapper {
    overflow: hidden;
  }
  .modal-content {
    text-align: center;
    max-width: 30rem;
    background: #131415;
    .title,
    p,
    .subtitle {
      color: $white;
    }
    .box {
      background: $dark;
    }
  }
}
</style>
<script>
// @ is an alias to /src

import { QrcodeStream } from 'vue-qrcode-reader';
import store from '@/store';
import Modal from '@/components/modal.vue';
import router from '@/router';
import { computed } from '@vue/composition-api';
export default {
  components: {
    Modal,
    QrcodeStream,
  },
  data() {
    return {
      modal: {
        isActive: false,
        isCard: true,
        onClose: this.onClose,
      },
      scan: false,
      event: 0,
      error: false,
    };
  },
  setup() {
    const translations = computed(() => store.getters.getTranslations);
    return { translations };
  },

  created() {
    this.event = parseInt(this.$route.params.event, 10);
    store.subscribe((mutation) => {
      if (mutation.type === 'openScanner') {
        this.modal.isActive = true;
      }
    });
  },
  methods: {
    openScanner() {
      this.scan = true;
    },

    closeScanner() {
      this.modal.isActive = false;
    },

    onDecode(decodedString) {
      if (decodedString.includes('beamian.com')) {
        const route = decodedString.split('.com')[1];
        router.push(route);
        this.modal.isActive = false;
      } else {
        window.open(decodedString, '_blank');
      }
    },
  },
};
</script>
