import { IEventsTranslations } from '@/translations/default/events';

const events: Partial<IEventsTranslations> = {
  createExhibitor: 'Registrar empresa',
  createExhibitorDescription: 'Registrar tu empresa en este evento.',
  manageExhibitor: 'Gestionar empresa',
  manageExhibitorDescription: 'Editar información del empresa, ver interacciones.',
};

export default events;
