import {
  IVisitorsDashboardTranslations, IVisitorSidebar, IVisitorExhibitorTranslations, IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  home: 'Accueil',
  information: 'Profil',
  networking: 'Réseautage',
  networking_description: "Rencontrer d'autres candidats",
  sessions: 'Calendrier',
  sessions_description: 'Nombre de séances dans le calendrier des événements:',
  sessions_btn: 'Consulter le calendrier des événements',
  name: 'Nom du candidat :',
  edit: 'Éditer',
  exhibitors: 'Exposants',
  nrOfInteractions: "Nombre de fois où j'ai partagé mon CV :",
  seeAll: 'Voir tous les exposants',
  download: 'Télécharger',
  join_networking: 'Rejoindre la zone de réseautage',
  event_finished: "L'événement est terminé",
  event_not_start: "L'événement n'a pas encore commencé",
};

const sidebar: Partial<IVisitorSidebar> = {
  title: 'Candidat',
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  title: 'Exposants',
  subtitle: "Voir les exposants et consulter leurs offres d'emploi",
  download: 'Télécharger',
  noExhibitors: 'Aucun exposant à afficher.',
  exhibitor_information: 'Informations sur l’exposant',
  contact_success: 'Votre CV a été partagé avec succès !',
  all_exhibitors: 'Tous les exposants',
  interacted_exhibitors: "Exposants avec lesquels j'ai partagé des informations",
  not_interacted_exhibitors: "Exposants avec lesquels je n'ai pas partagé d'informations",
  filter_exhibitors: 'Filtrer les exposants',
  contact_request: 'Laisser mon CV',
  company_info: 'Informations sur l’exposant',
  job_offers: "Offres d'emplois",
  apply_to_job_offer: 'Appliquer',
  job_offer_info: "Informations sur l'offre d'emploi",
  cover_letter: 'Lettre de motivation',
  cover_letter_text: 'Dites-nous pourquoi vous êtes la bonne personne pour ce poste',
  your_info_will_be_shared: "Votre profil sera partagé avec l'entreprise",
  submit_my_application: 'Soumettre',
  successfully_submited: 'Candidature soumise avec succès',
  already_applied: 'Vous avez déjà postulé à ce poste',
  schedule_interview: 'Réservez votre place',
  select_time_slot: 'Choisissez une plage horaire pour y accéder',
};

const products: IVisitorProducts = {
  title: "Offres d'emplois",
  subtitle: "Parcourir et postuler aux offres d'emploi",
  products_same_brand: 'Job offers from the same company',
};

export default {
  dashboard, exhibitors, sidebar, products,
};
