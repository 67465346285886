import {
  IExhibitorsDashboardTranslations, IExhibitorSidebar,
  IExhibitorSettingsTranslations, IExhibitorProducsTranslations, IExhibitorInteractionsTranslations, IExhibitorOnboardingTranslations, IExhibitorSessions, IExhibitorProductInteractions, IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  product_interactions_description: 'Número de candidaturas a ofertas de emprego recebidas:',
  interactions: 'Candidaturas espontâneas',
  nrOfInteractions: 'Número de candidaturas espontâneas recebidas:',
  seeAll: 'Ver candidaturas',
  applications: 'Candidaturas a ofertas de emprego',
  see_applications: 'Ver candidaturas',
};

const sidebar: Partial<IExhibitorSidebar> = {
  exhibitor_interactions: 'Candidaturas espontâneas',
  product_interactions: 'Candidaturas a ofertas',
  exhibitor_sessions: 'Sessões da empresa',
  products: 'Ofertas de emprego',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  // title: 'Definições de empresa',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Candidaturas espontâneas',
  subtitle: 'Consultar candidaturas e agendar entrevistas',
  noInteractions: 'Este expositor não tem interações.',
  interaction: {
    editComment: 'Editar comentário',
    saveComment: 'Guardar comentário',
    checkIn: 'CheckIn',
  },
  search_placeholder: 'Introduza o que pretende procurar na lista de candidaturas',
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Ofertas de emprego',
  subtitle: 'Criar, alterar e apagar ofertas de emprego',
  no_products: 'Nenhum pedido de informação disponível.',
  new_product: 'Novo oferta de emprego',
  create_new_product: 'Criar',
  edit_product: 'Editar oferta de emprego',
  delete_confirm: 'Tem a certeza que pretende apagar esta oferta de emprego?',
  interactions_exist_cannot_delete: 'Esta oferta já tem interações, por isso não é possível eliminar.',
  search_placeholder: 'Introduza o que pretende procurar na lista de ofertas de emprego',
  inputs: {
    name: 'Nome',
  },
};

const onboarding: DeepPartial<IExhibitorOnboardingTranslations> = {
  login: {
    title: 'Está prestes a registar a sua empresa',
    message: 'Para registar a sua empresa precisamos que inicie sessão ou se registe com uma conta Beamian.',
    exhibitor_manager_name: 'Introduza o nome do gestor da empresa',
    exhibitor_manager_email: 'Introduza o email do gestor da empresa',
  },
  basicInformation: {
    title: 'Dados da empresa',
    createExhibitor: 'Criar empresa',
    exhibitor_name: 'Nome da empresa',
    exhibitor_name_placeholder: 'Digite o nome a ser atribuído ao seu stand',
    modal: {
      subtitle: 'Se quiser gerir a sua empresa terá que iniciar sessão ou criar conta.',
    },
  },
  completed: {
    title: 'Empresa criada',
    subtitle: 'A sua empresa está pronta. Obrigado pelo registo!',
  },
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning: 'Crie uma sessão privada para convidar candidatos específicos para uma entrevista. Se for uma sessão pública, qualquer candidato poderá visualizar e registar-se na sua sessão.',
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: 'Candidaturas a ofertas de emprego',
  subtitle: 'Consultar candidaturas e agendar entrevistas',
  product_name: 'Candidatura',
  interaction_name: 'Nome do candidato',
  application: 'Candidatura',
  invite_to_session: 'Agendar entrevista',
  search_placeholder: 'Introduza o que pretende procurar na lista de candidaturas',
};

const profile: Partial<IExhibitorProfile> = {
  // exhibitor_information: 'Informação da empresa',
  products: 'Ofertas de emprego',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
