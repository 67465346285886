import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  exhibitors: 'Empresas',
  exhibitor: 'Empresa',
  productInteractions: 'Candidaturas a ofertas de emprego',
  contact_request: 'Candidatura espontânea',
};

export default common;
