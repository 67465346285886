import { ActionContext } from 'vuex';
import { IData } from '@/api';
import { ISession, ISessionParams } from '@/models/session';
import { ISessionCapacity } from '@/models/session_capacity';
import formatDate from '@/utils/formatDate';

import {
  getSessions, getExhibitorSessions, addExhibitorSession, updateExhibitorSession, deleteExhibitorSession, getSessionRegistrations, addSessionCapacity,
  getEventSessions,
} from '@/api/sessions';
import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateSession extends IObjectState, ISession { }

export type ISessionsState = IStateObject<IStateSession> & {
  calendar_blocks: string[];
};

const state: ISessionsState = {
  list: [],
  selected: [],
  calendar_blocks: [],
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (sessions: ISession[]) => sessions.map((session) => ({
  ...session,
  loading: false,
  error: false,
  message: '',
}));

const getters = {
  getSessions: (state: ISessionsState) => state.list,
  getSessionsState: (state: ISessionsState) => state,
  getSelectedSessions: (state: ISessionsState) => state.selected,
};

const actions = {
  fetchSessions(context: ActionContext<ISessionsState, IState>, params: ISessionParams) {
    context.commit('setSessionsLoading');
    if (params.type === 'exhibitor') {
      getExhibitorSessions(params)
        .then((response) => {
          if (params.reset || params.reset === undefined) {
            context.commit('setSessionsReset');
          }
          context.commit('setSessions', response.data);
        })
        .catch((err) => {
          context.commit('setSessionsError', err);
        });
    } else if (params.type === 'event') {
      getEventSessions(params)
        .then((response) => {
          if (params.reset || params.reset === undefined) {
            context.commit('setSessionsReset');
          }
          context.commit('setSessions', response.data);
        })
        .catch((err) => {
          context.commit('setSessionsError', err);
        });
    } else {
      getSessions(params)
        .then((response) => {
          const sessions = response.data.results;
          if (params.status) {
            getSessionRegistrations(params).then((response2) => {
              const registrations = response2.data.results;
              response.data.results = sessions
                .filter((session) => registrations
                  .some((registration) => registration.session === session.id));

              if (params.reset || params.reset === undefined) {
                context.commit('setSessionsReset');
              }
              context.commit('setSessions', response.data);
            });
          } else {
            if (params.reset || params.reset === undefined) {
              context.commit('setSessionsReset');
            }
            context.commit('setSessions', response.data);
          }
        })
        .catch((err) => {
          context.commit('setSessionsError', err);
        });
    }
  },

  addSession(context: ActionContext<ISessionsState, IState>, params: ISessionParams) {
    context.commit('setSessionsLoading');
    if (params.type === 'exhibitor' && params.payload) {
      addExhibitorSession({ ...params.payload, event: params.event, exhibitor: params.exhibitor })
        .then((response) => {
          // Save registration capacity
          // Note: entrance capacity not supported for exhibitor sessions
          if (params.payload?.registration_capacity !== undefined) {
            const payload = {
              registration_capacity: params.payload.registration_capacity,
              entrance_capacity: params.payload.entrance_capacity,
              session: response.data.id,
              event: params.event,
            } as ISessionCapacity;

            addSessionCapacity(payload)
              .then((responseCapacity) => {
                response.data.registration_capacity = responseCapacity.data.registration_capacity;
                context.commit('newSession', addStateProps([response.data])[0]);
              })
              .catch((err) => {
                context.commit('setSessionsError', err);
              });
          } else {
            // No capacity update
            context.commit('newSession', addStateProps([response.data])[0]);
          }
        })
        .catch((err) => {
          context.commit('setSessionsError', err);
        });
    }
  },

  editSession(context: ActionContext<ISessionsState, IState>, params: ISessionParams) {
    if (params.type === 'exhibitor' && params.payload) {
      context.commit(
        'setLoadingSession',
        context.state.list.find((session) => params.payload && params.payload.id === session.id),
      );
      updateExhibitorSession({ ...params.payload, event: params.event, exhibitor: params.exhibitor })
        .then((response) => {
          // Save registration capacity
          // Note: entrance capacity not supported for exhibitor sessions
          if (params.payload?.registration_capacity !== undefined) {
            const payload = {
              registration_capacity: params.payload.registration_capacity,
              entrance_capacity: params.payload.entrance_capacity,
              session: params.payload.id,
              event: params.event,
            } as ISessionCapacity;

            addSessionCapacity(payload)
              .then((responseCapacity) => {
                response.data.registration_capacity = responseCapacity.data.registration_capacity;
                context.commit('editSession', addStateProps([response.data])[0]);
              })
              .catch((err) => {
                context.commit('setSessionError', { failedSession: params.payload, error: err });
              });
          } else {
            // No capacity update
            context.commit('editSession', addStateProps([response.data])[0]);
          }
        })
        .catch((err) => {
          context.commit('setSessionError', { failedSession: params.payload, error: err });
        });
    }
  },

  deleteSession(context: ActionContext<ISessionsState, IState>, params: ISessionParams) {
    if (params.type === 'exhibitor' && params.payload) {
      context.commit(
        'setLoadingSession',
        context.state.list.find((session) => params.payload && params.payload.id === session.id),
      );
      deleteExhibitorSession({ ...params.payload, event: params.event, exhibitor: params.exhibitor })
        .then((response) => {
          console.log(response);
          context.commit('removeSession', params.payload);
        })
        .catch((err) => {
          context.commit('setSessionError', { failedSession: params.payload, message: err.message });
        });
    }
  },

  deleteSessions(context: ActionContext<ISessionsState, IState>, ids: number[]) {
    context.commit('setLoadingSessions', ids);

    // axios
    //   .delete(`${url}/session/bulk_destroy?ids=${ids.join('&ids=')}`, config())
    //   .then(response => {
    //     context.commit('removeSessions', ids);
    //   })
    //   .catch(err => {
    //     context.commit('setSessionsError', { ids, message: err.message });
    //   });
  },
};

const mutations = {
  setSessionsReset: (state: ISessionsState) => {
    state.loading = false;
    state.error = false;
    state.list = [];
  },
  setSessionsError: (state: ISessionsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setSessionError: (state: ISessionsState, { failedSession, message }: { failedSession: IStateSession; message: string }) => {
    state.list = state.list.map((session) => (session.id === failedSession.id
      ? {
        ...session,
        loading: false,
        error: true,
        message,
      }
      : session));
  },

  setSessionsLoading: (state: ISessionsState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingSession: (state: ISessionsState, session: IStateSession) => {
    state.list[state.list.indexOf(session)] = {
      ...state.list[state.list.indexOf(session)],
      loading: true,
      error: false,
    };
  },

  setSessions: (state: ISessionsState, data: IData<ISession>) => {
    state.error = false;
    state.loading = false;
    state.page = data.current;
    state.page_count = data.page_count;
    if (data.calendar_blocks) {
      state.calendar_blocks = data.calendar_blocks;
    }
    state.list = [...state.list, ...addStateProps(data.results.filter((session) => session.name !== 'check-in' && session.name !== 'checkin'))];
  },

  editSession: (state: ISessionsState, editedSession: IStateSession) => {
    state.list = state.list.map((session) => (session.id === editedSession.id
      ? {
        ...editedSession,
        loading: false,
        error: false,
      }
      : session));
  },

  newSession: (state: ISessionsState, session: IStateSession) => {
    state.loading = false;
    state.error = false;
    state.list.unshift({
      ...session,
      start_date: formatDate(session.start_date ? session.start_date : ''),
      end_date: formatDate(session.end_date ? session.end_date : ''),
    });
  },

  removeSession: (state: ISessionsState, deletedSession: ISession) => {
    state.list = state.list.filter((session) => session.id !== deletedSession.id);
  },

  removeSessions: (state: ISessionsState, ids: number[]) => {
    state.list = state.list.filter((session) => session.id && ids.indexOf(session.id) === -1);
  },

  setLoadingSessions: (state: ISessionsState, ids: number[]) => {
    state.list = state.list.map((session) => ({
      ...session,
      loading: !!(session.id && ids.indexOf(session.id) > -1),
      error: false,
    }));
  },
  selectSession: (state: ISessionsState, session: IStateSession) => {
    if (session.id) {
      const sessionIndex = state.selected.indexOf(session.id);
      if (sessionIndex > -1) {
        state.selected.splice(sessionIndex, 1);
      } else {
        state.selected.push(session.id);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
