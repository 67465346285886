export interface IPopupsState {
    list: IPopup[]
}

export interface IPopup {
    type: 'warning' | 'danger' | 'info' | 'success',
    message: string;
    autohide: boolean;
    onClose?: any
}

const state: IPopupsState = {
  list: [],
};

const getters = {
  getPopups: (state: IPopupsState) => state.list,
};

const mutations = {
  setPopups: (state: IPopupsState, popups: IPopup[]) => {
    state.list = popups;
  },
  addPopup: (state: IPopupsState, popup: IPopup) => {
    state.list.push(popup);
    if (popup.autohide) {
      setTimeout(() => {
        state.list = state.list.filter(
          (statePopup) => statePopup !== popup,
        );
      }, 5000);
    }
    // User clicks the popup away
    popup.onClose = () => {
      state.list = state.list.filter(
        (statePopup) => statePopup !== popup,
      );
    };
  },
};

export default {
  state,
  getters,
  mutations,
};
