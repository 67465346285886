import {
  IVisitorsDashboardTranslations, IVisitorSidebar, IVisitorExhibitorTranslations, IVisitorSessions, IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  networking_description: 'Meet fellow students',
  exhibitors: 'Teachers',
  nrOfInteractions: 'See all teachers',
  seeAll: 'Teachers',
  name: 'Name of the student:',
  featured_exhibitors: 'Teachers',
  featured_products: 'Courses',
  featured_sessions: 'Classes',
};

const sidebar: Partial<IVisitorSidebar> = {
  exhibitors: 'Teachers',
  title: 'Student',
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  title: 'Teachers',
  subtitle: 'See teachers and check their offers',
  noExhibitors: 'No teachers to display.',
  exhibitor_information: 'Teacher information',
  contact_success: 'Your resume was shared successfully!',
  all_exhibitors: 'All teachers',
  interacted_exhibitors: 'Teachers I shared information with',
  not_interacted_exhibitors: "Teachers I didn't share information with",
  filter_exhibitors: 'Filter teachers',
  contact_request: 'Leave my resume',
  company_info: 'Teacher information',
  job_offers: 'Offers',
  apply_to_job_offer: 'Apply',
  job_offer_info: 'Information about the offer',
  cover_letter: 'Cover letter',
  cover_letter_text: 'Tell us why you’re interested in this offer',
  your_info_will_be_shared: 'Your profile will be shared with the teacher',
  submit_my_application: 'Submit',
  successfully_submited: 'Application submitted successfuly',
  already_applied: "You've already applied to this offer",
  schedule_interview: 'Next classes',
};

const sessions: Partial<IVisitorSessions> = {
  exhibitor_sessions: 'Teacher sessions',
};

const products: IVisitorProducts = {
  title: 'Job offers',
  subtitle: 'Browse and apply to offers',
  products_same_brand: 'Offers from the same teacher',
};

export default {
  dashboard, exhibitors, sidebar, products, sessions,
};
