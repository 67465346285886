import _ from 'lodash';
import default_en from '@/translations/default/en';
import default_pt from '@/translations/default/pt';
import default_es from '@/translations/default/es';
import default_de from '@/translations/default/de';
import default_fr from '@/translations/default/fr';
import jobfair_en from '@/translations/jobfair/en';
import jobfair_pt from '@/translations/jobfair/pt';
import jobfair_es from '@/translations/jobfair/es';
import jobfair_de from '@/translations/jobfair/de';
import jobfair_fr from '@/translations/jobfair/fr';
import appdi_en from '@/translations/appdi/en';
import appdi_pt from '@/translations/appdi/pt';
import appdi_es from '@/translations/appdi/es';
import appdi_de from '@/translations/appdi/de';
import appdi_fr from '@/translations/appdi/fr';
import tr_en from '@/translations/trade/en';
import tr_pt from '@/translations/trade/pt';
import tr_es from '@/translations/trade/es';
import tr_de from '@/translations/trade/de';
import tr_fr from '@/translations/trade/fr';
import ed_en from '@/translations/education/en';
import ed_pt from '@/translations/education/pt';
import ed_es from '@/translations/education/es';
import ed_de from '@/translations/education/de';
import ed_fr from '@/translations/education/fr';
import sc_en from '@/translations/science/en';
import sc_pt from '@/translations/science/pt';
import sc_es from '@/translations/science/es';
import sc_de from '@/translations/science/de';
import sc_fr from '@/translations/science/fr';
import store from '@/store';

import { IEventV2 } from '@/models/event';
import { AvailableLanguages } from '@/translations/index';

const defaultTranslations = {
  en: default_en,
  pt: default_pt,
  es: default_es,
  de: default_de,
  fr: default_fr,
};

const jobfairTranslations = {
  en: jobfair_en,
  pt: jobfair_pt,
  es: jobfair_es,
  de: jobfair_de,
  fr: jobfair_fr,
};

const edTranslations = {
  en: ed_en,
  pt: ed_pt,
  es: ed_es,
  de: ed_de,
  fr: ed_fr,
};

const trTranslations = {
  en: tr_en,
  pt: tr_pt,
  es: tr_es,
  de: tr_de,
  fr: tr_fr,
};
const scienceTranslations = {
  en: sc_en,
  pt: sc_pt,
  es: sc_es,
  de: sc_de,
  fr: sc_fr,
};
const e441Translations = {
  en: appdi_en,
  pt: appdi_pt,
  es: appdi_es,
  de: appdi_de,
  fr: appdi_fr,
};

export interface ITranslationsState {
  language: AvailableLanguages;
  event_languages: string[];
  lang: Record<symbol, unknown>;
}

const state: ITranslationsState = {
  language: 'en',
  event_languages: [],
  lang: defaultTranslations.en,
};

const getters = {
  getEventLanguages: (state: ITranslationsState) => state.event_languages,
  getLanguage: (state: ITranslationsState) => state.language,
  getTranslations: (state: ITranslationsState) => state.lang,
};

const mutations = {
  setLanguage: (state: ITranslationsState, language: AvailableLanguages) => {
    state.language = language;
    state.lang = defaultTranslations[language];
    const contextEvent = store.getters.getContextEvent as IEventV2;
    if (contextEvent && contextEvent.settings) {
      if (contextEvent.settings.event_type === 'ed') {
        state.lang = _.merge(state.lang, edTranslations.en);
      }
      if (contextEvent.settings.event_type === 'jf') {
        state.lang = _.merge(state.lang, jobfairTranslations[language]);
      }
      if (contextEvent.settings.event_type === 'e441') {
        state.lang = _.merge(state.lang, e441Translations[language]);
      }
      if (contextEvent.settings.event_type === 'tr') {
        state.lang = _.merge(state.lang, trTranslations[language]);
      }
      if (contextEvent.settings.event_type === 'sc') {
        state.lang = _.merge(state.lang, scienceTranslations[language]);
      }
    }
  },
  setEventLanguages: (state: ITranslationsState, languages: string[]) => {
    state.event_languages = languages;
  },
};

export default {
  state,
  getters,
  mutations,
};
