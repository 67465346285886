import {
  IVisitorOnboardingTranslations, IVisitorExhibitorTranslations, IVisitorSettingsTranslations, IVisitorSidebar, IVisitorsDashboardTranslations, IVisitorNetworkingTranslations, IVisitorExhibitorSessions, IVisitorSessions, IVisitorProfile,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Información',
  name: 'Nombre del visitante:',
  edit: 'Editar',
  exhibitors: 'Empresas',
  nrOfInteractions: 'Número de interacciones:',
  seeAll: 'Ver todas las empresas',
  download: 'Descargar',
  networking: 'Networking',
  networking_description: 'Habla con otros visitantes',
  sessions: 'Sesiones',
  sessions_description: 'Próximas sesiones:',
  sessions_btn: 'Ver todas las sesiones',
  join_networking: 'Únete al espacio de networking',
  event_finished: 'El evento terminó',
  event_not_start: 'El evento no ha comenzado todavía',
};

const sidebar: IVisitorSidebar = {
  title: 'Visitando',
  home: 'Home',
  exhibitors: 'Empresas',
  exhibitor_sessions: 'Reuniones de empresas',
  event_sessions: 'Sesiones del evento',
  settings: 'Formulario',
  sessions: 'Agenda',
  myCalendar: 'Mi agenda',
  eventCalendar: 'Agenda del evento',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Configuración del visitante',
  basicInfo: 'Información básica',
  save: 'Guardar',
  additionalInfo: 'Información adicional',
  formError: 'No hay expositores para mostrar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Expositores',
  subtitle: 'Ver empresas',
  download: 'Descargar',
  noExhibitors: 'No hay expositores disponibles.',
  exhibitor_information: 'Información del expositor',
  contact_success: 'Solicitud de contacto exitosa!',
  all_exhibitors: 'Ver todas las empresas',
  interacted_exhibitors: 'Empresas con los que interactué',
  not_interacted_exhibitors: 'Empresas con las que no interactué',
  filter_exhibitors: 'Filtrar empresas',
  contact_request: 'Intercambiar contactos',
  company_info: 'Información de la empresa',
  job_offers: 'Ofertas de empleo',
  apply_to_job_offer: 'Estoy interesado',
  job_offer_info: 'Información de la oferta de empleo',
  cover_letter: 'Carta de movitación',
  cover_letter_text: 'Dinos por qué eres la persona adecuada para este trabajo',
  your_info_will_be_shared: 'Tu información de registro será compartida con la organización',
  submit_my_application: 'Enviar la solicitud',
  successfully_submited: 'Su solicitud fue exitosa',
  already_applied: 'Ya ha hecho una solicitud para esta oferta de trabajo.',
  schedule_interview: 'Programe su entrevista',
  select_time_slot: 'Selecciona una franja horaria para programar una entrevista con nosotros',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Dinos sobre tu visitante',
    createVisitor: 'Crear visitante',
    modal: {
      title: 'Aviso',
      subtitle: 'Si quieres gestionar tu visitante, por favor, haz login primero.',
      skip: 'Saltar',
      createAccount: 'Crear cuenta',
      login: 'Login',
    },
  },
  managedFields: {
    title: 'Información adicional',
  },
  review: {
    title: 'Revisar información',
    name: 'Nombre',
  },
  completed: {
    title: 'Visitante creado!',
    subtitle: 'Tu visitante está listo. Gracias por completar la información!',
    manage: 'Gestionar',
  },

  back: 'Atrás',
  next: 'Adelante',
  submit: 'Enviar',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Habla con otros visitantes',
  chat: 'Chat',
  profile: 'Perfil',
  signup_title: '¿Unirse a la conversación?',
  signup_message: 'Estás a punto de unirte al espacio de networking. Su información será visible para otros visitantes y ellos podrán hablar con usted.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Regístrese en la reunión para unirse',
  event_sessions: 'Sesiones del evento',
  exhibitor_sessions: 'Sesiones de expositores',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Reuniones a las que fuiste invitado',
};

const profile: IVisitorProfile = {
  visitor_information: 'Información del visitante',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile,
};
