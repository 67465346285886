







































































































































































































































































import { MutationPayload } from 'vuex';
import Offcanvas from '@/components/offcanvas.vue';
import store from '@/store';
import { IAppState } from '@/store/modules/app';
import { ITranslations } from '@/translations';
import { IVisitor } from '@/models/visitor';
import { INotification } from '@/models/notifications';
import { computed, defineComponent } from '@vue/composition-api';
import { sessionRegistrationUpdate } from '@/api/sessions';
import { IRocketRoom } from '@/models/chat';
import router from '@/router';
export default defineComponent({
  components: { Offcanvas },

  data() {
    return {
      notificationsOffcanvas: {
        isActive: false,
      },
      interval: 0,
      event: 0,
      exhibitor: 0,
      acceptLoading: [false],
      rejectLoading: [false],
    };
  },
  setup() {
    const translations = computed(() => store.getters.getTranslations as ITranslations);
    const contextVisitor = computed(() => store.getters.getContextVisitor as IVisitor);
    const userLoggedIn = computed(() => store.getters.userLoggedIn as boolean);

    const notifications = computed(() => store.getters.getNotifications as INotification[]);
    const appState = computed(() => store.getters.getAppState as IAppState);

    const invitations = computed(() =>
      notifications.value.filter((notification) =>
        notification.notification_type.includes('private_exhibitor_session_invitation'),
      ),
    );

    const requests = computed(() =>
      notifications.value.filter((notification) =>
        notification.notification_type.includes('contact_request'),
      ),
    );

    const productInteractions = computed(() =>
      notifications.value.filter((notification) =>
        notification.notification_type.includes('product_interaction'),
      ),
    );

    return {
      translations,
      contextVisitor,
      userLoggedIn,
      notifications,
      appState,
      invitations,
      requests,
      productInteractions,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    if (this.userLoggedIn) {
      store.dispatch('fetchNotifications');
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        store.dispatch('fetchNotifications');
      }, 120000);
    }
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setUserLogin') {
        store.dispatch('fetchNotifications');

        clearInterval(this.interval);

        this.interval = setInterval(() => {
          store.dispatch('fetchNotifications');
        }, 120000);
      }
      if (mutation.type === 'openNotifications') {
        this.notificationsOffcanvas = {
          isActive: this.appState.notificationsOpen,
        };
      }
      if (mutation.type === 'closeNotifications') {
        this.notificationsOffcanvas = {
          isActive: false,
        };
      }
    });
    if (this.$route.query.s) {
      // TODO remove timeout
      // Wait until component is loaded
      setTimeout(() => {
        this.notificationsOffcanvas.isActive = true;
      }, 1000);
    }
  },
  methods: {
    openSession(notification: INotification) {
      router.push(`/event/${notification.event}/sessions/session/${notification.session_source}`);
    },

    inviteInteraction(notification: INotification) {
      router.push(
        `/event/${notification.event}/manage-exhibitor/${notification.receiver_exhibitor}/visitor/${notification.sender_id}/invite/`,
      );
      store.dispatch('updateNotification', { id: notification.id, status: 'seen' });
    },

    dismissNotification(notification: INotification) {
      store.dispatch('deleteNotification', notification);
    },

    inviteProductInteraction(notification: INotification) {
      router.push(
        `/event/${notification.event}/manage-exhibitor/${notification.receiver_exhibitor}/visitor/${notification.sender_id}/invite/`,
      );
      store.dispatch('updateNotification', { id: notification.id, status: 'seen' });
    },

    manageInteraction(notification: INotification) {
      router.push(
        `/event/${notification.event}/manage-exhibitor/${notification.receiver_exhibitor}/visitors`,
      );
      store.dispatch('updateNotification', { id: notification.id, status: 'seen' });
    },

    manageProductInteraction(notification: INotification) {
      router.push(
        `/event/${notification.event}/manage-exhibitor/${notification.receiver_exhibitor}/visitors`,
      );
      store.dispatch('updateNotification', { id: notification.id, status: 'seen' });
    },

    openChat(room: IRocketRoom) {
      store.commit('setChatRoom', {
        rid: room.rid,
        type: room.t === 'c' ? 'c' : 'im',
        roomName: `${room.fname}`,
      });
      store.commit('setChatOpen');
      this.notificationsOffcanvas.isActive = false;
    },

    async acceptInvitation(notification: INotification, index: number) {

      this.acceptLoading[index] = true;
      this.$forceUpdate();

      const updateSRResponse = await sessionRegistrationUpdate({
        id: notification.source_id,
        status: 6,
      }).catch((err) => {
        const errors = err.response.data;
        if (errors && errors.length > 0) {
          errors.forEach((error: string) => {
            store.commit('addPopup', {
              message: error,
              type: 'danger',
              autohide: true,
            });
          });
        } else {
          store.commit('addPopup', {
            message: err.nessage,
            type: 'danger',
            autohide: true,
          });
        }
        this.acceptLoading[index] = false;
        this.$forceUpdate();
      });

      if (updateSRResponse) {
        store.dispatch('updateNotification', { id: notification.id, status: 'seen' });
        this.acceptLoading[index] = false;
        this.$forceUpdate();
      }

      return true;
    },

    async rejectInvitation(notification: INotification, index: number) {
      this.rejectLoading[index] = true;
      this.$forceUpdate();

      const updateSRResponse = await sessionRegistrationUpdate({
        id: notification.source_id,
        status: 7,
      }).catch((err) => {
        const errors = err.response.data;
        if (errors && errors.length > 0) {
          errors.forEach((error: string) => {
            store.commit('addPopup', {
              message: error,
              type: 'danger',
              autohide: true,
            });
          });
        } else {
          store.commit('addPopup', {
            message: err.nessage,
            type: 'danger',
            autohide: true,
          });
        }
        this.rejectLoading[index] = false;
        this.$forceUpdate();
      });

      if (updateSRResponse) {
        store.dispatch('updateNotification', { id: notification.id, status: 'seen' });
        this.rejectLoading[index] = false;
        this.$forceUpdate();
      }

      return true;
    },
  },
});
