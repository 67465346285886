import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  dashboard: 'Painel de controlo',
  events: 'Eventos',
  devices: 'Dispositivos',
  exhibitors: 'Expositores',
  exhibitor: 'Expositor',
  logout: 'Sair',
  edit: 'Editar',
  create: 'Criar',
  delete: 'Apagar',
  name: 'Nome',
  name_placeholder: 'Digite o seu nome',
  email: 'Email',
  description: 'Descrição',
  category: 'Categoria',
  model: 'Modelo',
  save: 'Guardar',
  download: 'Descarregar',
  upload: 'Upload',
  change: 'Mudar',
  apply: 'Guardar',
  emptyFieldNotAllowed: 'Este campo não pode estar vazio',
  next: 'Próxima',
  previous: 'Anterior',
  close: 'Fechar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  search: 'Procurar',
  mandatory_field: 'Este campo é obrigatório.',
  name_in_use: 'Este nome já está a ser usado.',
  crop: 'Cortar',
  published: 'Publicado',
  not_published: 'Não publicados',
  all: 'Todos',
  empty_list: 'Sem dados para mostrar',
  user_chat_failed: 'Este utilizador ainda não está online',
  activity_center: 'Centro de atividade',
  no_recent_activity: 'Sem atividade recente',
  unread_messages: 'Mensagens por ler',
  reply: 'Responder',
  session_invitations: 'Convites por responder',
  accept: 'Aceitar',
  refuse: 'Recusar',
  in_progress: 'Em progresso',
  recent_files: 'Ficheiros recentes',
  open_conversations: 'Conversas em aberto',
  all_fields_required: 'Todos os campos são necessários.',
  something_went_wrong: 'Aconteceu algo de errado',
  already_exists: 'Já existe',
  profile: 'A minha conta',
  filter_by_status: 'Filtrar por estado',
  open_meeting: 'Entrar na reunião',
  visitors: 'Visitantes',
  saved: 'Guardado',
  back: 'Voltar',
  session_full: 'Cheia',
  file_not_image: 'Este formato não é suportado',
  new_export: 'Novo exporte',
  state: 'Estado',
  details: 'Detalhes',
  see_more: 'Ver mais',
  manage: 'Gerir',
  manage_event: 'Gerir evento',
  see_as_visitor: 'Ver como visitante',
  see_as_exhibitor: 'Ver como expositor',
  manage_visitors: 'Gerir visitantes',
  manage_exhibitors: 'Gerir expositores',
  registered: 'registado',
  refused: 'recusado',
  pending: 'pendente',
  accepted: 'aceite',
  favorites: 'Favoritos',
  add_to_favorites: 'Adicionar aos favoritos',
  remove_from_favorites: 'Remover dos favoritos',
  contact_request: 'Pedido de contacto',
  invited: 'Convidado/a',
  productInteractions: 'Interações com produtos',
  note: 'Nota',
  discard: 'Descartar',
  no_comment: 'Sem comentário',
  click_here: 'Clique aqui',
};

export default common;
