import { MutationPayload } from 'vuex';
import Offcanvas from '@/components/offcanvas.vue';
import {
  IRocketChatStreamMessage, IRocketRoom, IRocketUserLoggedIn, IVueChatMessage,
} from '@/models/chat';
import { IUser } from '@/models/user';
import store from '@/store';
import { UserRole } from '@/store/modules/context';
import Chat from '@/components/chat.vue';
import {
  connectToRocketChat,
  getChannelMessages,
  getExhibitorChatAccount,
  getImMessages,
  getRocketSubscriptions,
  getUserChatAccount,
  setChannelRead,
  syncChatExhibitorAccount,
  syncChatUserAccount,
} from '@/api/chat';
import { defineComponent } from '@vue/composition-api';
import { IExhibitor } from '@/models/exhibitor';

const rcurl = process.env.VUE_APP_ROCKET_SERVER_PATH;

export default defineComponent({
  components: {
    Offcanvas, Chat,
  },
  data() {
    return {
      rocketChatPath: process.env.VUE_APP_ROCKET_SERVER_PATH,
      event: 0,
      exhibitor: 0,
      modal: {
        isActive: false,
      },
      socketInit: false,
      roomId: '',
      roomName: '',
      firstLoad: true,
      chat: {
        userId: '',
        roomId: '',
        theme: 'dark',
        rooms: [] as any,
        messages: null as IVueChatMessage[] | null,
        roomsLoadedCount: 1000,
        selectedRoom: '',
      },
      subscriptions: [] as IRocketRoom[],
      subId: '',
      rc: connectToRocketChat(),
      messagesInterval: 0,
      messagesLoaded: false,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    const userLoggedIn = store.getters.userLoggedIn as boolean;
    const role = store.getters.role as UserRole;

    // ping server to keepAlive
    this.rc.connectToServer().subscribe(
      () => {
        this.rc.keepAlive().subscribe(); // Ping Server
      },
      (error) => {
        console.log('ping server error', error);
      },
    );

    if (userLoggedIn) {
      if (role === 'exhibitor') {
        this.loginRocketExhibitor();
      } else {
        this.loginRocketUser();
      }
    }

    store.subscribe(async (mutation: MutationPayload) => {
      if (mutation.type === 'setRoles') {
        const role = store.getters.getContextRole as UserRole;
        if (role !== 'exhibitor') {
          this.loginRocketUser();
        }
      }

      if (mutation.type === 'setContextExhibitor' && mutation.payload.id) {
        this.loginRocketExhibitor();
      }

      if (mutation.type === 'setChatRoom') {
        this.setChatRoom(mutation.payload);
      }

      if (mutation.type === 'setChatOpen') {
        this.modal.isActive = true;
        if (mutation.payload && this.subscriptions[0] && this.subscriptions[0].rid) {
          this.selectChannel(this.subscriptions[0].rid);
        }
      }

      if (mutation.type === 'setRocketUserLogin') {
        this.setRocketUserLogin();
        clearInterval(this.messagesInterval);
        this.messagesInterval = setInterval(() => {
          this.setRocketUserLogin();
        }, 60000);
      }
    });
  },
  methods: {
    async setChatRoom(room: { rid: string, type: string, roomName: string }) {
      this.roomName = room.roomName;
      const newChatRoom = !this.chat.rooms.some((chatRoom: { roomId: string; }) => chatRoom.roomId === room.rid);
      if (newChatRoom) {
        const response = await getRocketSubscriptions();

        if (response.data.update) {
          this.subscriptions = response.data.update.sort((subscription, oldSubscription) => {
            if (subscription.unread > 0 || subscription.alert || subscription._updatedAt > oldSubscription._updatedAt) {
              return -1;
            }
            return 1;
          });

          this.chat = {
            ...this.chat,
            rooms: this.subscriptions.map((subscription) => ({
              roomId: subscription.rid,
              roomName: subscription.fname ? subscription.fname : subscription.name,
              users: [
                {
                  _id: 1000000000,
                  username: 'John Doe',
                  avatar: 'assets/imgs/doe.png',
                  status: {
                    state: 'online',
                    lastChanged: 'today, 14:30',
                  },
                },
                {
                  _id: 10000000001,
                  username: 'John Snow',
                  avatar: 'assets/imgs/snow.png',
                  status: {
                    state: 'offline',
                    lastChanged: '14 July, 20:00',
                  },
                },
                {
                  _id: 10000000002,
                  username: 'John Snow',
                  avatar: 'assets/imgs/snow.png',
                  status: {
                    state: 'offline',
                    lastChanged: '14 July, 20:00',
                  },
                },
              ],
              avatar: `${rcurl}/avatar/${subscription.name}?size=50`,
              unreadCount: subscription.unread,
            })),
          };
          this.chat.selectedRoom = room.rid;
        }
      } else {
        this.chat.selectedRoom = room.rid;
      }

      if (room.type === 'im' || room.type === 'd') {
        this.getImMessages(room.rid);
      } else {
        this.getChannelMessages(room.rid);
      }

      if (this.roomId !== room.rid || newChatRoom) {
        this.roomId = room.rid;
        this.rc.sendMessage({
          msg: 'unsub',
          id: this.subId,
        });
        this.joinChannelAndStream(room.rid);
      }
    },

    async setRocketUserLogin() {

      const response = await getRocketSubscriptions();

      if (response.data.update) {
        this.subscriptions = response.data.update
          .sort((subscription, oldSubscription) => {
            if (subscription.unread > 0 || subscription.alert || subscription._updatedAt > oldSubscription._updatedAt) {
              return -1;
            }
            return 1;
          });
        const unreadMessages = this.subscriptions.filter((subscription) => subscription.unread > 0 || subscription.alert);
        store.commit('setMessagesNotifications', unreadMessages);
        this.chat = {
          ...this.chat,
          rooms: this.subscriptions.map((subscription) => ({
            roomId: subscription.rid,
            roomName: subscription.fname ? subscription.fname : subscription.name,
            users: [
              {
                _id: 1000000000,
                username: 'John Doe',
                avatar: 'assets/imgs/doe.png',
                status: {
                  state: 'online',
                  lastChanged: 'today, 14:30',
                },
              },
              {
                _id: 10000000001,
                username: 'John Snow',
                avatar: 'assets/imgs/snow.png',
                status: {
                  state: 'offline',
                  lastChanged: '14 July, 20:00',
                },
              },
              {
                _id: 10000000002,
                username: 'John Snow',
                avatar: 'assets/imgs/snow.png',
                status: {
                  state: 'offline',
                  lastChanged: '14 July, 20:00',
                },
              },
            ],
            avatar: `${rcurl}/avatar/${subscription.name}?size=50`,
            unreadCount: subscription.unread,
          })),
        };
        this.$forceUpdate();
      }
    },

    async loginRocketUser() {
      const currentUser = store.getters.currentUser as IUser;
      if (currentUser.id) {
        const response = await getUserChatAccount(currentUser.id);
        if (response.data.results.length > 0) {
          const auth = response.data.results[0];
          if (auth.rocket_chat_auth_token) {
            this.chat.userId = auth.rocket_chat_user_id;
            this.loginAndConnect(auth.rocket_chat_auth_token);
            store.commit('setRocketUserLogin', auth);
          } else {
            syncChatUserAccount(auth.id).then((response) => {
              const auth = response.data;
              this.chat.userId = auth.rocket_chat_user_id;
              store.commit('setRocketUserLogin', auth);
              this.loginAndConnect(auth.rocket_chat_auth_token);
            });
          }
        }
      }
    },

    async loginRocketExhibitor() {
      this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
      const contextExhibitor = store.getters.getContextExhibitor as IExhibitor;
      const response = await getExhibitorChatAccount(contextExhibitor.id || this.exhibitor);
      if (response.data.results.length > 0) {
        const auth = response.data.results[0];
        if (auth.rocket_chat_auth_token) {
          this.chat.userId = auth.rocket_chat_user_id;
          store.commit('setRocketUserLogin', auth);
          this.loginAndConnect(auth.rocket_chat_auth_token);
        } else {
          syncChatExhibitorAccount(auth.id).then((response) => {
            const auth = response.data;
            this.chat.userId = auth.rocket_chat_user_id;
            store.commit('setRocketUserLogin', auth);
            this.loginAndConnect(auth.rocket_chat_auth_token);
          });
        }
      }
    },

    initRocket() {
      this.rc.onError((error) => console.log(error));
      this.rc.onCompletion(() => console.log('finished'));
      this.rc.onMessage((_msg) => {
        const msg = _msg as IRocketChatStreamMessage;
        const language = store.getters.getLanguage as string;
        if (
          msg.fields &&
          msg.fields.args &&
          msg.fields.args[0] &&
          msg.fields.args[0].rid === this.roomId
        ) {
          const message = msg.fields.args[0];
          const date = new Date(message._updatedAt.$date);
          this.chat = {
            ...this.chat,
            messages: [
              ...(this.chat.messages ? this.chat.messages : []),
              ...[
                {
                  _id: msg.id,
                  content: message.msg,
                  sender_id: message.u._id,
                  username: message.u.name,
                  date: date.toLocaleDateString(language),
                  timestamp: date.toLocaleTimeString(language),
                  system: false,
                  saved: false,
                  distributed: false,
                  seen: false,
                  disable_actions: true,
                  disable_reactions: true,
                }],
            ],
          };
        }
      });
    },

    joinChannelAndStream(channel: string) {
      this.subId = `${new Date().getTime()}`;
      this.rc.sendMessage({
        msg: 'sub',
        id: this.subId,
        name: 'stream-room-messages',
        params: [channel, true],
      });
    },

    loginAndConnect(token: string) {
      this.rc.loginWithAuthToken(token).subscribe(
        (apiEvent) => {
          if (apiEvent.msg === 'result' && !this.socketInit) {
            this.socketInit = true;
            this.initRocket();
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },

    async getChannelMessages(channel: string) {
      this.messagesLoaded = false;

      this.chat = {
        ...this.chat,
        messages: [],
      };
      const response = await getChannelMessages(channel);
      const messages = response.data.messages.filter((msg) => !msg.t);
      const language = store.getters.getLanguage as string;

      this.chat.messages = [];

      if (messages && messages.length > 0) {
        this.chat = {
          ...this.chat,
          messages: [
            ...messages
              .sort((prev, next) => {
                if (prev._updatedAt < next._updatedAt) {
                  return -1;
                }
                return 1;
              })
              .filter((msg) => !msg.t)
              // filter messages about users joining the channel (t property unique to those cases)

              .map((msg) => {
                const date = new Date(msg._updatedAt as string);
                return {
                  _id: msg._id,
                  content: msg.msg,
                  sender_id: msg.u._id,
                  username: msg.u.name,
                  date: date.toLocaleDateString(language),
                  timestamp: date.toLocaleTimeString(language),
                  system: false,
                  saved: true,
                  distributed: true,
                  seen: true,
                  disable_actions: true,
                  disable_reactions: true,
                };
              }),
          ],
        };
      } else {
        this.chat = {
          ...this.chat,
          messages: null,
        };
      }
      this.messagesLoaded = true;

    },

    async getImMessages(channel: string) {
      this.messagesLoaded = false;

      this.chat = {
        ...this.chat,
        messages: [],
      };
      const response = await getImMessages(channel);
      const messages = response.data.messages.filter((msg) => !msg.t);
      const language = store.getters.getLanguage as string;
      const rocketAuth = store.getters.getRocketAuth as IRocketUserLoggedIn;
      if (messages && messages.length > 0) {
        this.chat = {
          ...this.chat,
          messages: [
            ...messages
              .sort((prev, next) => {
                if (prev._updatedAt < next._updatedAt) {
                  return -1;
                }
                return 1;
              })
              // filter messages about users joining the channel (t property unique to those cases)
              .filter((msg) => !msg.t)
              .map((msg) => {
                const date = new Date(msg._updatedAt as string);
                const seen = msg.u._id !== rocketAuth.rocket_chat_user_id;
                return {
                  _id: msg._id,
                  content: msg.msg,
                  sender_id: msg.u._id,
                  username: msg.u.name,
                  date: date.toLocaleDateString(language),
                  timestamp: date.toLocaleTimeString(language),
                  system: false,
                  saved: false,
                  distributed: false,
                  seen,
                  disable_actions: true,
                  disable_reactions: true,

                };
              }),
          ],
        };
      } else {
        this.chat = {
          ...this.chat,
          messages: null,
        };
      }

      this.messagesLoaded = true;
      this.$forceUpdate();
    },

    sendMessage(msg: { content: string; file: File | null; replyMessage: string; roomId: number }) {
      this.rc.sendMessage({
        msg: 'method',
        method: 'sendMessage',
        id: `${new Date().getTime()}`,
        params: [
          {
            _id: `${new Date().getTime()}`,
            rid: this.roomId,
            msg: msg.content,
          },
        ],
      });
    },

    selectChannel(rid: string) {
      if (rid !== this.chat.selectedRoom && !this.firstLoad) {
        const subscription = this.subscriptions.find((subscription) => subscription.rid === rid);
        if (subscription) {
          setChannelRead({ rid });
          setTimeout(() => {
            this.subscriptions = this.subscriptions.map((oldSubscription) => {
              if (oldSubscription.rid === subscription.rid) {
                return {
                  ...oldSubscription,
                  unread: 0,
                  alert: false,
                };
              }
              return oldSubscription;
            });
            const unreadMessages = this.subscriptions.filter((subscription) => subscription.unread > 0 || subscription.alert);
            store.commit('setMessagesNotifications', unreadMessages);
            this.chat.rooms = this.subscriptions.map((subscription) => ({
              roomId: subscription.rid,
              roomName: subscription.fname ? subscription.fname : subscription.name,
              users: [
                {
                  _id: 1000000000,
                  username: 'John Doe',
                  avatar: 'assets/imgs/doe.png',
                  status: {
                    state: 'online',
                    lastChanged: 'today, 14:30',
                  },
                },
                {
                  _id: 10000000001,
                  username: 'John Snow',
                  avatar: 'assets/imgs/snow.png',
                  status: {
                    state: 'offline',
                    lastChanged: '14 July, 20:00',
                  },
                },
                {
                  _id: 10000000002,
                  username: 'John Snow',
                  avatar: 'assets/imgs/snow.png',
                  status: {
                    state: 'offline',
                    lastChanged: '14 July, 20:00',
                  },
                },
              ],
              avatar: `${rcurl}/avatar/${subscription.name}?size=50`,
              unreadCount: subscription.unread,
            }));
          }, 2000);
          store.commit('setChatRoom', { rid, type: subscription.t, roomName: subscription.name });

        }
      }
      this.firstLoad = false;
    }
  }
});



