import { ActionContext } from 'vuex';
import { IRocketRoom } from '@/models/chat';

import { IObjectState, IState } from '..';
import { getRocketSubscriptions } from '../../api/chat';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateRoom extends IObjectState, IRocketRoom {
}

export type IChatState = {
  active: boolean;
  room: { rid: string, type: string };
  subscriptions: IRocketRoom[];
};

const state: IChatState = {
  active: false,
  room: { rid: '', type: '' },
  subscriptions: [],
};

const getters = {
  getChatIsActive: (state: IChatState) => state.active,
  getChatRooms: (state: IChatState) => state.subscriptions,
  getUnreadMessages: (state: IChatState) => state.subscriptions.filter((subscription) => subscription.unread > 0 && subscription.t === 'd'),
  getChatState: (state: IChatState) => state,
  getChatRoom: (state: IChatState) => state.room,
};

const actions = {
  fetchSubscriptions(context: ActionContext<IChatState, IState>) {
    context.commit('setSubscriptionsLoading');

    getRocketSubscriptions()
      .then((response) => {
        context.commit('setSubscriptions', response.data.update);
      })
      .catch((err) => {
        context.commit('setEventsError', err.request);
      });
  },
};

const mutations = {

  setSubscriptions: (state: IChatState, subscriptions: IRocketRoom[]) => {
    state.subscriptions = subscriptions;
  },
  setChatOpen: (state: IChatState) => {
    state.active = true;
  },
  setChatClose: (state: IChatState) => {
    state.active = false;
  },
  setChatRoom: (state: IChatState, payload: { rid: string, type: string }) => {
    state.room = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
