import { ActionContext } from 'vuex';
import { IParams } from '@/api';
import { addEvent, editEvent, getEvents } from '@/api/events';
import { IEvent, IEventV2 } from '@/models/event';

import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateEvent extends IObjectState, IEvent { }

export type IEventsState = IStateObject<IStateEvent>;

export interface IEventsParams extends IParams {
  search?: string;
}

const state: IEventsState = {
  list: [],
  selected: [],
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (events: (IEvent | IEventV2)[]) => events.map((event) => ({
  ...event,
  loading: false,
  error: false,
  message: '',
}));

const getters = {
  getEvents: (state: IEventsState) => state.list,
  getEventsState: (state: IEventsState) => state,
  getSelectedEvents: (state: IEventsState) => state.selected,
};

const actions = {
  fetchEvents(context: ActionContext<IEventsState, IState>, params: IEventsParams) {
    context.commit('setEventsLoading');

    getEvents(params)
      .then((response) => {
        const events = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        if (params.reset) {
          context.commit('resetEvents');
        }

        context.commit('setEvents', events);
      })
      .catch((err) => {
        context.commit('setEventsError', err.request);
      });
  },

  addEvent(context: ActionContext<IEventsState, IState>, event: IEvent) {
    context.commit('setEventsLoading');

    addEvent(event)
      .then((response) => {
        context.commit('newEvent', addStateProps([response.data])[0]);
      })
      .catch((err) => {
        context.commit('setEventsError', err.response.data);
      });
  },

  editEvent(context: ActionContext<IEventsState, IState>, editedEvent: IStateEvent) {
    context.commit(
      'setLoadingEvent',
      context.state.list.find((event) => editedEvent.id === event.id),
    );

    editEvent(editedEvent)
      .then((response) => {
        context.commit('editEvent', response);
      })
      .catch((err) => {
        context.commit('setEventError', { failedEvent: editedEvent, message: err.message });
      });
  },
  deleteEvent(context: ActionContext<IEventsState, IState>, deletedEvent: IStateEvent) {
    context.commit('setLoadingEvent', deletedEvent);
  },

  deleteEvents(context: ActionContext<IEventsState, IState>, ids: number[]) {
    context.commit('setLoadingEvents', ids);

    // axios
    //   .delete(`${url}/event/bulk_destroy?ids=${ids.join('&ids=')}`, config())
    //   .then(response => {
    //     context.commit('removeEvents', ids);
    //   })
    //   .catch(err => {
    //     context.commit('setEventsError', { ids, message: err.message });
    //   });
  },
};

const mutations = {
  setEventsError: (state: IEventsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setEventError: (state: IEventsState, { failedEvent, message }: { failedEvent: IStateEvent; message: string }) => {
    state.list = state.list.map((event) => (event.id === failedEvent.id
      ? {
        ...event,
        loading: false,
        error: true,
        message,
      }
      : event));
  },

  setEventsLoading: (state: IEventsState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingEvent: (state: IEventsState, event: IStateEvent) => {
    state.list[state.list.indexOf(event)] = {
      ...state.list[state.list.indexOf(event)],
      loading: true,
      error: false,
    };
  },

  setEvents: (state: IEventsState, events: IEventsState) => {
    state.error = false;
    state.loading = false;
    state.list = [...state.list, ...events.list];
    state.page = events.page;
    state.page_count = events.page_count;
    state.item_count = events.item_count;
  },

  editEvent: (state: IEventsState, editedEvent: IStateEvent) => {
    state.list = state.list.map((event) => (event.id === editedEvent.id
      ? {
        ...editedEvent,
        loading: false,
        error: false,
      }
      : event));
  },

  newEvent: (state: IEventsState, event: IStateEvent) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(event);
  },

  removeEvent: (state: IEventsState, deletedEvent: IEvent) => {
    state.list = state.list.filter((event) => event.id !== deletedEvent.id);
  },

  removeEvents: (state: IEventsState, ids: number[]) => {
    state.list = state.list.filter((event) => event.id && ids.indexOf(event.id) === -1);
  },

  setLoadingEvents: (state: IEventsState, ids: number[]) => {
    state.list = state.list.map((event) => ({
      ...event,
      loading: !!(event.id && ids.indexOf(event.id) > -1),
      error: false,
    }));
  },
  selectEvent: (state: IEventsState, event: IStateEvent) => {
    if (event.id) {
      const eventIndex = state.selected.indexOf(event.id);
      if (eventIndex > -1) {
        state.selected.splice(eventIndex, 1);
      } else {
        state.selected.push(event.id);
      }
    }
  },
  resetEvents: (state: IEventsState) => {
    state.error = false;
    state.loading = false;
    state.list = [];
    state.page = 0;
    state.page_count = 0;
    state.item_count = 0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
