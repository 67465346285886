import { IEventsTranslations } from '.';

const table: IEventsTranslations = {
  title: 'Bem vindo à Beamian',
  subtitle: 'Por favor escolha um evento',
  rolesTitle: 'Bem vindo à beamian',
  rolesSubtitle: 'O que precisa fazer?',
  registerVisitor: 'Registar visitante',
  registerVisitorDescription: 'Registe-se neste evento como visitante.',
  createExhibitor: 'Registar expositor',
  createExhibitorDescription: 'Registe o seu expositor neste evento.',
  manageExhibitor: 'Gerir expositor',
  manageExhibitorDescription: 'Editar informação de expositor, ver interações.',
  manageVisitor: 'Gerir visitante',
  manageVisitorDescription: 'Editar informação de visitante, ver interações.',
  manageEvent: 'Gerir evento',
  manageEventDescription: 'Aceder a configuraçoes e informação do evento.',
  name: 'Nome do evento',
  email: 'Email do gestor de evento',
  new: 'Novo evento',
  edit: 'Editar evento',
  delete: 'Apagar',
  create: 'Criar evento',
  send_registration_invite: 'Enviar convite de registo',
  assign: 'Atribuir',
  delete_manager: 'Remover gestor de evento',
  no_managers_assigned: 'Este evento não tem gestores atribuidos',
  start_date: 'Data de início',
  end_date: 'Data de fim',
  capacity: 'Capacidade',
  location: 'Localização',
  location_placeholder: 'Digite a localização do evento',
  is_published: 'Está publicado',
  base_cost: 'Custo base',
  max_capacity: 'Capacidade máxima',
  assign_manager: 'Atribuir gestor de evento (selecione apenas 1 evento)',
  name_placeholder: 'Digite o nome do evento',
  manager: 'Gestor de evento',
  select_manager: 'Selecione gestor de evento',
  timezone: 'Fuso horário',
  europe: 'Europa',
  london: 'Londres',
  lisbon: 'Lisboa',
  madrid: 'Madrid',
  berlin: 'Berlim',
  default_language: 'Idioma pre-definido',
  portuguese: 'Portugues',
  spanish: 'Espanhol',
  english: 'Inglês',
  german: 'Alemão',
  french: 'Francês',
  description: 'Descrição',
  description_placeholder: 'Digite uma descrição para o evento',
  image: 'Imagem',
  image_placeholder: 'Clique para fazer upload de imagem',
  background_image: 'Imagem de fundo',
  background_image_placeholder: 'Clique para fazer upload de imagem',
  created_success: 'Evento criado.',
  managers: 'Gestores de evento',
  manager_email_placeholder: 'Digite o email do utilizador',
  manager_added_success: 'Gestor de evento adicionado.',
  manager_deleted_success: 'Gestor de evento eliminado.',
  manager_user_not_exists: 'Utilizador não existe, certifique-se que escreveu o email corretamente ou envie um convite de registo.',
  manager_setup: 'Definir nome e password',
  manager_user_password: 'Palavra passe',
  manager_user_name_placeholder: 'Digite nome do utilizador',
  manager_user_password_placeholder: 'Digite a palavra chave do utilizador',
  setup_booklet: 'Definir booklet',
  booklet: 'booklet',
  title_placeholder: 'Digite o título do livreto',
  cover: 'Capa',
  cover_placeholder: 'Carregar imagem da capa do livreto',
  configure_booklet: 'Se você deseja enviar um livreto quando o evento terminar, configure-o.',
  no_fields_selected: 'Nenhum campo selecionado.',
  drag_drop_fields: 'Arraste e solte campos para definir a ordem.',
  fields: 'Campos',
  create_booklet_success: 'Booklet criado',
  create_booklet_error: 'Criação de booklet falhou',
  edit_booklet_success: 'Booklet editado',
  edit_booklet_error: 'Edição de booklet falhou',
  no_available_fields: 'Sem campos disponíveis',
  empty_space: 'Espaço vazio',
  live_interactions_email: 'Email de convite das interações live',
};

export default table;
