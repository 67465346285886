import { ITableTranslations } from '.';

const table: ITableTranslations = {
  showing: 'Mostrando',
  of: 'de',
  items: 'artículos',
  see: 'Ver',
  see_selected_only: 'Ver selecionado/s solo',
  clear_selection: 'Borrar selección',
  all_items: 'Todos los artículos',
  items_per_page: 'Artículos por página:',
  previous: 'Anterior',
  next: 'Siguiente',
  table_settings: 'Configuración de la mesa',
  search_settings: 'Buscar configuraciones',
  refresh_table: 'Refrescar mesa',
  show_hide_columns: 'Mostrar/esconder columnas',
  search_fields: 'Buscar campos',
  search_data: 'Buscar',
};

export default table;
