import { IEventsTranslations } from '@/translations/default/events';

const events: Partial<IEventsTranslations> = {
  createExhibitor: 'Register teacher',
  createExhibitorDescription: 'Register a teacher on this event.',
  manageExhibitor: 'Manage teacher',
  manageExhibitorDescription: 'Edit teacher information, see interactions.',
};

export default events;
