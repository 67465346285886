import axios from 'axios';
import {
  IAvailableTicket,
  ITicket, ITicketAssignment, ITicketsParams, ITicketType,
} from '@/models/ticket';
import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;


export const getTicketsAvailable = (params: ITicketsParams) => new Promise((resolve: Resolve<IAvailableTicket[]>, reject) => {
  let requestUrl = `${url}/organizers/events/${params.event}/tickets/available?page_size=1000`;

  if (params.exhibitor) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});


export const getTicketMode = (params: ITicketsParams) => new Promise((resolve: Resolve<IData<{ id: number, event: number, mode: 'vr' | 'ec' | 'ds' }>>, reject) => {
  let requestUrl = `${url}/v2/ticket-modes?page_size=100&ordering=-id`;


  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getTickets = (params: ITicketsParams) => new Promise((resolve: Resolve<IData<ITicket>>, reject) => {
  let requestUrl = `${url}/v2/tickets?page_size=1000`;

  if (params.exhibitor) {
    requestUrl += `&exhibitor=${params.exhibitor}`;
  }

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getTicket = (ticketId: number) => new Promise((resolve: Resolve<ITicket>, reject) => {
  const requestUrl = `${url}/v2/tickets/${ticketId}`;

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getTicketTypes = (event: number) => new Promise((resolve: Resolve<IData<ITicketType>>, reject) => {
  axios
    .get(`${url}/v1/ticket-types?event=${event}&page_size=100`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getTicketType = (ticketId: number) => new Promise((resolve: Resolve<ITicketType>, reject) => {
  axios
    .get(`${url}/v1/ticket-types/${ticketId}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const newTicketAssignment = (ticketAssignment: ITicketAssignment) => new Promise((resolve: Resolve<ITicketAssignment>, reject) => {
  axios
    .post(`${url}/v2/ticket-assignments`, ticketAssignment, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
