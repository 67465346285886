import {
  IVisitorOnboardingTranslations, IVisitorExhibitorTranslations, IVisitorSettingsTranslations, IVisitorSidebar, IVisitorsDashboardTranslations, IVisitorNetworkingTranslations, IVisitorExhibitorSessions, IVisitorSessions, IVisitorProfile,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Information',
  networking: 'Networking',
  networking_description: 'Talk to other visitors',
  sessions: 'Sessions',
  sessions_description: 'Upcoming sessions:',
  sessions_btn: 'See all sessions',
  name: 'Visitor name:',
  edit: 'Edit',
  exhibitors: 'Exhibitors',
  nrOfInteractions: 'Nr of contact requests:',
  seeAll: 'See all exhibitors',
  download: 'Download',
  join_networking: 'Juntar-se ao espaço de networking',
  event_finished: 'Event is finished',
  event_not_start: 'Event did not start yet',
};

const sidebar: IVisitorSidebar = {
  title: 'Besuch',
  home: 'Zuhause',
  exhibitors: 'Aussteller',
  settings: 'Bilden',
  sessions: 'Sessions',
  exhibitor_sessions: 'Ausstellersitzungen',
  event_sessions: 'Ereignissitzungen',
  myCalendar: 'Mein Kalender',
  eventCalendar: 'Veranstaltungskalender',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Visitor settings',
  basicInfo: 'Basic information',
  save: 'Save',
  additionalInfo: 'Additional information',
  formError: 'No information retrieved, form not available.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Exhibitors',
  subtitle: 'Exhibitors',
  download: 'Download',
  noExhibitors: 'This event has no exhibitors.',
  exhibitor_information: 'Informação de expositor',
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  all_exhibitors: 'All visitors',
  interacted_exhibitors: 'Visitors I interacted with',
  not_interacted_exhibitors: "Visitors I didn't interact with",
  filter_exhibitors: 'Filtrar expositores',
  contact_request: 'Contact request',
  company_info: 'Company information',
  job_offers: 'Job offers',
  apply_to_job_offer: 'Apply',
  job_offer_info: 'Job offer information',
  cover_letter: 'Cover letter',
  cover_letter_text: 'Tell us why you are the right person for this job offer',
  your_info_will_be_shared: 'Your registration information will be shared with the company',
  submit_my_application: 'Submit my application',
  successfully_submited: 'Application sent successfully',
  already_applied: 'You have already applied to this position',
  schedule_interview: 'Schedule your interview',
  select_time_slot: 'Select a time slot to schedule an interview with us',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Tell us about your visitor',
    createVisitor: 'Create visitor',
    modal: {
      title: 'Warning',
      subtitle: 'If you wish to manage your visitor, please login first.',
      skip: 'Skip',
      createAccount: 'Create account',
      login: 'Login',
    },
  },
  managedFields: {
    title: 'Additional information',
  },
  review: {
    title: 'Review information',
    name: 'Name',
  },
  completed: {
    title: 'Visitor created!',
    subtitle: 'Your visitor is ready. Thank you for your submission!',
    manage: 'Manage',
  },
  back: 'Back',
  next: 'Next',
  submit: 'Submit',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Talk to other visitors',
  chat: 'Chat',
  profile: 'Profile',
  signup_title: 'Join conversation?',
  signup_message: "You're about to join the network space. Your information will be visible to other visitors and they will be able  to talk to you.",
};

const sessions: IVisitorSessions = {
  subtitle: 'Registe-se nas próximas sessões',
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Registe-se nas próximas sessões',
};
const profile: IVisitorProfile = {
  visitor_information: 'Visitor information',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile,
};
