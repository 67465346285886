import { IAccountSettingsTranslations } from './index';

const settings: IAccountSettingsTranslations = {
  accountSettings: 'Kontoeinstellungen',
  changeAccountInfo: 'Ändern Sie Ihre Kontoinformationen',
  save: 'Speichern',
  resetPassword: 'Passwort zurücksetzen',
  password_confirm: 'Passwort bestätigen',
  password_confirm_placeholder: 'Geben Sie Ihr Passwort erneut ein',
  no_profile_pic: 'Sie haben kein Profilbild. Bitte laden Sie es hoch',
  here: 'hier',
};

export default { settings };
