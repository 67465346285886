


















































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import Modal from '@/components/modal.vue';
import Offcanvas from '@/components/offcanvas.vue';
import Accordion from '@/components/accordion.vue';
import Textarea from '@/components/form/textarea.vue';
import store from '@/store';
import { createSupportChannel, sendMessage } from '@/api/chat';
import { IRocketUserLoggedIn } from '@/models/chat';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { Offcanvas, Accordion, Modal, Textarea },

  data() {
    return {
      offcanvas: {
        isActive: false,
      },
      modal: {
        isActive: false,
        isCard: true,
      },
      firstMessage: '',
      inputTextarea: {
        id: 'first-message',
        name: 'first-message',
        label: 'How can we help you?',
        placeholder: 'Tell us what we can do for you',
        value: '',
      },
      event: 0,
      messageLoading: false,
    };
  },
  setup() {
    const translations = computed(() => store.getters.getTranslations);
    const rocketAuth = computed(() => store.getters.getRocketAuth as IRocketUserLoggedIn);

    return {
      translations,
      rocketAuth,
    };
  },
  created() {
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'openHelpCenter') {
        this.offcanvas.isActive = true;
      }
    });
  },
  methods: {
    async openHelpDeskChat() {
      this.event = parseInt(this.$route.params.event, 10);

      this.messageLoading = true;
      const response = await createSupportChannel({
        event: this.event,
        rocket_chat_user_id: this.rocketAuth.rocket_chat_user_id,
      });
      const room = response.data;

      await sendMessage({
        roomId: room.rocket_chat_room_id,
        text: this.firstMessage,
      });

      this.messageLoading = false;

      this.modal.isActive = false;

      store.commit('setChatRoom', {
        rid: room.rocket_chat_room_id,
        type: 'c',
        roomName: 'Help desk',
      });
      store.commit('setChatOpen');
    },
  },
});
