import { IAuthLoginTranslations, IAuthRegisterTranslations } from '.';

const login: IAuthLoginTranslations = {
  welcome: 'Bem vindo',
  email: 'Email',
  email_placeholder: 'Digite o seu email',
  password: 'Password',
  password_placeholder: 'Digite a sua palavra passe',
  login: 'Iniciar sessão',
  log_back: 'Voltar a iniciar sessão?',
  logged_out: 'Sessão terminada',
  or: 'ou',
  other_account: 'Outra conta',
  forgot_password: 'Esqueceu-se da sua palavra passe?',
  newAccount: 'Nova conta',
  wrong_password: 'Não conseguimos fazer login com estas credênciais.',
};

const register: IAuthRegisterTranslations = {
  newAccount: 'Nova conta',
  error: 'There was an error creating your user. Please try again later.',
  cancel: 'Cancel',
  signUp: 'Registar',
  welcome: 'Welcome to Beamian!',
  confirmationEmail: "We've sent you an email for confirming your account. Please check your inbox.",
  login: 'Iniciar sessão',
  password_not_match: 'As palavras passe não coincidem.',
};

export default { login, register };
