import { IEventsTranslations } from '.';

const events: IEventsTranslations = {
  title: 'Bienvenue sur la page de beamian',
  subtitle: 'Veuillez choisir un événement',
  rolesTitle: 'Bienvenue',
  rolesSubtitle: 'Que souhaitez-vous faire ?',
  registerVisitor: 'Inscrire un visiteur',
  registerVisitorDescription: 'Inscrivez-vous ou inscrivez une autre personne en tant que visiteur de cet événement.',
  createExhibitor: 'Inscrire un exposant',
  createExhibitorDescription: 'Inscrivez votre exposant à cet événement.',
  manageVisitor: 'Gérer un visiteur',
  manageVisitorDescription: 'Modifier les informations relatives à un visiteur, voir les interactions.',
  manageExhibitor: 'Gérer un exposant',
  manageExhibitorDescription: 'Modifier les informations relatives à un exposant, voir les interactions.',
  manageEvent: 'Gérer un événement',
  manageEventDescription: "Accéder aux paramètres, aux informations et au contenu de l'événement.",
};

export default events;
