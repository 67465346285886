import { ActionContext } from 'vuex';
import {
  addInteractionsExport,
  deleteInteractionsExport,
  editInteraction,
  editInteractionsExport,
  fetchInteractions,
  fetchInteractionsByVisitor,
  fetchInteractionsExports,
} from '@/api/interactions';
import { IInteraction, IInteractionsExport } from '@/models/interaction';

import { IState, IObjectState, IStateObject } from '..';
import { IParams } from '@/api/index';

export interface IStateInteraction extends IInteraction, IObjectState { }

export interface IStateInteractionsExport extends IInteractionsExport, IObjectState { }

export interface IInteractionsState extends IStateObject<IStateInteraction> {
  exports: IInteractionsExportState;
}

export type IInteractionsExportState = IStateObject<IStateInteractionsExport>

export interface IFetchInteractionsParams extends IParams {
  exhibitor: number;
  visitor?: number;
  search?: string;
}

const state: IInteractionsState = {
  list: [],
  selected: [],
  page: 1,
  page_count: 0,
  item_count: 0,
  exports: {
    list: [],
    selected: [],
    loading: false,
    message: '',
    error: false,
  },
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (items: (IInteraction | IInteractionsExport)[]) => items.map((item) => ({
  ...item,
  loading: false,
  success: false,
  error: false,
  message: '',
}));

const getters = {
  getInteractions: (state: IInteractionsState) => state.list,
  getInteractionsExports: (state: IInteractionsState) => state.exports.list,
  getInteractionsExportsState: (state: IInteractionsState) => state.exports,
  getInteractionsState: (state: IInteractionsState) => state,
};

const actions = {
  fetchInteractions(context: ActionContext<IInteractionsState, IState>, params: IFetchInteractionsParams) {
    context.commit('setInteractionsLoading');
    fetchInteractions(params)
      .then((response) => {
        const interactions = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        context.commit('setInteractions', interactions);
      })
      .catch((err) => {
        context.commit('setInteractionsError', err.message);
      });
  },
  fetchInteractionsByVisitor(context: ActionContext<IInteractionsState, IState>, visitor: number) {
    context.commit('setInteractionsLoading');
    fetchInteractionsByVisitor(visitor)
      .then((response) => {
        const interactions = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };
        context.commit('setInteractions', interactions);
      })
      .catch((err) => {
        context.commit('setInteractionsError', err.message);
      });
  },

  fetchInteractionsExports(context: ActionContext<IInteractionsState, IState>, exhibitor: number) {
    context.commit('setInteractionsExportsLoading');

    fetchInteractionsExports(exhibitor)
      .then((response) => {
        context.commit('setInteractionsExports', addStateProps(response.data.results));
      })
      .catch((err) => {
        context.commit('setInteractionsExportsError', err.message);
      });
  },

  addInteractionsExport(context: ActionContext<IInteractionsState, IState>, interactionsExport: IInteractionsExport) {
    // context.commit('setInteractionsExportsLoading');
    addInteractionsExport(interactionsExport)
      .then((response) => {
        context.commit('newInteractionsExports', addStateProps([response.data])[0]);
      })
      .catch((err) => {
        context.commit('interactionsExportsError', err.message);
      });
  },

  editInteraction(context: ActionContext<IInteractionsState, IState>, editedInteraction: IStateInteraction) {
    context.commit(
      'setLoadingInteraction',
      context.state.list.find((interaction) => editedInteraction.id === interaction.id),
    );
    editInteraction(editedInteraction)
      .then(() => {
        context.commit('editInteraction', editedInteraction);
      })
      .catch((err) => {
        context.commit('newInteraction', err.message);
      });
  },

  editInteractionsExport(
    context: ActionContext<IInteractionsState, IState>,
    interactionsExport: IStateInteractionsExport,
  ) {
    context.commit(
      'setLoadingInteractionsExport',
      context.state.exports.list.find((interaction) => interactionsExport.id === interaction.id),
    );

    editInteractionsExport(interactionsExport)
      .then((response) => {
        context.commit('editInteractionsExport', addStateProps([response.data])[0]);
      })
      .catch((err) => {
        context.commit('setInteractionsExportError', {
          interactionsExport: context.state.exports.list.find(
            (interaction) => interactionsExport.id === interaction.id,
          ),
          message: err.message,
        });
      });
  },

  deleteInteraction(context: ActionContext<IInteractionsState, IState>, interaction: IStateInteraction) {
    context.commit('setLoadingInteraction', interaction);

    setTimeout(() => {
      context.commit('removeInteraction', interaction);
    }, 2000);

    // axios
    //   .delete(`https://b.beamian.com/api/v2/interactions/${interaction.slug}/`)
    //   .then(response => {
    //     context.commit('deleteInteraction', interaction);
    //   })
    //   .catch(err => { });
  },

  deleteInteractionsExport(
    context: ActionContext<IInteractionsState, IState>,
    interactionsExport: IStateInteractionsExport,
  ) {
    context.commit(
      'setLoadingInteractionsExport',
      context.state.exports.list.find((interaction) => interactionsExport.id === interaction.id),
    );

    deleteInteractionsExport(interactionsExport)
      .then(() => {
        context.commit('removeInteractionsExport', interactionsExport);
      })
      .catch((err) => {
        context.commit('setInteractionsExportError', {
          interactionsExport: context.state.exports.list.find(
            (interaction) => interactionsExport.id === interaction.id,
          ),
          message: err.message,
        });
      });
  },
};

const mutations = {

  setInteractions: (state: IInteractionsState, interactions: IInteractionsState) => {
    state.loading = false;
    state.list = interactions.list;
    state.page = interactions.page;
    state.page_count = interactions.page_count;
    state.item_count = interactions.item_count;
  },

  resetInteractions: (state: IInteractionsState) => {
    state.loading = false;
    state.error = false;
    state.message = '';
  },

  setInteractionsError: (state: IInteractionsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setInteractionsExportError: (
    state: IInteractionsState,
    interactionsExportError: { interactionsExport: IStateInteractionsExport; message: string },
  ) => {
    const newList = [...state.exports.list];

    newList[newList.indexOf(interactionsExportError.interactionsExport)] = {
      ...state.exports.list[state.exports.list.indexOf(interactionsExportError.interactionsExport)],
      loading: false,
      error: true,
      message: interactionsExportError.message,
    };

    state.exports.list = newList;
  },

  setInteractionsLoading: (state: IInteractionsState) => {
    state.loading = true;
    state.error = false;
  },

  setInteractionsExportsLoading: (state: IInteractionsState) => {
    state.exports.loading = true;
    state.exports.error = false;
  },

  setInteractionsExportsError: (state: IInteractionsState, message: string) => {
    state.exports.message = message;
    state.exports.error = true;
  },

  setLoadingInteraction: (state: IInteractionsState, interaction: IStateInteraction) => {
    state.list[state.list.indexOf(interaction)] = {
      ...state.list[state.list.indexOf(interaction)],
      loading: true,
      error: false,
    };
    state.error = false;
    state.loading = true;
  },

  setLoadingInteractionsExport: (state: IInteractionsState, interactionsExport: IStateInteractionsExport) => {
    const newList = [...state.exports.list];

    newList[newList.indexOf(interactionsExport)] = {
      ...state.exports.list[state.exports.list.indexOf(interactionsExport)],
      loading: true,
      error: false,
    };

    state.exports.list = newList;
  },
  setInteractionsExports: (state: IInteractionsState, interactionsExports: IStateInteractionsExport[]) => {
    state.exports.loading = false;
    state.exports.list = interactionsExports;
  },

  editInteraction: (state: IInteractionsState, editedInteraction: IStateInteraction) => {
    state.list = state.list.map((interaction) => (interaction.id === editedInteraction.id
      ? {
        ...editedInteraction,
        loading: false,
        error: false,
      }
      : interaction));
    state.loading = false;
  },

  editInteractionsExport: (state: IInteractionsState, interactionsExport: IStateInteractionsExport) => {
    state.exports.list = state.exports.list.map((interaction) => (interaction.id === interactionsExport.id
      ? {
        ...interactionsExport,
        loading: false,
        error: false,
      }
      : interaction));
  },

  newInteraction: (state: IInteractionsState, interaction: IStateInteraction) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(interaction);
  },

  newInteractionsExports: (state: IInteractionsState, interactionsExport: IStateInteractionsExport) => {
    state.exports.loading = false;
    state.exports.error = false;
    state.exports.list.unshift(interactionsExport);
  },

  removeInteraction: (state: IInteractionsState, deletedInteraction: IStateInteraction) => {
    state.list = state.list.filter((interaction) => interaction.id !== deletedInteraction.id);
  },

  removeInteractionsExport: (state: IInteractionsState, deletedInteraction: IStateInteractionsExport) => {
    state.exports.list = state.exports.list.filter((interaction) => interaction.id !== deletedInteraction.id);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
