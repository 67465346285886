import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  dashboard: 'Dashboard',
  events: 'Events',
  devices: 'Devices',
  exhibitors: 'Exhibitors',
  exhibitor: 'Exhibitor',
  logout: 'Logout',
  edit: 'Edit',
  create: 'Create',
  delete: 'Delete',
  name: 'Name',
  name_placeholder: 'Enter your name',
  email: 'Email',
  description: 'Description',
  category: 'Category',
  model: 'Model',
  save: 'Save',
  download: 'Download',
  upload: 'Upload',
  change: 'Change',
  apply: 'Apply',
  emptyFieldNotAllowed: 'This field must not be empty',
  next: 'Next',
  previous: 'Previous',
  close: 'Close',
  cancel: 'Cancel',
  confirm: 'Confirm',
  search: 'Search',
  mandatory_field: 'This field is mandatory.',
  name_in_use: 'Name already taken.',
  crop: 'Crop',
  published: 'Published',
  not_published: 'Unpublished',
  all: 'All',
  empty_list: 'No data available',
  user_chat_failed: 'Could not open chat with this user',
  activity_center: 'Activity center',
  no_recent_activity: 'No recent activity',
  unread_messages: 'Unread messages',
  reply: 'Reply',
  session_invitations: 'Pending invitations',
  accept: 'Accept',
  refuse: 'Refuse',
  in_progress: 'In progress',
  recent_files: 'Recent files',
  open_conversations: 'Open conversations',
  all_fields_required: 'All fields required',
  something_went_wrong: 'Something went wrong',
  already_exists: 'Already exists',
  profile: 'My account',
  filter_by_status: 'Filter by status',
  open_meeting: 'Open meeting',
  visitors: 'Visitors',
  saved: 'Saved',
  back: 'Back',
  session_full: 'Full',
  file_not_image: 'This file is not an image',
  new_export: 'New export',
  state: 'State',
  details: 'Details',
  see_more: 'See more',
  manage: 'Manage',
  see_as_visitor: 'See as visitor',
  see_as_exhibitor: 'See as exhibitor',
  manage_visitors: 'Manage candidates',
  manage_exhibitors: 'Manage exhibitors',
  manage_event: 'Manage event',
  registered: 'registered',
  refused: 'refused',
  pending: 'pending',
  accepted: 'accepted',
};

export default common;
