import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions, IExhibitorInteractionsTranslations, IExhibitorSettingsTranslations, IExhibitorSidebar, IExhibitorsDashboardTranslations, IExhibitorProducsTranslations, IExhibitorProductInteractions, IExhibitorProfile,
} from '.';

const dashboard: IExhibitorsDashboardTranslations = {
  home: 'Control panel',
  product_interactions_description: 'Number of visitors interested in products:',
  information: 'Profile',
  name: 'Exhibitor name:',
  edit: 'Edit',
  interactions: 'Visitors interested in the exhibitor',
  nrOfInteractions: 'Number of visitors interested in the exhibitor:',
  seeAll: 'See contacts',
  download: 'Download',
  sessions: 'Sessions',
  upcoming_sessions: 'Upcoming sessions',
  see_all_sessions: 'See all sessions',
  applications: 'Visitors interested in products',
  see_applications: 'See contacts',
  choose_exhibitor: 'Choose the exhibitor you wish to manage',
  schedule_meetings: 'Scheduled meetings',
  check_scheduled_meetings: 'Check the meetings visitors already scheduled',
  exhibitor_pending_title: 'Exhibitor is pending aproval',
  exhibitor_pending_text: 'Your exhibitor was not aproved for entering the platform. Please wait until the event manager aproves your exhibitor',
};

const sidebar: IExhibitorSidebar = {
  title: 'Exhibiting',
  home: 'Control panel',
  exhibitor_interactions: 'Interested in the exhibitor',
  exhibitor_sessions: 'My calendar',
  interactions: 'Visitors',
  sessions: 'Calendar',
  settings: 'Profile',
  products: 'Products',
  product_interactions: 'Interested in products',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Exhibitor settings',
  basicInfo: 'Basic information',
  save: 'Save',
  additionalInfo: 'Additional information',
  formError: 'No information retrieved, form not available.',
};

const products: IExhibitorProducsTranslations = {
  title: 'Products',
  subtitle: 'Create, edit and delete products',
  no_products: 'No products available',
  new_product: 'New product',
  create_new_product: 'Create',
  edit_product: 'Edit product',
  delete_confirm: 'Are you sure you want to delete this product?',
  interactions_exist_cannot_delete: 'Already has interactions. Cannot be deleted.',
  search_placeholder: ' Type what you wish to search on the products list',
  inputs: {
    name: 'Name',
  },
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Visitors interested in the exhibitor',
  subtitle: 'Check the list of visitors interested in the exhibitor',
  download: 'Download',
  noInteractions: 'This exhibitor has no interactions.',
  interaction: {
    editComment: 'Edit comment',
    saveComment: 'Save comment',
    checkIn: 'CheckIn',
  },
  search_placeholder: 'Type what you wish to search on the contact list',
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Authentication',
  step_basicInfo: 'Basic information',
  step_additionalInfo: 'Additional information',
  step_review: 'Review',
  step_done: 'Done!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Profile picture',
    embedded: 'Youtube video',
    meeting_room: 'Enable virtual meeting room',
  },
  login: {
    title: "You're about to register your exhibitor",
    subtitle: 'Sign up for event or register someone else',
    message: 'To register your exhibitor we need you to login or register a new beamian account.',
    exhibitor_manager_name: 'Insert the exhibitor manager name',
    exhibitor_manager_email: "Insert the exhibitor manager's email",
  },
  basicInformation: {
    title: 'Tell us about your exhibitor',
    warning: 'The information you provide will be used for the creation of the booklet as well as to create a profile for visitors to see.',
    createExhibitor: 'Create exhibitor',
    exhibitor_name: 'Exhibitor name',
    exhibitor_name_placeholder: 'Insert the name of your exhibitor (company)',
    modal: {
      title: 'Warning',
      subtitle: 'If you wish to manage your exhibitor please login first.',
      skip: 'Skip',
      createAccount: 'Create account',
      login: 'Login',
    },
  },
  managedFields: {
    title: 'Additional information',
    crop_image: 'Crop image',
    crop_again: 'Back to crop',
  },
  review: {
    title: 'Review information',
    name: 'Name',
  },
  completed: {
    title: 'Exhibitor created!',
    subtitle: 'Your exhibitor is ready. Thank you for your submission!',
    manage: 'Manage',
  },
  back: 'Back',
  next: 'Next',
  submit: 'Submit',
};

const sessions: IExhibitorSessions = {
  title: 'Sessions',
  subtitle: 'Create, edit and delete sessions',
  start_date: 'Starts at:',
  end_date: 'Ends at:',
  join_meeting: 'Join session',
  meeting_url: 'Meeting URL:',
  description: 'Description:',
  new_session: 'New session',
  edit_session: 'Edit session',
  register: 'Register',
  invited_users: 'Invited',
  inputs: {
    name: 'Name',
    name_placeholder: 'Enter session name',
    start_date: 'Start date',
    start_date_placeholder: 'Enter session start date',
    end_date: 'End date',
    end_date_placeholder: 'Enter session end date',
    color: 'Color',
    color_placeholder: 'Enter session color',
    description: 'Description',
    description_placeholder: 'Enter session description',
    entrance_capacity: 'Entrance capacity',
    entrance_capacity_placeholder: 'Enter entrance capacity',
    registration_capacity: 'Registration capacity',
    registration_capacity_placeholder: 'Enter registration capacity',
    external_link: 'Meeting URL',
    external_link_placeholder: 'Enter meeting URL',
    external_link_warning_message: 'Leave this field blank if you want beamian to create a meeting room in your behalf.',
    order: 'Order',
    order_placeholder: 'Enter session order',
    private_session: 'Private session',
  },
  session_label: 'Session',
  select_session: 'Select session',
  no_private_sessions: 'There are no exhibitor sessions',
  private_session_warning: "Create a private session to invite specific visitors to a meeting. If it 's a public session, any visitor will be able to see and register for that session.",
  enable_chat: 'Enable chat',
  embed_meeting: 'Embed meeting video',
  embed_meeting_detail: 'If you uncheck this option the meeting link will open in a new tab',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Visitors interested in products',
  subtitle: 'Check the list of visitors interested in your products and schedule meetings',
  product_name: 'Product',
  interaction_name: 'Name of the visitor',
  application: 'Contact information',
  invite_to_session: 'Schedule a meeting',
  send_invite: 'Send invite',
  user_already_registered: 'This user is already registered on this session',
  publish_warning: 'By publishing this form the information will be visible to visitors',
  invitation_sent: 'Invitation sent successfully',
  search_placeholder: 'Type what you wish to search on the contact list',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Exhibitor information',
  products: 'Products',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
