import auth from './auth/es';
import common from './common/es';
import dashboard from './dashboard/es';
import table from './table/es';
import account from './account/es';
import events from './events/es';
import exhibitors from './exhibitors/es';
import visitors from './visitors/es';
import root from './root/es';

export default {
  auth, common, dashboard, table, account, events, exhibitors, visitors, root,
};
