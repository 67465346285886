import auth from './auth/pt';
import common from './common/pt';
import dashboard from './dashboard/pt';
import table from './table/pt';
import account from './account/pt';
import events from './events/pt';
import exhibitors from './exhibitors/pt';
import visitors from './visitors/pt';
import root from './root/pt';

export default {
  auth, common, dashboard, table, account, events, exhibitors, visitors, root,
};
