import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'Home',
    component: () => import('@/views/Home.vue'),

  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/Events.vue'),
  },
  {
    path: '/event/:event',
    name: 'Event',
    component: () => import('@/views/Event/Event.vue'),
    children: [
      {
        path: '',
        name: 'EventHome',
        component: () => import('@/views/Event/Home.vue'),
        children: [{
          path: 'session-details/:session',
          name: 'EventHomeSessionDetails',
          component: () => import('@/views/Sessions/Actions/Details.vue'),
        }],
      },
      {
        path: 'pending',
        name: 'NotAllowed',
        component: () => import('@/views/Event/NotAllowed.vue'),
      },
      {
        path: 'profile/:visitor',
        name: 'VisitorNetworkingProfile',
        component: () => import('@/views/Visitors/Profile.vue'),
        children: [{
          path: 'edit',
          name: 'VisitorForm',
          component: () => import('@/views/VisitorForm/VisitorForm.vue'),
        }]
      },
      {
        path: 'exhibitors',
        name: 'VisitorExhibitors',
        component: () => import('@/views/Exhibitors/Exhibitors.vue'),
      },
      {
        path: 'exhibitor/:exhibitor',
        component: () => import('@/views/Exhibitors/Actions/Exhibitor.vue'),
        name: 'VisitorExhibitor',
        children: [
          {
            path: 'session/:session',
            name: 'VisitorExhibitorSession',
            component: () => import('@/views/Sessions/Actions/Details.vue'),
          },
          {
            path: 'vote',
            name: 'VisitorExhibitorVote',
            component: () => import('@/views/Exhibitors/Actions/Vote.vue'),
          },
        ],
      },
      {
        path: 'products',
        name: 'VisitorProducts',
        component: () => import('@/views/Products/Products.vue'),
      },
      {
        path: 'product/:product',
        name: 'VisitorProduct',
        component: () => import('@/views/Products/Product.vue'),
        children: [
          {
            path: 'detail',
            name: 'VisitorProductDetails',
            component: () => import('@/views/Products/Actions/Detail.vue'),
          },
        ],
      },
      {
        path: 'trend/:trend',
        component: () => import('@/views/Trends/Trend.vue'),
        name: 'VisitorTrend',
      },
      {
        path: 'sessions',
        name: 'VisitorSessions',
        component: () => import('@/views/Sessions/Sessions.vue'),
        children: [
          {
            path: 'session/:session',
            component: () => import('@/views/Sessions/Actions/Details.vue'),
          },
        ],
      },
      {
        path: 'session/:session',
        name: 'VisitorSession',
        component: () => import('@/views/Sessions/Session.vue'),
      },
      {
        path: 'networking',
        name: 'VisitorNetworking',
        component: () => import('@/views/Networking/Networking.vue'),
      },

      {
        path: 'roles',
        name: 'ManagerRoles',
        component: () => import('@/views/Event/Roles.vue'),
      },
      {
        path: 'new-exhibitor',
        name: 'ExhibitorOnboarding',
        component: () => import('@/views/ExhibitorOnboarding/ExhibitorOnboarding.vue'),
      },
      {
        path: 'new-visitor',
        name: 'VisitorOnboarding',
        component: () => import('@/views/VisitorOnboarding/VisitorOnboarding.vue'),
      },
      {
        path: 'manage-exhibitors',
        name: 'ManageExhibitors',
        component: () => import('@/views/ExhibitorManager/Exhibitors.vue'),
      },
      {
        path: 'manage-exhibitor/:exhibitor',
        name: 'ManageExhibitor',
        component: () => import('@/views/ExhibitorManager/Exhibitor.vue'),
        children: [
          {
            path: '',
            name: 'ManageExhibitorDashboard',
            component: () => import('@/views/ExhibitorManager/Dashboard.vue'),

          },
          {
            path: 'pending',
            name: 'ManageNotPublished',
            component: () => import('@/views/ExhibitorManager/NotPublished.vue'),
          },
          {
            path: 'visitors',
            name: 'ManageExhibitorVisitors',
            component: () => import('@/views/ExhibitorManager/VisitorContacts/VisitorContacts.vue'),
            children: [
              {
                path: 'assign',
                name: 'ManageExhibitorVisitorsAssign',
                component: () => import('@/views/ExhibitorManager/VisitorContacts/Actions/Assign.vue'),
              }
            ],
          },
          { path: 'interactions', redirect: 'visitors' },
          { path: 'product-interactions', redirect: 'visitors' },
          {
            path: 'exports',
            name: 'ManageExhibitorExports',
            component: () => import('@/views/ExhibitorManager/Exports/Exports.vue'),
          },
          {
            path: 'product/:product',
            name: 'ManageExhibitorProduct',
            component: () => import('@/views/Products/Product.vue'),
            children: [
              {
                path: 'edit',
                name: 'ManageExhibitorEditProduct',
                component: () => import('@/views/ExhibitorManager/Products/Actions/EditProduct.vue'),
              },
            ],
          },
          {
            path: 'products',
            name: 'ManageExhibitorProducts',
            component: () => import('@/views/ExhibitorManager/Products/Products.vue'),
            children: [
              {
                path: 'new',
                name: 'ManageExhibitorNewProduct',
                component: () => import('@/views/ExhibitorManager/Products/Actions/NewProduct.vue'),
              },
            ],
          },
          {
            path: 'sessions',
            name: 'ManageExhibitorSessions',
            component: () => import('@/views/ExhibitorManager/Sessions/Sessions.vue'),
            children: [
              {
                path: 'new-session',
                name: 'ManageExhibitorNewSession',
                component: () => import('@/views/ExhibitorManager/Sessions/Actions/NewSession.vue'),
              },
              {
                path: 'details/:session',
                name: 'ManageExhibitorSessionDetails',
                component: () => import('@/views/ExhibitorManager/Sessions/Actions/Details.vue'),
              },
              {
                path: 'edit/:session',
                name: 'ManageExhibitorEditSession',
                component: () => import('@/views/ExhibitorManager/Sessions/Actions/EditSession.vue'),
              },
            ],
          },

          {
            path: 'session/:session',
            name: 'ManageExhibitorSession',
            component: () => import('@/views/ExhibitorManager/Session.vue'),
          },

          {
            path: 'profile',
            name: 'ManageExhibitorProfile',
            component: () => import('@/views/Exhibitors/Actions/Exhibitor.vue'),
            children: [{
              path: 'qrcode',
              name: 'ManageExhibitorQRCode',
              component: () => import('@/views/ExhibitorManager/QRCode.vue'),
            },
            {
              path: 'edit',
              name: 'ManageExhibitorForm',
              component: () => import('@/views/ExhibitorManager/Form/Form.vue'),
            },],
          },
          {
            path: 'exhibitor/:exhibitor',
            component: () => import('@/views/Exhibitors/Actions/Exhibitor.vue'),
            name: 'ManageExhibitorExhibitor',
            children: [
              {
                path: 'session/:session',
                name: 'ManageExhibitorExhibitorSession',
                component: () => import('@/views/Sessions/Actions/Details.vue'),
              },

              {
                path: 'vote',
                name: 'ManageExhibitorExhibitorVote',
                component: () => import('@/views/Exhibitors/Actions/Vote.vue'),
              },
            ],
          },
          {
            path: 'visitor/:visitor',
            name: 'ManageExhibitorVisitorProfile',
            component: () => import('@/views/ExhibitorManager/VisitorProfile.vue'),
            children: [{
              path: 'invite',
              name: 'ManageExhibitorVisitorsInvite',
              component: () => import('@/views/ExhibitorManager/VisitorContacts/Actions/Invite.vue'),
            }]
          },

        ],
      },
      {
        path: 'manage-visitors',
        name: 'ManageVisitors',
        component: () => import('@/views/Visitors/ManageVisitors.vue'),
      },
    ],
  },

  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: () => import('@/views/UserAccount/UserAccount.vue'),
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/setup',
    name: 'Setup',
    component: () => import('@/views/Setup.vue'),
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: process.env.VUE_APP_ROUTER_MODE, // this needs to change depending on whether it's a web app or native app
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
});

export default router;
