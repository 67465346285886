




























































import { defineComponent, reactive, PropType } from '@vue/composition-api';
import { ISidebar } from '@/components';
import Loading from '@/components/loading.vue';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ISidebar>,
      required: true,
    },
    value: String,
  },
  components: { Loading },
  setup() {
    const state = reactive({ isActive: [false, false, false, false, false, false] });

    const toggleMenu = (i: number) => {
      state.isActive = state.isActive.map((val, index) => (index === i ? !val : val));
    };

    return { state, toggleMenu };
  },
});
