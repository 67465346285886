import { ActionContext } from 'vuex';
import { IExhibitor } from '@/models/exhibitor';

import { getExhibitors, getExhibitorsByVisitorId } from '@/api/exhibitors';
import { IState, IObjectState, IStateObject } from '..';
import { IParams } from '../../api/index';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateExhibitor extends IObjectState, IExhibitor { }

export type IExhibitorsState = IStateObject<IStateExhibitor>;

const state: IExhibitorsState = {
  list: [],
  selected: [],
  page: 1,
  page_count: 0,
  item_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (exhibitors: IExhibitor[]) => exhibitors.map((exhibitor) => ({
  ...exhibitor,
  loading: false,
  error: false,
  message: '',
}));

const getters = {
  getExhibitorsState: (state: IExhibitorsState) => state,
  getExhibitors: (state: IExhibitorsState) => state.list,
  getSelectedExhibitors: (state: IExhibitorsState) => state.selected,
};

export interface IExhibitorsParams extends IParams {
  event?: number;
  name?: string;
  search?: string;
  notvisitor?: number;
  visitor?: number;
  hidden?: boolean;
  category?: number;
  status?: string;
  sponsor_level?: 'ds' | 't1' | 't2';
  home_page?: boolean;
  favorite?: boolean;
}

const actions = {
  fetchExhibitors(context: ActionContext<IExhibitorsState, IState>, params: IExhibitorsParams) {
    context.commit('setExhibitorsLoading');

    getExhibitors(params)
      .then((response) => {
        const exhibitors = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        if (params.reset) {
          context.commit('resetExhibitors');
        }

        context.commit('setExhibitors', exhibitors);
      })
      .catch((err) => {
        context.commit('setExhibitorsError', err.request);
      });
  },
  fetchExhibitorsByVisitorId(context: ActionContext<IExhibitorsState, IState>, visitor: number) {
    context.commit('setExhibitorsLoading');

    getExhibitorsByVisitorId(visitor)
      .then((response) => {
        context.commit('setExhibitors', addStateProps(response.data.results));
      })
      .catch((err) => {
        context.commit('setExhibitorsError', err.request);
      });
  },

  addExhibitor(context: ActionContext<IExhibitorsState, IState>, exhibitor: IExhibitor) {
    context.commit('setExhibitorsLoading');
    console.log(exhibitor);
    // addExhibitor(exhibitor)
    //     .then((response) => {
    //         context.commit('newExhibitor', addStateProps([response.data])[0]);
    //     })
    //     .catch((err) => {
    //         context.commit('setExhibitorsError', err.response.data);
    //     });
  },

  editExhibitor(context: ActionContext<IExhibitorsState, IState>, editedExhibitor: IStateExhibitor) {
    context.commit(
      'setLoadingExhibitor',
      context.state.list.find((exhibitor) => editedExhibitor.id === exhibitor.id),
    );

    // editExhibitor(editedExhibitor)
    //     .then((response) => {
    //         context.commit('editExhibitor', response);
    //     })
    //     .catch((err) => {
    //         context.commit('setExhibitorError', { failedExhibitor: editedExhibitor, message: err.message });
    //     });
  },

  deleteExhibitor(context: ActionContext<IExhibitorsState, IState>, deletedExhibitor: IStateExhibitor) {
    context.commit('setLoadingExhibitor', deletedExhibitor);
  },

  deleteExhibitors(context: ActionContext<IExhibitorsState, IState>, ids: number[]) {
    context.commit('setLoadingExhibitors', ids);

    // axios
    //   .delete(`${url}/exhibitor/bulk_destroy?ids=${ids.join('&ids=')}`, config())
    //   .then(response => {
    //     context.commit('removeExhibitors', ids);
    //   })
    //   .catch(err => {
    //     context.commit('setExhibitorsError', { ids, message: err.message });
    //   });
  },
};

const mutations = {
  setExhibitorsError: (state: IExhibitorsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setExhibitorError: (state: IExhibitorsState, { failedExhibitor, message }: { failedExhibitor: IStateExhibitor; message: string }) => {
    state.list = state.list.map((exhibitor) => (exhibitor.id === failedExhibitor.id
      ? {
        ...exhibitor,
        loading: false,
        error: true,
        message,
      }
      : exhibitor));
  },

  setExhibitorsLoading: (state: IExhibitorsState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingExhibitor: (state: IExhibitorsState, exhibitor: IStateExhibitor) => {
    state.list[state.list.indexOf(exhibitor)] = {
      ...state.list[state.list.indexOf(exhibitor)],
      loading: true,
      error: false,
    };
  },

  setExhibitors: (state: IExhibitorsState, exhibitors: IExhibitorsState) => {
    state.error = false;
    state.loading = false;
    state.list = [...state.list, ...exhibitors.list];

    state.page = exhibitors.page;
    state.page_count = exhibitors.page_count;
    state.item_count = exhibitors.item_count;
  },

  resetExhibitors: (state: IExhibitorsState) => {
    state.error = false;
    state.loading = false;
    state.list = [];
    state.page = 0;
    state.page_count = 0;
    state.item_count = 0;
  },

  editExhibitor: (state: IExhibitorsState, editedExhibitor: IStateExhibitor) => {
    state.list = state.list.map((exhibitor) => (exhibitor.id === editedExhibitor.id
      ? {
        ...editedExhibitor,
        loading: false,
        error: false,
      }
      : exhibitor));
  },

  newExhibitor: (state: IExhibitorsState, exhibitor: IStateExhibitor) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(exhibitor);
  },

  removeExhibitor: (state: IExhibitorsState, deletedExhibitor: IExhibitor) => {
    state.list = state.list.filter((exhibitor) => exhibitor.id !== deletedExhibitor.id);
  },

  removeExhibitors: (state: IExhibitorsState, ids: number[]) => {
    state.list = state.list.filter((exhibitor) => exhibitor.id && ids.indexOf(exhibitor.id) === -1);
  },

  setLoadingExhibitors: (state: IExhibitorsState, ids: number[]) => {
    state.list = state.list.map((exhibitor) => ({
      ...exhibitor,
      loading: !!(exhibitor.id && ids.indexOf(exhibitor.id) > -1),
      error: false,
    }));
  },
  selectExhibitor: (state: IExhibitorsState, exhibitor: IStateExhibitor) => {
    if (exhibitor.id) {
      const exhibitorIndex = state.selected.indexOf(exhibitor.id);
      if (exhibitorIndex > -1) {
        state.selected.splice(exhibitorIndex, 1);
      } else {
        state.selected.push(exhibitor.id);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
