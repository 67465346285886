import { ITableTranslations } from '.';

const table: ITableTranslations = {
  showing: 'Présentation',
  of: 'des',
  items: 'articles',
  see: 'Voir',
  see_selected_only: 'Voir ce qui a été sélectionné uniquement',
  clear_selection: 'Effacer la sélection',
  all_items: 'Tous les articles',
  items_per_page: 'Objets par page :',
  previous: 'Précédent',
  next: 'Suivant',
  table_settings: 'Paramètres de table',
  search_settings: 'Paramètres de recherche',
  refresh_table: 'Actualiser le tableau',
  show_hide_columns: 'Afficher / masquer des colonnes',
  search_fields: 'Champs de recherche',
  search_data: 'Rechercher',
};

export default table;
