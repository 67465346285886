



















import { defineComponent, PropType } from '@vue/composition-api';

import { IPopup } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IPopup>,
      required: true,
    },
  },
  setup(props) {
    const close = () => {
      if (props.config.onClose) {
        props.config.onClose();
      }
    };
    return { close };
  },
});
