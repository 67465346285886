import {
  IVisitorOnboardingTranslations, IVisitorExhibitorTranslations, IVisitorSettingsTranslations, IVisitorSidebar, IVisitorsDashboardTranslations, IVisitorNetworkingTranslations, IVisitorExhibitorSessions, IVisitorSessions, IVisitorProfile,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Início',
  information: 'Perfil',
  networking: 'Networking',
  networking_description: 'Falar com outros visitantes',
  sessions: 'Agenda',
  sessions_description: 'Número de sessões na agenda do evento:',
  sessions_btn: 'Ver agenda do evento',
  name: 'Nome de visitante:',
  edit: 'Alterar',
  exhibitors: 'Grupos de trabalho',
  nrOfInteractions: 'Número de contactos partilhados:',
  seeAll: 'Ver grupos de trabalho',
  download: 'Descarregar',
  join_networking: 'Entrar no espaço de networking',
  event_finished: 'Este evento já terminou',
  event_not_start: 'Este evento ainda não começou',
};

const sidebar: IVisitorSidebar = {
  title: 'Visitante',
  home: 'Início',
  exhibitors: 'Grupos de trabalho',
  settings: 'Perfil',
  sessions: 'Agenda',
  exhibitor_sessions: 'Agenda pessoal',
  event_sessions: 'Agenda do evento',
  myCalendar: 'My calendar',
  eventCalendar: 'Event calendar',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Definições de visitante',
  basicInfo: 'Informação básica',
  save: 'Guardar',
  additionalInfo: 'Informação adicional',
  formError: 'Sem informação para mostrar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Grupos de trabalho',
  subtitle: 'Ver Grupos de trabalho de consultar áreas de interesse',
  download: 'Descarregar',
  noExhibitors: 'Sem expositores para mostrar.',
  exhibitor_information: 'Informação de expositor',
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  all_exhibitors: 'Todos os grupos de trabalho',
  interacted_exhibitors: 'Grupos de trabalho com que interagi',
  not_interacted_exhibitors: 'Grupos de trabalho com que não interagi',
  filter_exhibitors: 'Filtrar grupos de trabalho',
  contact_request: 'Partilhar o meu contacto',
  company_info: 'Informação',
  job_offers: 'Áreas de interesse',
  apply_to_job_offer: 'Saber mais',
  job_offer_info: 'Informação sobre área de interesse',
  cover_letter: 'Motivo do interesse',
  cover_letter_text: 'Deixe uma mensagem ao grupo de trabalho',
  your_info_will_be_shared: 'A sua informação de registo será partilhada com o grupo de trabalho',
  submit_my_application: 'Submeter',
  successfully_submited: 'Efetuado com sucesso',
  already_applied: 'Já efetuou este registo de interesse',
  schedule_interview: 'Reserve o seu lugar',
  select_time_slot: 'Selecione um horário para participar',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Dados de visitante',
    createVisitor: 'Registar como visitante',
    modal: {
      title: 'Aviso',
      subtitle: 'Se quiser aceder ao evento após o registo terá que iniciar sessão primeiro.',
      skip: 'Saltar',
      createAccount: 'Criar conta',
      login: 'Iniciar sessão',
    },
  },
  managedFields: {
    title: 'Informação adicional',
  },
  review: {
    title: 'Rever informação',
    name: 'Nome',
  },
  completed: {
    title: 'Visitante registado',
    subtitle: 'Utilizador registado como visitante. Obrigado!',
    manage: 'Gerir',
  },
  back: 'Voltar',
  next: 'Próximo',
  submit: 'Submeter',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Fale com outros visitantes',
  chat: 'Chat',
  profile: 'Perfil',
  signup_title: 'Pretende falar com outros visitantes?',
  signup_message: 'Está prestes a entrar na área de networking. A sua informação ficará disponível para outros visitantes.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Registe-se para participar nas sessões do evento',
  event_sessions: 'Sessões do evento',
  exhibitor_sessions: 'Sessões de expositores',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessões em que está inscrito/a',
};

const profile: IVisitorProfile = {
  visitor_information: 'Informação do visitante',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile,
};
