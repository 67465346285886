import {
  IVisitorOnboardingTranslations,
  IVisitorExhibitorTranslations, IVisitorSettingsTranslations, IVisitorSidebar, IVisitorsDashboardTranslations, IVisitorNetworkingTranslations, IVisitorExhibitorSessions, IVisitorSessions, IVisitorProfile, IVisitorProducts,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Accueil',
  information: 'Profil',
  networking: 'Réseautage',
  networking_description: "Rencontrer d'autres visiteurs",
  sessions: 'Calendrier',
  sessions_description: 'Nombre de séances dans le calendrier des événements :',
  sessions_btn: 'Calendrier des événements',
  name: 'Nom du visiteur :',
  edit: 'Éditer',
  exhibitors: 'Exposants',
  nrOfInteractions: "Nombre de fois où j'ai partagé mes contacts :",
  seeAll: 'Voir les exposants',
  download: 'Télécharger',
  join_networking: 'Rejoindre la zone de réseautage',
  event_finished: "L'événement est terminé",
  event_not_start: "L'événement n'a pas encore commencé",
  featured_products: 'Featured products',
  featured_sessions: 'Featured sessions',
  featured_exhibitors: 'Featured brands',
  upcoming_exhibitors: 'Next sessions',
};

const sidebar: IVisitorSidebar = {
  title: 'Visite',
  home: 'Accueil',
  exhibitors: 'Exposants',
  settings: 'Profil',
  sessions: 'Calendrier',
  exhibitor_sessions: 'Mon agenda',
  event_sessions: 'Calendrier des événements',
  myCalendar: 'Mon agenda',
  eventCalendar: 'Calendrier des événements',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Paramètres du visiteur',
  basicInfo: 'Informations de base',
  save: 'Sauvegarder',
  additionalInfo: 'Informations complémentaires',
  formError: 'Aucune information trouvée, formulaire non disponible.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Exposants',
  subtitle: "Voir les exposants et consulter leurs offres d'emploi",
  download: 'Télécharger',
  noExhibitors: 'Aucun exposant à afficher.',
  exhibitor_information: 'Informations sur l’exposant',
  contact_success: 'Vos informations ont été partagées avec succès !',
  all_exhibitors: 'Tous les exposants',
  interacted_exhibitors: "Exposants avec lesquels j'ai interagi",
  not_interacted_exhibitors: "Exposants avec lesquels je n'ai pas interagi",
  filter_exhibitors: 'Filtrer les exposants',
  contact_request: 'Laisser mon contact',
  company_info: 'Informations sur l’exposant',
  job_offers: 'Offres',
  apply_to_job_offer: 'Je suis intéressé',
  job_offer_info: "Informations sur l'offre",
  cover_letter: 'Pourquoi êtes-vous intéressé ?',
  cover_letter_text: "Faites savoir à l'exposant pourquoi vous êtes intéressé et / ou posez-lui une question sur l'offre",
  your_info_will_be_shared: "Vos informations seront partagées avec l'exposant",
  submit_my_application: 'Soumettre',
  successfully_submited: 'Soumis avec succès ',
  already_applied: 'Vous avez déjà partagé votre intérêt pour cette offre.',
  schedule_interview: 'Réservez votre place',
  select_time_slot: 'Choisissez une plage horaire pour y accéder',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Parlez-nous de votre visiteur',
    createVisitor: 'Créer un visiteur',
    modal: {
      title: 'Attention',
      subtitle: "Si vous souhaitez gérer votre visiteur, veuillez d'abord vous connecter.",
      skip: 'Sauter',
      createAccount: 'Créer un compte',
      login: 'Connexion',
    },
    already_registered: '¡Ya estás registrado en este evento!',
    send_login_link: 'Haga clic aquí para recibir un correo electrónico con el link de inicio de sesión.',
  },
  tickets: {
    title: 'Usar código de bilhete',
    subtitle: 'Para registar neste evento precisa de um código de bilhete válido',
    message_head: "Não tem bilhete?",
    to_buy_tickets: 'para comprar bilhetes;',
    copy_ticket_code: "Copie o código que lhe enviaremos para o email e use-o para se registar.",
    ticket_code_invalid: 'El código de la entrada no es válido o ya se ha utilizado',
    ticket_code_label: 'Código de bilhete',
    ticket_code_placeholder: 'Insira o seu código de bilhete',
  },
  managedFields: {
    title: 'Informations complémentaires',
  },
  review: {
    title: 'Vérifier les informations',
    name: 'Nom',
  },
  completed: {
    title: 'Visiteur créé !',
    subtitle: 'Votre visiteur est prêt. Merci pour votre soumission !',
    manage: 'Gérer',
  },
  back: 'Retour',
  next: 'Suivant',
  submit: 'Soumettre',
  not_allowed_title: 'Su cuenta no ha sido aprobada',
  not_allowed_subtitle: 'Espere la aprobación del administrador del evento',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Réseautage',
  subtitle: 'Parler aux autres visiteurs',
  chat: 'Discussion',
  profile: 'Profil',
  signup_title: "Vous souhaitez rencontrer d'autres visiteurs ?",
  signup_message: 'Vous êtes sur le point de rejoindre la zone de réseautage. Vos informations seront disponibles pour les autres visiteurs.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Inscrivez-vous à une séance pour y accéder',
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
  all_sessions: 'All sessions',
  join_in_15: "Podrá unirse 15 minutos antes de que comience la sesión.",

};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Séances auxquelles vous êtes inscrit',
};

const profile: IVisitorProfile = {
  visitor_information: 'Informations sur le visiteur',
};

const products: IVisitorProducts = {
  title: 'Produits',
  subtitle: 'Parcourir et interagir avec les produits des exposants',
  products_same_brand: 'Offers from the same brand',
};

export default {
  dashboard, settings, exhibitors, onboarding, sidebar, networking, sessions, exhibitorSessions, profile, products,
};
