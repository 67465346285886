import { ActionContext } from 'vuex';
import { editUser, fetchUsers } from '@/api/users';
import { IUser } from '@/models/user';

import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateUser extends IUser, IObjectState { }
export type IUsersState = IStateObject<IStateUser>;

const state: IUsersState = {
  list: [],
  selected: [],
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (events: IUser[]) => events.map((event) => ({
  ...event,
  loading: false,
  success: false,
  error: false,
  message: '',
}));

const getters = {
  getUsers: (state: IUsersState) => state.list,
  getUsersState: (state: IUsersState) => ({
    loading: state.loading,
    error: state.error,
    message: state.message,
  }),
};

const actions = {
  fetchUsers(context: ActionContext<IUsersState, IState>) {
    context.commit('setUsersLoading');

    fetchUsers()
      .then((response) => {
        context.commit('setUsers', addStateProps(response.data.results));
      })
      .catch((err) => {
        context.commit('setUsersError', err.message);
      });
  },

  addUser(context: ActionContext<IUsersState, IState>, user: IUser) {
    context.commit('setUsersLoading');

    setTimeout(() => {
      context.commit('newUser', addStateProps([user])[0]);
    }, 2000);
    // axios
    //   .post('https://b.beamian.com/api/v2/users/', user)
    //   .then(response => {
    //     context.commit('newUser', response.data);
    //   })
    //   .catch(err => { });
  },

  editUser(context: ActionContext<IUsersState, IState>, editedUser: IStateUser) {
    context.commit(
      'setLoadingUser',
      context.state.list.find((user) => editedUser.id === user.id),
    );

    editUser(editedUser)
      .then(() => {
        context.commit('editUser', editedUser);
      })
      .catch((err) => {
        context.commit('setUsersError', err.message);
      });
  },

  deleteUser(context: ActionContext<IUsersState, IState>, user: IStateUser) {
    context.commit('setLoadingUser', user);

    setTimeout(() => {
      context.commit('removeUser', user);
    }, 2000);

    // axios
    //   .delete(`https://b.beamian.com/api/v2/users/${user.slug}/`)
    //   .then(response => {
    //     context.commit('deleteUser', user);
    //   })
    //   .catch(err => { });
  },
};

const mutations = {
  resetUsers: (state: IUsersState) => {
    state.loading = false;
    state.error = false;
    state.message = '';
  },

  setUsersError: (state: IUsersState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setUsersLoading: (state: IUsersState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingUser: (state: IUsersState, user: IStateUser) => {
    state.list[state.list.indexOf(user)] = {
      ...state.list[state.list.indexOf(user)],
      loading: true,
      error: false,
    };
    state.error = false;
  },

  setUsers: (state: IUsersState, users: IStateUser[]) => {
    state.loading = false;
    state.list = users;
  },

  editUser: (state: IUsersState, editedUser: IStateUser) => {
    state.list = state.list.map((user) => (user.id === editedUser.id
      ? {
        ...editedUser,
        loading: false,
        error: false,
      }
      : user));
  },

  newUser: (state: IUsersState, user: IStateUser) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(user);
  },

  removeUser: (state: IUsersState, deletedUser: IStateUser) => {
    state.list = state.list.filter((user) => user.id !== deletedUser.id);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
