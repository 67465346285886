import auth from './auth/fr';
import common from './common/fr';
import dashboard from './dashboard/fr';
import table from './table/fr';
import account from './account/fr';
import events from './events/fr';
import exhibitors from './exhibitors/fr';
import visitors from './visitors/fr';
import root from './root/fr';

export default {
  auth, common, dashboard, table, account, events, exhibitors, visitors, root,
};
