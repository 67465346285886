export interface IAppState {
  notificationsOpen: boolean;
  messagesOpen: boolean;
  loginPromptOpen: boolean;
  scannerOpen: boolean;
  changeContextOpen: boolean;
  helpCenterOpen: boolean;
  notifications: number;
}

const state: IAppState = {
  notificationsOpen: false,
  helpCenterOpen: false,
  messagesOpen: false,
  loginPromptOpen: false,
  scannerOpen: false,
  changeContextOpen: false,
  notifications: 0,
};

const getters = {
  getAppState: (state: IAppState) => state,
};

// const actions = {
// };

const mutations = {
  openLoginPrompt: (state: IAppState) => {
    state.loginPromptOpen = true;
  },
  openScanner: (state: IAppState) => {
    state.scannerOpen = true;
  },
  openNotifications: (state: IAppState) => {
    state.notificationsOpen = true;
  },
  openMessages: (state: IAppState) => {
    state.messagesOpen = true;
  },
  openHelpCenter: (state: IAppState) => {
    state.helpCenterOpen = true;
  },
  openEventContext: (state: IAppState) => {
    state.changeContextOpen = true;
  },
  closeNotifications: (state: IAppState) => {
    state.notificationsOpen = false;
  },
  closeChangeContext: (state: IAppState) => {
    state.changeContextOpen = true;
  },
  setPopups: (state: IAppState, notifications: number) => {
    state.notifications = notifications;
  },
  addPopups: (state: IAppState, notifications: number) => {
    state.notifications += notifications;
  },
  removeNotifications: (state: IAppState, notifications: number) => {
    state.notifications -= notifications;
  },
};

export default {
  state,
  getters,
  // actions,
  mutations,
};
