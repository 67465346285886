import { ActionContext } from 'vuex';
import { getVisitorContacts, getVisitors } from '@/api/visitors';
import { IVisitorContactsParams, IVisitorsParams, IVisitor } from '@/models/visitor';

import { IState, IObjectState, IStateObject } from '@/store';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateVisitor extends IObjectState, IVisitor { }

export type IVisitorsState = IStateObject<IStateVisitor>;

const state: IVisitorsState = {
  list: [],
  selected: [],
  page: 1,
  page_count: 0,
  item_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (visitors: (IVisitor)[]) => visitors.map((visitor) => ({
  ...visitor,
  loading: false,
  error: false,
  message: '',
}));

const getters = {
  getVisitors: (state: IVisitorsState) => state.list,
  getVisitorsState: (state: IVisitorsState) => state,
  getSelectedVisitors: (state: IVisitorsState) => state.selected,
};

const actions = {
  fetchVisitors(context: ActionContext<IVisitorsState, IState>, params: IVisitorsParams) {
    context.commit('setVisitorsLoading');

    getVisitors(params)
      .then((response) => {
        const visitors = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        context.commit('setVisitors', visitors);
      })
      .catch((err) => {
        context.commit('setVisitorsError', err.request);
      });
  },
  fetchVisitorContacts(context: ActionContext<IVisitorsState, IState>, params: IVisitorContactsParams) {
    context.commit('setVisitorsLoading');
    getVisitorContacts(params)
      .then((response) => {
        const visitors = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        context.commit('setVisitors', visitors);
      })
      .catch((err) => {
        context.commit('setVisitorsError', err.request);
      });
  },

};

const mutations = {
  setVisitorsError: (state: IVisitorsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setVisitorError: (state: IVisitorsState, { failedVisitor, message }: { failedVisitor: IStateVisitor; message: string }) => {
    state.list = state.list.map((visitor) => (visitor.id === failedVisitor.id
      ? {
        ...visitor,
        loading: false,
        error: true,
        message,
      }
      : visitor));
  },

  setVisitorsPage: (state: IVisitorsState, page: number) => {
    state.page = page;
  },

  setVisitorsLoading: (state: IVisitorsState) => {
    state.loading = true;
    state.error = false;
  },

  setVisitorLoading: (state: IVisitorsState, visitor: IStateVisitor) => {
    state.list[state.list.indexOf(visitor)] = {
      ...state.list[state.list.indexOf(visitor)],
      loading: true,
      error: false,
    };
  },

  setVisitors: (state: IVisitorsState, visitors: IVisitorsState) => {
    state.error = false;
    state.loading = false;
    state.list = visitors.list;
    state.page = visitors.page;
    state.page_count = visitors.page_count;
    state.item_count = visitors.item_count;
  },
  editVisitor: (state: IVisitorsState, editedVisitor: IStateVisitor) => {
    state.list = state.list.map((visitor) => (visitor.id === editedVisitor.id
      ? {
        ...editedVisitor,
        loading: false,
        error: false,
      }
      : visitor));
  },

  newVisitor: (state: IVisitorsState, visitor: IStateVisitor) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(visitor);
  },

  removeVisitor: (state: IVisitorsState, deletedVisitor: IVisitor) => {
    state.list = state.list.filter((visitor) => visitor.id !== deletedVisitor.id);
  },

  removeVisitors: (state: IVisitorsState, ids: number[]) => {
    state.list = state.list.filter((visitor) => visitor.id && ids.indexOf(visitor.id) === -1);
  },

  setLoadingVisitors: (state: IVisitorsState, ids: number[]) => {
    state.list = state.list.map((visitor) => ({
      ...visitor,
      loading: !!(visitor.id && ids.indexOf(visitor.id) > -1),
      error: false,
    }));
  },

  selectVisitor: (state: IVisitorsState, visitor: IStateVisitor) => {
    if (visitor.id) {
      const visitorIndex = state.selected.indexOf(visitor.id);
      if (visitorIndex > -1) {
        state.selected.splice(visitorIndex, 1);
      } else {
        state.selected.push(visitor.id);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
