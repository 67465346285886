import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions, IExhibitorInteractionsTranslations, IExhibitorSettingsTranslations, IExhibitorSidebar, IExhibitorsDashboardTranslations, IExhibitorProducsTranslations, IExhibitorProductInteractions, IExhibitorProfile,
} from '.';

const dashboard: IExhibitorsDashboardTranslations = {
  home: 'Início',
  product_interactions_description: 'Número de visitantes interessados em áreas:',
  information: 'Perfil',
  name: 'Nome do expositor:',
  edit: 'Alterar',
  interactions: 'Visitantes interessados no Grupo de trabalho',
  nrOfInteractions: 'Número de visitantes interessados no Grupo de trabalho',
  seeAll: 'Ver contactos',
  download: 'Descarregar',
  sessions: 'Agenda',
  upcoming_sessions: 'Sessões agendadas',
  see_all_sessions: 'Ver agenda',
  applications: 'Pedidos de informação',
  see_applications: 'Ver contactos',
  choose_exhibitor: 'Escolha o expositor que deseja administrar',
  schedule_meetings: 'Sessões agendadas',
  check_scheduled_meetings: 'Consulte as sessões nas quais os visitantes já se inscreveram',
};

const sidebar: IExhibitorSidebar = {
  title: 'Expositor',
  home: 'Início',
  interactions: 'Visitantes',
  exhibitor_interactions: 'Contactos',
  product_interactions: 'Pedidos de informação',
  exhibitor_sessions: 'Sessões do expositor',
  sessions: 'Agenda',
  settings: 'Perfil',
  products: 'Áreas de interesse',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Formulário de expositor',
  basicInfo: 'Informação básica',
  save: 'Guardar',
  additionalInfo: 'Informação adicional',
  formError: 'Sem informação disponivel.',
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Contactos',
  subtitle: 'Consultar a lista de contactos',
  download: 'Descarregar',
  noInteractions: 'Este expositor não tem interações.',
  interaction: {
    editComment: 'Editar comentário',
    saveComment: 'Guardar comentário',
    checkIn: 'CheckIn',
  },
  search_placeholder: 'Introduza o que pretende procurar na lista de contactos',
};

const products: IExhibitorProducsTranslations = {
  title: 'Áreas de interesse',
  subtitle: 'Criar, editar e apagar áreas de interesse',
  no_products: 'Nenhum pedido de informação disponível.',
  new_product: 'Nova área de interesse',
  create_new_product: 'Criar área de interesse',
  edit_product: 'Editar área de interesse',
  delete_confirm: 'Tem a certeza que pretende apagar esta áreas de interesse?',
  interactions_exist_cannot_delete: 'Esta área de interesse já tem interações, por isso não é possível eliminar.',
  search_placeholder: 'Digite o que pretende procurar na lista de áreas de interesse',
  inputs: {
    name: 'Nome',
  },
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Autenticação',
  step_basicInfo: 'Informação básica',
  step_additionalInfo: 'Informação adicional',
  step_review: 'Revisão',
  step_done: 'Terminado!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Imagem de perfil',
    embedded: 'Vídeo de Youtube',
    meeting_room: 'Ativar sala de reuniões virtual',
  },
  login: {
    title: 'Registar expositor',
    subtitle: 'Está prestes a registar o seu expositor',
    message: 'Para registar o seu expositor precisamos que inicie sessão ou se registe com uma conta Beamian.',
    exhibitor_manager_name: 'Introduza o nome do gestor de expositor',
    exhibitor_manager_email: 'Introduza o email do gestor de expositor',
  },
  basicInformation: {
    title: 'Dados do expositor',
    warning: 'A informação providenciada será usada na criação de um booklet e no perfil acessível por visitantes deste evento.',
    createExhibitor: 'Criar expositor',
    exhibitor_name: 'Nome do expositor',
    exhibitor_name_placeholder: 'Digite o nome a ser atribuído ao seu stand',
    modal: {
      title: 'Aviso',
      subtitle: 'Se quiser gerir o seu expositor terá que iniciar sessão ou criar conta.',
      skip: 'Saltar',
      createAccount: 'Criar conta',
      login: 'Iniciar sessão',
    },
  },
  managedFields: {
    title: 'Informação adicional',
    crop_image: 'Cortar imagem',
    crop_again: 'Voltar a cortar',
  },
  review: {
    title: 'Rever informação',
    name: 'Nome',
  },
  completed: {
    title: 'Expositor criado',
    subtitle: 'O seu expositor está pronto. Obrigado pelo registo!',
    manage: 'Gerir',
  },
  back: 'Voltar',
  next: 'Próxima',
  submit: 'Submeter',
};

const sessions: IExhibitorSessions = {
  title: 'Agenda',
  subtitle: 'Criar, alterar e eliminar sessões',
  start_date: 'Começa a:',
  end_date: 'Acaba a:',
  join_meeting: 'Entrar na sessão',
  meeting_url: 'URL da chamada:',
  description: 'Descrição:',
  new_session: 'Nova sessão',
  edit_session: 'Editar Sessão',
  register: 'Registar na sessão',
  invited_users: 'Convidados',
  inputs: {
    name: 'Nome',
    name_placeholder: 'Digite o nome da sessão',
    start_date: 'Data de início',
    start_date_placeholder: 'Digite a data de início da sessão',
    end_date: 'Data de fim',
    end_date_placeholder: 'Digite a data do fim da sessão',
    color: 'Cor',
    color_placeholder: 'Escolha uma cor para a sessão',
    description: 'Descrição',
    description_placeholder: 'Digite a descrição da sessão',
    entrance_capacity: 'Capacidade de entrada',
    entrance_capacity_placeholder: 'Digite a capacidade da sala',
    registration_capacity: 'Capacidade de registo',
    registration_capacity_placeholder: 'Digite o número máximo de registos',
    external_link: 'URL da chamada',
    external_link_placeholder: 'Digite o url da chamada/reunião',
    external_link_warning_message: 'Mantenha este campo vazio se pretender que o beamian crie a sala de reuniões por si.',
    order: 'Ordem',
    order_placeholder: 'Digite a ordem da sessão',
    private_session: 'Sessão privada',
  },
  session_label: 'Sessão',
  select_session: 'Seleccione a sessão',
  no_private_sessions: 'Não existem sessões privadas',
  private_session_warning: 'Crie uma sessão privada para convidar visitantes específicos para uma reunião. Se for uma sessão pública, qualquer visitante poderá visualizar e registar-se na sua sessão.',
  enable_chat: 'Ativar chat',
  embed_meeting: 'Sessão interna',
  embed_meeting_detail: 'Se desativar esta opção o url da sessão será aberto numa nova aba',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Pedidos de informação',
  subtitle: 'Consultar a lista de visitantes',
  product_name: 'Área de interesse',
  interaction_name: 'Nome do visitante',
  application: 'Informação do contacto',
  invite_to_session: 'Agendar uma reunião',
  send_invite: 'Enviar convite',
  user_already_registered: 'Este utilizador já está convidado para esta sessão',
  publish_warning: 'Ao publicar a área de interesse esta ficará visível para todos os visitantes deste evento.',
  invitation_sent: 'Convite enviado com sucesso',
  search_placeholder: 'Introduza o que pretende procurar na lista de contactos',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Informação do expositor',
  products: 'Áreas de interesse',
};

export default {
  dashboard, settings, interactions, onboarding, sidebar, sessions, products, productInteractions, profile,
};
