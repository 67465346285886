import { ITableTranslations } from '.';

const table: ITableTranslations = {
  showing: 'A mostrar',
  of: 'de',
  items: 'itens',
  see: 'Ver',
  see_selected_only: 'Ver apenas selecionados',
  clear_selection: 'Limpar seleção',
  all_items: 'Todos os itens',
  items_per_page: 'Itens por página:',
  previous: 'Anterior',
  next: 'Próxima',
  table_settings: 'Definições da tabela',
  refresh_table: 'Recarregar tabela',
  show_hide_columns: 'Mostrar/esconder colunas',
  search_fields: 'Procurar nas colunas',
  search_settings: 'Definições de procura',
  search_data: 'Procurar',
};

export default table;
