import {
  IVisitorsDashboardTranslations, IVisitorSidebar, IVisitorExhibitorTranslations, IVisitorSessions, IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  networking_description: 'Meet fellow candidates',
  exhibitors: 'Exhibitors',
  nrOfInteractions: 'See all exhibitors',
  seeAll: 'Exhibitors',
  name: 'Name of the candite:',
};

const sidebar: Partial<IVisitorSidebar> = {
  exhibitors: 'Exhibitors',
  title: 'Candidate',
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  title: 'Exhibitors',
  subtitle: 'See exhibitors and check their job offers',
  noExhibitors: 'No exhbitors to display.',
  exhibitor_information: 'Exhibitor information',
  contact_success: 'Your resume was shared successfully!',
  all_exhibitors: 'All exhibitors',
  interacted_exhibitors: 'Exhibitors I shared information with',
  not_interacted_exhibitors: "Exhibitors I didn't share information with",
  filter_exhibitors: 'Filter exhibitors',
  contact_request: 'Leave my resume',
  company_info: 'Exhibitor information',
  job_offers: 'Job offers',
  apply_to_job_offer: 'Apply',
  job_offer_info: 'Information about the job offer',
  cover_letter: 'Cover letter',
  cover_letter_text: 'Tell us why you’re the right person for this position',
  your_info_will_be_shared: 'Your profile will be shared with the company',
  submit_my_application: 'Submit',
  successfully_submited: 'Application submitted successfuly',
  already_applied: "You've already applied to this position",
};

const sessions: Partial<IVisitorSessions> = {
  exhibitor_sessions: 'Company sessions',
};

const products: IVisitorProducts = {
  title: 'Job offers',
  subtitle: 'Browse and apply to job offers',
  products_same_brand: 'Job offers from the same company',
};

export default {
  dashboard, exhibitors, sidebar, products, sessions,
};
