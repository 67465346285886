import { ActionContext } from 'vuex';
import { getTickets } from '@/api/tickets';
import { ITicket, ITicketsParams } from '@/models/ticket';

import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateTicket extends ITicket, IObjectState { }
export type ITicketsState = IStateObject<IStateTicket>;

const state: ITicketsState = {
  list: [],
  selected: [],
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (events: ITicket[]) => events.map((event) => ({
  ...event,
  loading: false,
  success: false,
  error: false,
  message: '',
}));

const getters = {
  getTickets: (state: ITicketsState) => state.list,
  getTicketsState: (state: ITicketsState) => ({
    loading: state.loading,
    error: state.error,
    message: state.message,
  }),
};

const actions = {
  fetchTickets(context: ActionContext<ITicketsState, IState>, params: ITicketsParams) {
    context.commit('setTicketsLoading');

    getTickets(params)
      .then((response) => {
        context.commit('setTickets', addStateProps(response.data.results));
      })
      .catch((err) => {
        context.commit('setTicketsError', err.message);
      });
  },

};

const mutations = {
  resetTickets: (state: ITicketsState) => {
    state.loading = false;
    state.error = false;
    state.message = '';
  },

  setTicketsError: (state: ITicketsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setTicketsLoading: (state: ITicketsState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingTicket: (state: ITicketsState, ticket: IStateTicket) => {
    state.list[state.list.indexOf(ticket)] = {
      ...state.list[state.list.indexOf(ticket)],
      loading: true,
      error: false,
    };
    state.error = false;
  },

  setTickets: (state: ITicketsState, tickets: IStateTicket[]) => {
    state.loading = false;
    state.list = tickets;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
