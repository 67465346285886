


































































// @ is an alias to /src
import axios from 'axios';
import { MutationPayload } from 'vuex';
import { getExhibitorManagerInviteAccessToken, getVisitorAccessToken } from '@/api/interactions';
import Navbar from '@/components/navbar.vue';
import Modal from '@/components/modal.vue';
import Sidebar from '@/components/sidebar.vue';
import HelpCenter from '@/views/HelpCenter.vue';
import Messages from '@/views/Messages/Messages.vue';
import router from '@/router';
import store from '@/store';
import Notifications from '@/views/Notifications.vue';
import Scanner from '@/views/Scanner.vue';
import Popup from '@/components/popup.vue';
import { fetchUserByEmail, fetchUserById } from '@/api/users';

import { IAuth } from '@/models/auth';
import { computed, defineComponent } from '@vue/composition-api';

const development =
  window.location.href.includes('beta') || window.location.href.includes('staging');

export default defineComponent({
  components: {
    Navbar,
    Sidebar,
    HelpCenter,
    Notifications,
    Popup,
    Messages,
    Scanner,
    Modal,
  },
  data() {
    return {
      heroHidden: false,
      navigationHidden: false,
      footerHidden: false,
      redirectUrl: process.env.VUE_APP_REDIRECT_URL,
      serverPath: process.env.VUE_APP_SERVER_PATH,
      loginPromptModal: {
        isActive: false,
        isCard: true,
      },
      tokensInit: false,
    };
  },

  setup() {
    const translations = computed(() => store.getters.getTranslations);

    const contextEvent = computed(() => store.getters.getContextEvent);

    const userLoggedIn = computed(() => store.getters.userLoggedIn);

    const contextExhibitor = computed(() => store.getters.getContextExhibitor);

    const popups = computed(() => store.getters.getPopups);

    return {
      translations,
      contextEvent,
      userLoggedIn,
      popups,
      contextExhibitor,
    };
  },

  created() {
    this.getAuthCreds();
    if (development) {
      this.changeFavicon();
    }

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'openLoginPrompt') {
        this.loginPromptModal.isActive = true;
      }
    });
    axios.interceptors.response.use(undefined, (err) => {
      if (err.request.responseURL.includes(this.serverPath)) {
        if (err.request.status === 401) {
          // if you ever get an unauthorized, logout the user
          store.commit('setUserLogout');
          localStorage.setItem(
            process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
            router.currentRoute.fullPath,
          );
          router.push('/');
          // this.addPopup({
          //   message: err.message,
          //   type: 'danger',
          //   autohide: true,
          // });
        }
        if (err.request.status === 403) {
          // User should not see permissions error
          console.log(err.message);
        }
      }
      throw err;
    });
  },
  methods: {
    redirectToApp() {
      const routes = router.currentRoute.path.split(`/event/${this.contextEvent.id}`);
      window.open(`https://app.beamian.com/${this.contextEvent.slug}${routes[1]}`, '_blank');
    },
    redirectToLogin() {
      localStorage.setItem(
        process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
        router.currentRoute.fullPath,
      );
      router.push('/login');
      this.loginPromptModal.isActive = false;
    },

    checkRoute() {
      const { currentRoute } = router;
      if (
        currentRoute.name === 'Setup' ||
        currentRoute.name === 'Login' ||
        currentRoute.name === 'Register' ||
        currentRoute.name === 'Logout' ||
        currentRoute.name === 'VisitorSessionEnded' ||
        currentRoute.name === 'Home'
      ) {
        this.heroHidden = true;
        this.navigationHidden = true;
        this.footerHidden = true;
      } else if (
        currentRoute.name === 'ExhibitorOnboarding' ||
        currentRoute.name === 'VisitorOnboarding'
      ) {
        this.heroHidden = true;
        this.footerHidden = true;
      } else {
        this.heroHidden = false;
        this.navigationHidden = false;
        this.footerHidden = false;
      }

      if (router.currentRoute.query.activity_center) {
        if (this.userLoggedIn) {
          setTimeout(() => {
            store.commit('openNotifications');
          }, 2000);
        } else {
          localStorage.setItem(
            process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
            router.currentRoute.fullPath,
          );
          router.push('/login');
        }
      }
    },

    logVisitorFromInvite() {
      // Visitor entered the page with invitation link
      const eventId = router.currentRoute.query.e as string;
      const token = router.currentRoute.query.t as string;
      const userId = parseInt(router.currentRoute.query.user as string, 10);

      getVisitorAccessToken(token).then((response) => {
        const { token, name, email } = response.data;
        const auth: IAuth = {
          token,
          user: {
            email,
            name,
          },
        };
        // Set authentication token before accessing user information
        store.commit('setUserAccessToken', token);

        // Get userId and confirm credentials
        fetchUserById(userId).then(
          (response) => {
            // if (response.data.results.length > 0) {
            const user = response.data;
            auth.user.profile_picture = user.profile_picture;
            auth.user.id = user.id;

            // After getting user id, log the user in
            store.commit('setUserLogin', auth);
            const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;

            localStorage.setItem(
              localStorageProp,
              JSON.stringify({
                auth,
                userLoggedIn: true,
              }),
            );
            store.dispatch('getRoles');
            router.push(`/event/${eventId}`);
            // } else {
            //   // Could not get the user
            //   store.commit('addPopup', {
            //     message: 'User does not exist',
            //     type: 'danger',
            //     autohide: true,
            //   });
            //   // Logout
            //   store.commit('setUserLogout');
            //   router.push('/login');
            // }
          },
          (response) => {
            // Something went wrong. Logout
            console.log(response);
            store.commit('setUserLogout');
            router.push('/login');
          },
        );
      });
    },

    logExhibitorFromInvite() {
      // Exhibitor manager entered the page with invitation link
      const token = router.currentRoute.query.exhibitor_t as string;
      const exhibitor = parseInt(router.currentRoute.query.exhibitor as string, 10);
      const event = parseInt(router.currentRoute.query.event as string, 10);

      // Get invitation token information
      getExhibitorManagerInviteAccessToken(token)
        .then((response) => {
          const { email, auth_token } = response.data;

          // Set authentication token before accessing user information
          store.commit('setUserAccessToken', auth_token);

          // Get user information
          fetchUserByEmail(email)
            .then((response) => {
              // This most likely won't happen
              if (response.data.results.length < 1) {
                store.commit('addPopup', {
                  message: 'User does not exist',
                  type: 'danger',
                  autohide: true,
                });
                store.commit('setUserLogout');
                router.push('/login');
                return;
              }

              const user = response.data.results[0];

              const auth: IAuth = {
                token: auth_token,
                user: {
                  id: user.id,
                  email: user.email,
                  name: user.name,
                  profile_picture: user.profile_picture,
                },
              };

              store.commit('setUserLogin', auth);
              const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;

              localStorage.setItem(
                localStorageProp,
                JSON.stringify({
                  auth,
                  userLoggedIn: true,
                }),
              );
              store.dispatch('getRoles');
              if (event) {
                router.push(`/event/${event}/manage-exhibitor/${exhibitor}`);
              }
            })
            .catch((err) => {
              const errors = err.response.data;
              if (errors && errors.length > 0) {
                errors.forEach((error: string) => {
                  store.commit('addPopup', {
                    message: error,
                    type: 'danger',
                    autohide: true,
                  });
                });
              } else {
                store.commit('addPopup', {
                  message: err.nessage,
                  type: 'danger',
                  autohide: true,
                });
              }
            });
        })
        .catch((err) => {
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
        });
    },

    getAuthCreds() {
      const localStorageLang = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
      let lang = localStorage.getItem(localStorageLang);

      const sysLang = (<any>window.navigator).userLanguage || window.navigator.language;

      if (!lang) {
        if (sysLang.includes('pt')) {
          lang = 'pt';
        }
        if (sysLang.includes('en')) {
          lang = 'en';
        }
        if (sysLang.includes('es')) {
          lang = 'es';
        }
        if (sysLang.includes('fr')) {
          lang = 'fr';
        }
        if (sysLang.includes('de')) {
          lang = 'de';
        }
      }

      store.commit('setLanguage', lang || 'en');

      const localStorageAuthProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
      const authString = localStorage.getItem(localStorageAuthProp);
      if (authString) {
        const { userLoggedIn, auth } = JSON.parse(authString);
        if (userLoggedIn) {
          store.commit('setUserLogin', auth);
          store.dispatch('getRoles');
        } else {
          store.commit('setRoles', {});
          localStorage.setItem(
            process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
            router.currentRoute.fullPath,
          );
        }
      }
    },

    changeFavicon() {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = 'images/favicon-beta.ico';
    },
  },
  watch: {
    $route() {
      store.commit('closeNotifications');
      this.checkRoute();
      if (!this.tokensInit) {
        if (router.currentRoute.query.exhibitor_t) {
          this.logExhibitorFromInvite();
          this.tokensInit = true;
        } else if (router.currentRoute.query.t) {
          this.logVisitorFromInvite();
          this.tokensInit = true;
        }
      }
    },
  },
});
