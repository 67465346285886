import { IEventsTranslations } from '.';

const table: IEventsTranslations = {
  title: 'Bienvenido a beamian',
  subtitle: 'Por favor elija un evento',
  rolesTitle: 'Bienvenido a',
  rolesSubtitle: 'Qué quieres hacer?',
  registerVisitor: 'Registrar visitante',
  registerVisitorDescription: 'Regístrate o registra a alguien al evento como visitante.',
  createExhibitor: 'Registrar expositor',
  createExhibitorDescription: 'Registrar tu expositor en este evento.',
  manageVisitor: 'Gestionar visitante',
  manageVisitorDescription: 'Editar información del visitante, ver interacciones.',
  manageExhibitor: 'Gestionar expositor',
  manageExhibitorDescription: 'Editar información del expositor, ver interacciones.',
  manageEvent: 'Gestionar evento',
  manageEventDescription: 'Acceder a la configuración del evento, información y contenido.',
};

export default table;
